// PAGE HEADER
@import "core";

@include respond-to(base-inline) {
  .tc_page__header{display:grid;gap:var(--spacing-8-step);
    .tc_heading{overflow:hidden;
      &:first-child{padding-top:calc(var(--spacing-8-jump) + (var(--spacing-0-step) * 2));}
    }
    .tc_partner{padding-top:var(--spacing-4);}
  }
  .tc_page__header__label{display:flex;padding-bottom:var(--spacing-8);}
}

@include respond-to(large-inline) {
  :where(.tc_deck--col2center .tc_page__header--col2wide){margin:0 calc((100% - (720 * var(--unit))) / 2);}
}

@include respond-to(print) {
  .tc_page__header{
    .tc_heading{margin-bottom:var(--spacing-20);
      &:first-child{padding-top:var(--spacing-16-jump);}
    }
  }
  .tc_page__header__label{margin-bottom:var(--spacing-20);}
}
