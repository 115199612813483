// DATATABLE
@import "core";

@include respond-to(base-inline) {
  :where(.tc_datatable){position:relative;overflow:hidden;background:var(--color-light);font-size:var(--font-14);line-height:var(--line-110);
    & > .tc_heading{padding-bottom:var(--spacing-4);
      & + table caption{display:none;}
    }
    table{width:100%;border-spacing:0;margin:0;padding:0;border-collapse:separate;overflow-anchor:none;} // suppress scroll anchoring because of Chrome behaviour
    caption{position:absolute;width:60%;top:0;left:0;right:0;text-align:left;white-space:nowrap;padding:var(--spacing-4) var(--spacing-4-step);font-size:var(--font-12);font-weight:var(--font-bold);overflow:hidden;text-overflow:ellipsis;}
    th,td{text-align:center;color:var(--color-700);font-weight:var(--font-regular);
      strong{font-weight:var(--font-bold);color:var(--color-text);}
      .tc_datatable{margin:0 calc(var(--spacing-4-step) * -1);
        tbody td,tbody th{border:0;}
      }
    }
    :where(thead th){padding:var(--spacing-4) var(--spacing-4-step);border-bottom:var(--spacing-4) solid var(--color-100);position:relative;font-size:var(--font-12);font-weight:var(--font-bold);color:var(--color-text);
      span{position:absolute;z-index:-1;}
      &[title]{cursor:help;}
    }
    :where(tbody td,tbody th){padding:var(--spacing-8) var(--spacing-4-step);height:var(--spacing-32);border-bottom:var(--spacing-4) solid var(--color-100);}
    :where(thead th,tbody th,tbody td){
      &:first-child{
        &:not(:empty){padding-left:var(--spacing-8-step);}
        &:empty + td{padding-left:var(--spacing-8-step);}
      }
      &:last-child{padding-right:var(--spacing-8-step);}
    }
    :where(tfoot td){border:0;padding:var(--spacing-16) 0;}
    a:not(.tc_button){color:var(--color-link);text-decoration:underline;
      &:visited{color:var(--color-link-visited);}
    }
  }
  .tc_datatable__optional{display:none;}
  .tc_datatable__overflow{white-space:nowrap;text-overflow:ellipsis;overflow:hidden;max-width:0;}

  @media (scripting:none) {
    :where(tfoot .tc_button){display:none;}
  }
}

@include respond-to(medium-inline) {
  :where(.tc_datatable){
    caption{width:45%;}
  }
  .tc_datatable__optional{display:table-cell;}
}

@include respond-to(hover) {
  .tc_datatable{
    a:not(.tc_button){
      &:hover{color:var(--color-link-hover);}
      &:visited:hover{color:var(--color-link-visited-hover);}
    }
  }
}

@include respond-to(print) {
  .tc_datatable{margin:var(--spacing-32) 0;
    table{width:100%;border-collapse:collapse;}
    caption{font-size:var(--font-28);font-weight:var(--font-medium);text-align:left;}
    td{border:var(--spacing-1) solid var(--color-black);background-image:none!important; // override inline styles
      &:empty{display:none;}
      img,svg{display:inline-block;width:var(--spacing-32);vertical-align:bottom;}
    }
    tfoot{display:none;}
  }
  .tc_heading + .tc_datatable__main caption{display:none;}
}
