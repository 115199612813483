// PAGE FOOTER
@import "core";

@include respond-to(base-inline) {
  .tc_page__footer{display:grid;gap:var(--spacing-32);}
  .tc_page__footer__share{display:flex;justify-content:flex-end;
    .tc_share__button,.tc_share__print{color:var(--color-300);
      &.tc_button--dark{color:var(--color-600);}
    }
  }
  .tc_page__footer__logo svg{margin:var(--spacing-48) auto;width:var(--spacing-48);height:var(--spacing-48);}
}

@include respond-to(print) {
  .tc_page__footer{
    section:has(.tc_list){display:none;}
  }
}
