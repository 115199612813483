@import 'core';

@include respond-to(base-inline) {

  .tc_page__wrapper--party-guide-party {
    .tc_page__header {
      position: relative;

      [data-party-letter] {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: var(--spacing-32);
        margin-bottom: var(--spacing-32-step);

        &::before {
          content: attr(data-party-letter);
          text-transform: uppercase;
          display: inline-flex;
          height: var(--spacing-56);
          width: var(--spacing-56);
          border-radius: var(--spacing-56);
          justify-content: center;
          align-items: center;
          color: var(--color-light);
          background-color: var(--color-600);
          font-weight: var(--font-bold);
          font-size: var(--font-24-28);
          box-sizing: border-box;
          margin-bottom: var(--spacing-16);
        }
      }
    }

    .tc_party-guide-party__nav {
      position: absolute;
      right: 0;
      top: calc(0px - var(--spacing-4) - var(--spacing-1));
      display: block;
      font-size: var(--font-12);
      font-weight: var(--font-medium);
      color: var(--color-text);
      padding: var(--spacing-4) var(--spacing-8);
      text-decoration: none;
      border: var(--spacing-1) solid var(--color-300);
    }

    .tc_candidatetest-widget{margin-top:var(--spacing-64);}
  }

  .tc_party-guide-party__summary__stats {
    .tc_image {
      width: var(--spacing-56);
    }

    .tc_heading {
      font-weight: var(--font-regular);
    }

    p {
      font-weight: var(--font-bold);
      font-size: var(--font-16-18);
    }

  }
}

.tc_party-guide-party__summary {
  display: grid;
  grid-template-areas: "a a a b";
  margin-bottom: var(--spacing-24-step);
}

.tc_party-guide-party__summary__stats--candidate {
  grid-area: a;
  display: flex;
  align-items: center;

  .tc_image {
    margin-right: var(--spacing-16);
  }
}

.tc_party-guide-party__summary__stats--seats {
  grid-area: b;
  padding-top: calc(var(--spacing-4) + var(--spacing-0-step));

  p {
    color: var(--color-election-4, var(--color-400));

    strong {
      color: var(--color-text);
    }
  }
}

.tc_party-guide-party__description {
  font-size: var(--font-16);
}

.tc_party-guide-party>.tc_heading {
  margin-top: var(--spacing-24-step);
  margin-bottom: var(--spacing-8-step);
}

.tc_party-guide-party__topic {
  margin-bottom: var(--spacing-8);

  .tc_accordion__details {
    padding-left: var(--spacing-16);
    padding-right: var(--spacing-8-step);
    padding-bottom: var(--spacing-32-step);
  }

  .tc_party-guide__topic-link {
    font-size: var(--font-16);
    text-align: left;
    margin-left: var(--font-28-32-36);
    white-space: unset;
    padding: 0;
  }
}

@include respond-to(base) {
  .tc_page__wrapper--party-guide-party .tc_page__header [data-party-letter="a"]::before {
    background-color: var(--color-election-a);
  }

  .tc_page__wrapper--party-guide-party .tc_page__header [data-party-letter="b"]::before {
    background-color: var(--color-election-b);
  }

  .tc_page__wrapper--party-guide-party .tc_page__header [data-party-letter="c"]::before {
    background-color: var(--color-election-c);
  }

  .tc_page__wrapper--party-guide-party .tc_page__header [data-party-letter="d"]::before {
    background-color: var(--color-election-d);
  }

  .tc_page__wrapper--party-guide-party .tc_page__header [data-party-letter="f"]::before {
    background-color: var(--color-election-f);
  }

  .tc_page__wrapper--party-guide-party .tc_page__header [data-party-letter="i"]::before {
    background-color: var(--color-election-i);
  }

  .tc_page__wrapper--party-guide-party .tc_page__header [data-party-letter="k"]::before {
    background-color: var(--color-election-k);
  }

  .tc_page__wrapper--party-guide-party .tc_page__header [data-party-letter="m"]::before {
    background-color: var(--color-election-m);
  }

  .tc_page__wrapper--party-guide-party .tc_page__header [data-party-letter="o"]::before {
    background-color: var(--color-election-o);
  }

  .tc_page__wrapper--party-guide-party .tc_page__header [data-party-letter="q"]::before {
    background-color: var(--color-election-q);
  }

  .tc_page__wrapper--party-guide-party .tc_page__header [data-party-letter="v"]::before {
    background-color: var(--color-election-v);
  }

  .tc_page__wrapper--party-guide-party .tc_page__header [data-party-letter="æ"]::before {
    background-color: var(--color-election-æ);
  }

  .tc_page__wrapper--party-guide-party .tc_page__header [data-party-letter="ø"]::before {
    background-color: var(--color-election-ø);
  }

  .tc_page__wrapper--party-guide-party .tc_page__header [data-party-letter="å"]::before {
    background-color: var(--color-election-å);
  }

  .tc_page__wrapper--party-guide-party .tc_page__header [data-party-letter="j"]::before {
    background-color: var(--color-election-j);
  }

  .tc_page__wrapper--party-guide-party .tc_page__header [data-party-letter="n"]::before {
    background-color: var(--color-election-n);
  }
}

@include respond-to(medium-inline) {
  .tc_page__wrapper--party-guide-party {
    .tc_page__header [data-party-letter] {
      flex-direction: row;
      margin-top: var(--spacing-72);
      margin-bottom: var(--spacing-64);

      &::before {
        margin-bottom: 0;
        margin-right: var(--spacing-16);
      }
    }

    .tc_party-guide-party__summary {
      margin-bottom: var(--spacing-32);
    }

    .tc_party-guide-party__description {
      font-size: var(--font-18);
      padding-top: var(--spacing-8);
      margin-bottom: var(--spacing-48);
    }
  }

  .tc_party-guide-party__summary__stats--candidate {
    .tc_image {
      width: var(--spacing-72);
    }
  }
}
