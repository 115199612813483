// EMBED TOOLS BEFORE-AFTER
@import "core";

@include respond-to(base) {
  .tc_tools__before-after{position:relative;background-color:var(--color-white);}
  .tc_tools__before-after__images{position:relative;}
  .tc_tools__before-after__images__after{position:absolute;top:0;left:0;right:0;bottom:0;z-index:2;}
  .tc_tools__before-after__slider__input{width:100%;padding:0;margin:auto;}
  .tc_tools__before-after[data-widget-orientation="vertical"]{
    .tc_tools__before-after__slider__input{transform:rotate(-90deg);width:calc(56.25% + (44 * var(--unit)));height:calc(177.78% + (44 * var(--unit)));}
  }
  .tc_tools__before-after__slider{position:absolute;top:0;left:0;right:0;bottom:0;z-index:2;overflow:hidden;}
  .tc_tools__before-after__slider__input{appearance:none;position:absolute;top:calc(-22 * var(--unit));left:calc(-22 * var(--unit));right:calc(-22 * var(--unit));bottom:calc(-22 * var(--unit));z-index:1;width:calc(100% + (44 * var(--unit)));height:calc(100% + (44 * var(--unit)));background:transparent;filter:invert(1) brightness(2);
    &::-moz-range-track{width:100%;height:100%;background:transparent;border:none;}
    &::-moz-range-thumb{-moz-appearance:none;width:calc(44 * var(--unit));height:100%;background:url(../../../../../../icons/drag.svg) no-repeat 50% 50%,radial-gradient(circle,var(--color-white) var(--spacing-20),transparent calc(var(--spacing-20) + var(--spacing-1))),linear-gradient(to right,transparent var(--spacing-20),var(--color-midnight) var(--spacing-20),var(--color-midnight) var(--spacing-24),transparent var(--spacing-24)) no-repeat 50% 50%;background-size:var(--spacing-32) auto,auto,auto;border:0;border-radius:0;cursor:pointer;}
    &::-webkit-slider-runnable-track{width:100%;height:100%;background:transparent;border:none;}
    &::-webkit-slider-thumb{-webkit-appearance:none;width:calc(44 * var(--unit));height:100%;background:url(../../../../../../icons/drag.svg) no-repeat 50% 50%,radial-gradient(circle,var(--color-white) var(--spacing-20),transparent calc(var(--spacing-20) + var(--spacing-1))),linear-gradient(to right,transparent var(--spacing-20),var(--color-midnight) var(--spacing-20),var(--color-midnight) var(--spacing-24),transparent var(--spacing-24)) no-repeat 50% 50%;background-size:var(--spacing-32) auto,auto,auto;border:0;border-radius:0;cursor:pointer;box-shadow:none;}
    &:focus-visible,&:active{
      &::-moz-range-thumb{background:url(../../../../../../icons/drag.svg) no-repeat 50% 50%,radial-gradient(circle,var(--color-neutral-300) var(--spacing-20),transparent calc(var(--spacing-20) + var(--spacing-1))),linear-gradient(to right,transparent var(--spacing-20),var(--color-midnight) var(--spacing-20),var(--color-midnight) var(--spacing-24),transparent var(--spacing-24)) no-repeat 50% 50%;background-size:var(--spacing-32) auto,auto,auto;}
      &::-webkit-slider-thumb{background:url(../../../../../../icons/drag.svg) no-repeat 50% 50%,radial-gradient(circle,var(--color-neutral-300) var(--spacing-20),transparent calc(var(--spacing-20) + var(--spacing-1))),linear-gradient(to right,transparent var(--spacing-20),var(--color-midnight) var(--spacing-20),var(--color-midnight) var(--spacing-24),transparent var(--spacing-24)) no-repeat 50% 50%;background-size:var(--spacing-32) auto,auto,auto;}
    }
  }

  // selector is true if user has overriden the OS setting on tv2.dk with darkmode (using the settings switch)
  :root:has(meta[name="color-scheme"][content="dark"]) .tc_tools__before-after__slider__input{filter:none;}

  @media (prefers-color-scheme:dark) {
    // selector is true if user has set darkmode on OS level (but only if it's supported - ie. "dark" is present in the metatag)
    :root:has(meta[name="color-scheme"][content*="dark"]) .tc_tools__before-after__slider__input{filter:none;}
  }

  @media (scripting:none) {
    .tc_tools__before-after__slider{display:none;}
    .tc_tools__before-after__images__after{clip-path:none!important;} // override inline styles
  }
}

@include respond-to(print) {
  .tc_tools__before-after{border:var(--spacing-2) solid var(--color-black);padding:var(--spacing-20) var(--spacing-20) 0;}
  .tc_tools__before-after__slider{display:none;}
  .tc_tools__before-after__images__after{clip-path:none!important;} // override inline styles
}
