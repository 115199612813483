// CANDIDATE TEST
@import "core";

@include respond-to(base-inline) {
  .tc_page__wrapper--candidatetest .tc_page__header + .tc_richcontent{margin-bottom:var(--spacing-16-jump);}
  .tc_candidatetest{background-color:var(--color-election-1, var(--color-100));padding:var(--spacing-8-step) var(--spacing-8-step) 0;
    .tc_page__wrapper &{margin-left:calc(var(--gutter) * -1);margin-right:calc(var(--gutter) * -1);}
    .tc_candidatetest__priority__notice{margin:calc(var(--spacing-32) * -1) 0 var(--spacing-32);}
  }
  .tc_candidatetest__header{min-height:var(--spacing-32-step);padding:var(--spacing-24) var(--spacing-8) var(--spacing-32-step);background-repeat:no-repeat;background-position:right var(--spacing-8) top calc(50% - var(--spacing-4-step));background-size:auto 50%;
    .tc_heading{display:flex;flex-direction:column;justify-content:center;}
    &[data-location-icon="true"] .tc_heading{position:relative;padding-left:var(--spacing-32-step);
      &::before{content:"";position:absolute;top:0;left:0;width:var(--spacing-32-step);height:100%;mask:url("inline!../../../../../icons/pin.svg") no-repeat 0 50%;mask-size:auto var(--spacing-32-step);background-color:var(--color-dark-300);}
    }
    &[style] .tc_heading{padding-right:var(--spacing-64-jump);}
    &[data-logo-only="true"]{background-position:left 50% top calc(50% - var(--spacing-4-step));background-size:auto 65%;
      .tc_candidatetest__header__headline,.tc_candidatetest__header__subheadline{visibility:hidden;}
    }
  }
  .tc_candidatetest__header__logo{width:100%;max-width:var(--spacing-64);}
  .tc_candidatetest__header__headline{font-size:var(--font-12);line-height:var(--line-140);font-weight:var(--font-medium);}
  .tc_candidatetest__header__subheadline{font-size:var(--font-14);line-height:var(--line-140);color:var(--color-700);font-weight:var(--font-regular);}
  .tc_candidatetest__footer{padding:var(--spacing-24-step) var(--spacing-8-step);}
  .tc_candidatetest__footer strong{display:block;}
  .tc_candidatetest__footer span{font-size:var(--font-28-32-36);font-weight:var(--font-bold);}

  /* candidate test card */
  .tc_candidatetest__card{background-color:var(--color-light);padding:var(--spacing-24-step) var(--spacing-8-step) 0;border-bottom:var(--spacing-8-step) solid var(--color-election-1, var(--color-100));
    .tc_button__group{position:relative;z-index:0;margin-bottom:calc(var(--spacing-32) + var(--spacing-32-jump));}
    .react-autosuggest__container{max-width:calc(330 * var(--unit));margin:var(--spacing-88-step) auto;}
  }

  [data-election-type="fv"] .tc_candidatetest__footer{color:var(--color-midnight);}

  [data-election-type="ep"] .tc_candidatetest__card[data-type="intro"]{
    .tc_candidatetest__card__header__question{text-align:center;padding-top:var(--spacing-56);max-width:calc(360 * var(--unit));margin:0 auto calc(var(--spacing-32) * -1);
      .tc_heading{padding-bottom:var(--spacing-16);}
      p{font-size:var(--font-16-18);}
    }
  }

  .tc_candidatetest__card__header__steps{
    .tc_heading{padding-bottom:var(--spacing-24-step);
      span{color:var(--color-election-3, var(--color-400));}
    }
    & + .tc_candidatetest__card__header__question{min-height:calc(256 * var(--unit));}
  }

  .tc_candidatetest__card__header__question{
    .tc_heading{padding-bottom:var(--spacing-4-step);font-size:var(--font-20-24);}
    p{font-size:var(--font-18-20);line-height:var(--line-130);}
  }

  .tc_candidatetest__start .tc_button__group:only-child{margin:calc(var(--spacing-32-jump) + var(--spacing-32)) 0 calc(var(--spacing-64) + var(--spacing-32-jump) + var(--spacing-32));}

  /* candidate priority questions */
  .tc_candidatetest__priority{display:flex;flex-direction:column;row-gap:var(--spacing-24-step);padding:0 var(--spacing-16) var(--spacing-64-step);}
  .tc_candidatetest__checkbox{position:absolute;opacity:0;margin-left:0;
    & ~ label{display:flex;align-items:center;position:relative;min-height:var(--spacing-32-step);padding-left:var(--spacing-40-step);font-size:var(--font-14);line-height:var(--line-140);font-weight:var(--font-medium);-webkit-user-select:none;user-select:none;cursor:pointer;
      &::before,&::after{content:"";position:absolute;z-index:1;top:0;left:0;}
      &::before{width:var(--spacing-32-step);height:var(--spacing-32-step);box-sizing:border-box;border:var(--spacing-3) solid var(--color-election-10, var(--color-900));background-color:var(--color-election-8, var(--color-light));border-radius:var(--spacing-2);}
      &::after{width:var(--spacing-16-step);height:var(--spacing-16-step);top:var(--spacing-8);left:var(--spacing-8);opacity:0;transition:opacity var(--transition-normal);mask:url("inline!../../../../../icons/check.svg") no-repeat 50% 50%;mask-size:100% auto;background-color:var(--color-dark);}
    }
    &:checked ~ label::after{opacity:1;}
    &:active ~ label::after{opacity:.7;}
    &:focus-visible ~ label::before{outline:var(--spacing-2) solid var(--color-focus);outline-offset:var(--spacing-2);}
    &[disabled]{
      & ~ label::before{background-color:var(--color-300);border-color:var(--color-500);}
      & ~ label::after{display:none;}
    }
  }
  .tc_candidatetest__navigation{display:flex;justify-content:space-between;padding-bottom:var(--spacing-32-step);
    .tc_button{font-size:var(--font-14-16);color:var(--color-election-11, var(--color-700));}
  }

  .tc_election-bgline{position:absolute;z-index:-1;left:0;right:0;top:0;bottom:0;margin:auto 0;overflow:hidden;height:calc(10 * var(--unit));background-color:var(--color-election-7, var(--color-dark));border-radius:calc(var(--spacing-12) / 2);}

  /* election svg animation */
  #election-bgline{position:absolute;z-index:-1;left:calc(var(--spacing-8-step) * -1);right:calc(var(--spacing-8-step) * -1);top:0;bottom:0;margin:auto 0;width:calc(100% + (var(--spacing-8-step) * 2));overflow:hidden;}
}

@include respond-to(small-inline) {
  .tc_candidatetest{
    .tc_page__wrapper &{margin-left:0;margin-right:0;}
  }
  .tc_candidatetest__header{background-size:auto auto;}

  .tc_candidatetest__card__header__steps + .tc_candidatetest__card__header__question{min-height:calc(220 * var(--unit));}

  /* candidate priority questions */
  .tc_candidatetest__priority{flex-direction:row;flex-wrap:wrap;
    .tc_form__group{width:50%;}
  }
  .tc_candidatetest__checkbox{
    & ~ label{padding-right:var(--spacing-16);
      &::after{left:var(--spacing-8);}
    }
  }
}


@include respond-to(medium-inline) {
  .tc_election-bgline{height:var(--spacing-12);}

  /* candidate priority questions */
  .tc_candidatetest__checkbox ~ label::before{border-width:var(--spacing-4);}
}

@include respond-to(print) {
  .tc_candidatetest__header{background:none!important;} // override inline styles
  .tc_election-bgline{display:none;}
}
