// BUTTON GROUP
@import "core";

@include respond-to(base-inline) {
  .tc_button__group{display:flex;align-items:center;flex-wrap:wrap;gap:var(--spacing-16-step);
    .tc_button--link{color:var(--color-text);font-size:var(--font-16);}
  }
  .tc_button__group--left{justify-content:flex-start;};
  .tc_button__group--center{justify-content:center;};
  .tc_button__group--right{justify-content:flex-end;};
}

@include respond-to(print) {
  .tc_button__group{display:none;}
}
