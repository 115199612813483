// TOOLSCARD
@import "core";

@include respond-to(base) {
  .tc_toolscard{background-color:var(--color-dark-100);max-width:var(--main-medium);margin:0 auto;
    [data-object-layout] &.tc_toolscard--image .tc_toolscard__header__icon svg{fill:var(--color-white);}
  }
  .tc_toolscard--not-logged-in .tc_toolscard__body{position:relative;
    .tc_toolscard__body__login-button{position:absolute;left:0;top:0;bottom:0;right:0;z-index:3;background:transparent;border:0;}
  }
  .tc_toolscard__header{position:relative;color:var(--color-white);padding:var(--spacing-20) calc(var(--spacing-16-jump) + (var(--spacing-0-step) * 4)) var(--spacing-32);min-height:calc(var(--spacing-64-jump) + var(--spacing-32-step));display:flex;flex-direction:column;justify-content:center;align-items:center;background-color:var(--color-neutral-800);background-repeat:no-repeat;background-position:50% 50%;background-size:cover;
    .tc_heading{margin-bottom:var(--spacing-8);}
    .tc_tooltip{position:absolute;right:var(--spacing-8);bottom:var(--spacing-8);color:var(--color-white);}
    &[data-toolscard-header-info]::before{content:attr(data-toolscard-header-info);position:absolute;top:0;left:0;right:0;z-index:1;text-align:center;font-size:var(--font-14);line-height:var(--line-120);padding:var(--spacing-4) var(--spacing-16);background-color:var(--color-midnight-600);}
  }
  .tc_toolscard__header__subheading{text-align:center;}
  .tc_toolscard__header__login__link{color:var(--color-light);}
  .tc_toolscard__header__icon{display:flex;align-items:center;justify-content:center;width:calc(var(--spacing-16-jump) + var(--spacing-16));height:calc(var(--spacing-16-jump) + var(--spacing-16));margin-bottom:var(--spacing-8);background-color:var(--color-white);border-radius:50%;
    svg{fill:var(--color-midnight-800);width:calc(var(--spacing-16-step) + var(--spacing-4));height:calc(var(--spacing-16-step) + var(--spacing-4));}
  }

  .tc_toolscard__body{position:relative;padding:var(--spacing-16-jump) var(--spacing-8) var(--spacing-8);
    & > .tc_heading{padding-bottom:var(--spacing-4-step);}
  }

  .tc_toolscard__footer{padding:0 var(--spacing-8) var(--spacing-16);font-size:var(--font-14);line-height:var(--line-140);color:var(--color-700);text-align:center;}

  .tc_toolscard__overlay__login{width:max(calc(300 * var(--unit)),calc(400 * var(--unit)));background-color:var(--color-light);
    .tc_richcontent{padding-bottom:var(--spacing-40);}
    .tc_button__group{margin-bottom:var(--spacing-20);}
  }
  .tc_toolscard__overlay__login__header{padding:var(--spacing-24) 0 0;margin:0 var(--spacing-16);}
  .tc_toolscard__overlay__login__header__logo{position:relative;height:var(--spacing-40-step);margin:var(--spacing-8) 0 var(--spacing-32);
    svg{position:absolute;left:0;right:0;top:0;bottom:0;margin:auto;width:100%;height:100%;}
  }
  .tc_toolscard__overlay__login__body{padding:var(--spacing-20) 0;margin:0 var(--spacing-16);}

  [data-object-layout]{
    .tc_toolscard:not(.tc_toolscard--image){
      .tc_toolscard__header{background-color:var(--color-primary-tv2red);}
      .tc_toolscard__header__icon svg{fill:var(--color-primary-tv2red);}
    }
    .tc_toolscard--image .tc_toolscard__header__icon{background-color:var(--color-primary-tv2red);}
  }
  [data-object-layout="sport"]{
    .tc_toolscard:not(.tc_toolscard--image){
      .tc_toolscard__header{background-color:var(--color-primary-blue);}
      .tc_toolscard__header__icon svg{fill:var(--color-primary-blue);}
    }
    .tc_toolscard--image .tc_toolscard__header__icon{background-color:var(--color-primary-blue);}
  }
  [data-object-layout="vejr"]{
    .tc_toolscard:not(.tc_toolscard--image){
      .tc_toolscard__header{background-color:var(--color-primary-green);}
      .tc_toolscard__header__icon svg{fill:var(--color-primary-green);}
    }
    .tc_toolscard--image .tc_toolscard__header__icon{background-color:var(--color-primary-green);}
  }
  [data-object-layout="echo"]{
    .tc_toolscard:not(.tc_toolscard--image){
      .tc_toolscard__header{background-color:var(--color-primary-violet);}
      .tc_toolscard__header__icon svg{fill:var(--color-primary-violet);}
    }
    .tc_toolscard--image .tc_toolscard__header__icon{background-color:var(--color-primary-violet);}
  }
}

@include respond-to(print) {
  .tc_toolscard{border:var(--spacing-2) solid var(--color-black);}
  .tc_toolscard__header{display:flex;flex-direction:column;align-items:center;padding:var(--spacing-20);}
  .tc_toolscard__body{padding:var(--spacing-20) 0;margin:0 var(--spacing-20);}
  .tc_toolscard__footer{padding:var(--spacing-8);}
}
