// LIVEBLOG BODY
@import "core";

@include respond-to(base-inline) {
  .tc_liveblog__body{position:relative;max-width:var(--main-medium);overflow-anchor:none;
    & > .tc_button__group{margin-top:var(--spacing-40);}
    .tc_forms-filter{padding:var(--spacing-8) var(--spacing-16);}
    .tc_forms-filter{
      .tc_form-fieldset__body{padding-bottom:0;}
    }
  }
  .tc_liveblog__body__summary{padding-bottom:var(--spacing-8);}
  .tc_liveblog__body__posts{scroll-margin-top:var(--spacing-112-step);
    .tc_post{
      &:first-of-type{
        &::before{top:var(--spacing-24);}
      }
      &:last-of-type{padding-bottom:0;}
    }
    & > .tc_adwrap{padding:var(--spacing-24) 0 var(--spacing-24);
      &::before{content:"";position:absolute;left:var(--spacing-32);top:0;bottom:0;width:var(--spacing-2);background-color:var(--color-dark-500);}
      .tc_ad{background-color:var(--color-200);max-height:none;
        & > *{margin:var(--spacing-32) 0 var(--spacing-24);}
        &.tc_ad--tag::before{top:var(--spacing-12);
          [data-ad="interscroll"] &{top:calc(var(--spacing-1) * -1);}
        }
        &.tc_ad--midscroll.tc_ad--tag::before{top:calc(var(--spacing-1) * -1);}
      }
    }
  }
  .tc_liveblog__body__updates{position:sticky;z-index:10;top:var(--spacing-16);text-align:center;height:0;}
}

@include respond-to(print) {
  .tc_liveblog__body__updates{display:none;}
  .tc_liveblog__body__posts + .tc_button__group{display:none;}
}
