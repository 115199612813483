// PARTY SUMMARY
@import "core";

@include respond-to(base-inline) {
  .tc_party-summary{position:relative;display:flex;align-items:flex-start;column-gap:var(--spacing-8-step);padding:var(--spacing-16) 0;color:inherit;text-decoration:none;border-bottom:var(--spacing-1) solid var(--color-300);background-color:var(--color-light);
    &[href]{padding-right:var(--spacing-40);;
      &::after{content:"";position:absolute;right:0;top:0;bottom:0;width:var(--spacing-40-step);mask:url("inline!../../../../../icons/chevron-right.svg") no-repeat top 50% right var(--spacing-20);mask-size:auto var(--spacing-20);background-color:var(--color-dark);}
      .tc_party-summary__info__match{justify-content:flex-start;}
    }
    &[data-party-letter]::before{content:attr(data-party-letter);box-sizing:border-box;display:flex;justify-content:center;align-items:center;min-width:var(--spacing-32-step);max-width:var(--spacing-32-step);height:var(--spacing-32-step);background-color:var(--color-election-unknown);color:var(--color-light);text-transform:uppercase;font-size:var(--font-18);font-weight:var(--font-bold);}
    &[data-party-letter="a"]::before{background-color:var(--color-election-a);}
    &[data-party-letter="b"]::before{background-color:var(--color-election-b);}
    &[data-party-letter="c"]::before{background-color:var(--color-election-c);}
    &[data-party-letter="d"]::before{background-color:var(--color-election-d);}
    &[data-party-letter="f"]::before{background-color:var(--color-election-f);}
    &[data-party-letter="i"]::before{background-color:var(--color-election-i);}
    &[data-party-letter="k"]::before{background-color:var(--color-election-k);}
    &[data-party-letter="m"]::before{background-color:var(--color-election-m);}
    &[data-party-letter="o"]::before{background-color:var(--color-election-o);}
    &[data-party-letter="q"]::before{background-color:var(--color-election-q);}
    &[data-party-letter="v"]::before{background-color:var(--color-election-v);}
    &[data-party-letter="æ"]::before{background-color:var(--color-election-æ);}
    &[data-party-letter="ø"]::before{background-color:var(--color-election-ø);}
    &[data-party-letter="å"]::before{background-color:var(--color-election-å);}
  }

  .tc_party-summary__info{flex:1;display:flex;flex-direction:column;}
  .tc_party-summary__info__description{font-size:var(--font-14);}
  .tc_party-summary__info__match{display:flex;align-items:center;font-size:var(--font-14);line-height:var(--line-110);
    strong{font-size:var(--font-18);font-weight:var(--font-bold);padding-right:var(--spacing-4);}
  }

  /* FV DESIGN */
  [data-election-type="fv"]{
    .tc_party-summary[data-party-letter]::before{border-radius:50%;}
  }
}

@include respond-to(medium-inline) {
  [data-party-letter]::before{font-size:var(--font-20-24);}
  .tc_party-summary__info{flex-direction:row;}
  .tc_party-summary__info__match{min-width:calc(130 * var(--unit));justify-content:flex-end;padding:0 var(--spacing-16);margin-left:auto;}
}

@include respond-to(print) {
  .tc_party-summary{border:var(--spacing-2) solid var(--color-black);padding:var(--spacing-8);margin-bottom:var(--spacing-16);}
  .tc_party-summary__info__description{margin:0;}
}
