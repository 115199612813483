// LINEUP
@import "core";

@include respond-to(base-inline) {
  :root{
    --color-field: #9BD7A0;
  }
  .tc_lineup__field{margin-bottom:var(--spacing-16);}
  .tc_lineup__list{display:grid;grid-template-columns:repeat(2, minmax(0, 1fr));column-gap:var(--spacing-24-step);row-gap:var(--spacing-8);font-size:var(--font-12);padding:0 var(--spacing-16);}
  .tc_lineup__list__team__header{background-size:0;padding:var(--spacing-8) 0;
    .tc_heading{font-size:var(--font-18-24);}
  }
  .tc_lineup__list__team__list{list-style:none;}
  .tc_lineup__item{position:relative;display:flex;gap:var(--spacing-4);padding:var(--spacing-8) 0;}
  .tc_lineup__item__player-number{background-size:0;color:var(--color-700);
    &:not(:empty)::after{content:". ";}
  }
  .tc_lineup__item__player-name{text-decoration:none;}
  .tc_lineup__item__player-activities{display:flex;gap:var(--spacing-4);}
  .tc_lineup__item__player-activities__activity{position:relative;display:flex;justify-content:center;align-items:center;
    img{display:inline;width:var(--spacing-12);overflow:hidden;}
    &[data-number]{
      &::after{content:attr(data-number);position:absolute;display:flex;justify-content:center;align-items:center;font-size:calc(8 * var(--unit));font-weight:var(--font-medium);background:var(--color-900);width:calc(11 * var(--unit));height:calc(11 * var(--unit));padding-bottom:var(--spacing-1);border-radius:50%;color:var(--color-light);box-sizing:content-box;}
      &[data-type="goal"]::after{left:calc(var(--spacing-4) * -1);top:calc(var(--spacing-4) * -1);}
    }
  }

  @supports (aspect-ratio:1/1) {
    .tc_lineup__field{
      &[data-showfield="true"]{position:relative;padding:var(--spacing-8);background-color:var(--color-field);color:var(--color-midnight);
        .tc_accordion{background-color:transparent;}
        .tc_accordion__summary{position:absolute;width:var(--spacing-1);height:var(--spacing-1);overflow:hidden;white-space:nowrap;clip:rect(0 0 0 0);clip-path:inset(50%);}
        .tc_accordion__details{padding:0;}
        .tc_lineup__list{position:relative;font-size:var(--font-10);line-height:var(--line-120);padding:0;
          &::after{content:"";grid-column:1/3;display:block;aspect-ratio:1/1.69;background:transparent url('https://gfx.tv2a.dk/sportsdata-services/football/field_vertical.svg') no-repeat 0 100%;background-size:100% auto;}
        }
        .tc_lineup__list__team{
          &[data-team="home"]{
            .tc_lineup__list__team__header{padding:0 0 0 var(--spacing-24);background-position:0 var(--spacing-4);}
            .tc_lineup__list__team__list{bottom:48%;}
          }
          &[data-team="away"]{
            .tc_lineup__list__team__header{padding:0 var(--spacing-24) 0 0;background-position:100% var(--spacing-4);text-align:right;}
            .tc_lineup__list__team__list{bottom:2%;}
          }
        }
        .tc_lineup__list__team__header{min-height:var(--spacing-32);background-repeat:no-repeat;background-size:var(--spacing-16) auto;position:unset;width:unset;height:unset;white-space:normal;clip:unset;clip-path:unset;
          .tc_heading{font-size:var(--font-14);}
          [aria-label="Formation"]{padding-top:var(--spacing-2);}
        }
        .tc_lineup__list__team__list{position:absolute;z-index:1;list-style:none;left:5%;right:5%;aspect-ratio:calc(2 / 1.69) / 1;text-align:center;display:grid;grid-template-columns:repeat(9, minmax(0, 1fr));grid-template-rows:repeat(11, minmax(0, 1fr));justify-items:center;align-items:center;height:42%;}
        .tc_lineup__item{flex-direction:column;align-items:center;gap:0;white-space:nowrap;cursor:default;padding:0;}
        .tc_lineup__item__player-number{display:flex;justify-content:center;align-items:center;width:var(--spacing-32);height:var(--spacing-32);background-repeat:no-repeat;background-size:contain;background-position:50%;color:var(--color-white);font-size:calc(11 * var(--unit));font-weight:var(--font-medium);text-shadow:calc(var(--spacing-1) * -1) 0 var(--spacing-1) var(--color-midnight),0 calc(var(--spacing-1) * -1) var(--spacing-1) var(--color-midnight),var(--spacing-1) 0 var(--spacing-1) var(--color-midnight),0 var(--spacing-1) var(--color-midnight);
          &::after{position:absolute;width:var(--spacing-1);height:var(--spacing-1);overflow:hidden;white-space:nowrap;clip:rect(0 0 0 0);clip-path:inset(50%);}
        }
        .tc_lineup__item__player-name{position:absolute;top:calc(34*var(--unit));z-index:1;padding:0;width:calc(56*var(--unit));overflow:hidden;text-overflow:ellipsis;font-size:calc(9*var(--unit));opacity:1;transition:opacity var(--transition-fast) var(--transition-fast);}
        .tc_lineup__item__player-activities{position:absolute;left:0;right:0;top:0;bottom:0;}
        .tc_lineup__item__player-activities__activity{position:absolute;width:var(--spacing-16);height:var(--spacing-16);background:var(--color-white);border-radius:50%;
          &[data-type="goal"]{left:calc(50% - var(--spacing-20) - var(--spacing-2));top:calc(var(--spacing-2) * -1);}
          &[data-type="penaltymissed"],&[data-type="owngoal"]{left:calc(50% - var(--spacing-20) - var(--spacing-2));top:var(--spacing-16);}
          &[data-type="yellowcard"],&[data-type="redcard"],&[data-type="yellowredcard"]{right:calc(50% - var(--spacing-20) - var(--spacing-2));top:calc(var(--spacing-2) * -1);
            img{width:calc(var(--spacing-8) + var(--spacing-2));}
          }
          &[data-type="in"],&[data-type="out"]{right:calc(50% - var(--spacing-20) - var(--spacing-2));top:var(--spacing-16);
            img{width:calc(var(--spacing-8) + var(--spacing-2));}
          }
        }
      }
    }
  }
}

@include respond-to(medium-inline) {
  .tc_lineup__list{font-size:var(--font-14);}

  @supports (aspect-ratio:1/1) {
    .tc_lineup__field{
      &[data-showfield="true"]{
        .tc_lineup__list{
          &::after{aspect-ratio:1.66/1;background-image:url('https://gfx.tv2a.dk/sportsdata-services/football/field_horizontal.svg');}
        }
        .tc_lineup__list__team{
          &[data-team="home"]{
            .tc_lineup__list__team__list{left:-2%;right:48%;bottom:9%;}
          }
          &[data-team="away"]{
            .tc_lineup__list__team__list{left:48%;right:-2%;bottom:9%;}
          }
        }
        .tc_lineup__list__team__list{transform:rotate(-90deg);height:68%;}
        .tc_lineup__item{white-space:nowrap;transform:rotate(90deg);}
      }
    }
  }
}

@include respond-to(hover) {
  @supports (aspect-ratio:1/1) {
    .tc_lineup__field{
      &[data-showfield="true"]{
        .tc_lineup__item__player-name::after{content:attr(title);top:calc(-1*var(--unit));position:relative;display:block;white-space:normal;padding:var(--spacing-2);font-size:0;box-shadow:none;background-color:transparent;border-radius:var(--spacing-2);}
        .tc_lineup__item:hover{z-index:1;
          &::before{content:'';position:absolute;width:var(--spacing-64);height:var(--spacing-64);} // landing pad
          .tc_lineup__item__player-name{overflow:visible;font-size:0;transition:font-size 0s var(--transition-fast);pointer-events:none;
            &::after{transition:font-size 0s var(--transition-fast),padding 0s var(--transition-fast),box-shadow 0s var(--transition-fast),background-color var(--transition-fastest) var(--transition-fast);font-size: calc(9*var(--unit));background-color:var(--color-white);box-shadow:var(--spacing-1) var(--spacing-1) var(--spacing-1) var(--color-dark-100);}
          }
        }
      }
    }
  }
}

@include respond-to(print) {
  .tc_lineup{border:var(--spacing-2) solid var(--color-black);padding:var(--spacing-8);}
  .tc_lineup__list{display:flex;justify-content:space-between;}
  .tc_lineup__item{
    abbr{text-decoration:none;
      &::before{content:attr(title);}
      span{display:none;}
    }
  }
  .tc_lineup__item__player-activities{display:inline-flex;gap:var(--spacing-4);padding-left:var(--spacing-4);
    img{width:var(--spacing-12);}
  }
  .tc_lineup__item__player-number{padding-right:var(--spacing-4);}
  .tc_lineup__list__team__header,.tc_lineup__item__player-number{background:none!important;}
}
