// IMAGE
@import "core";

@include respond-to(base-inline) {
  :where(.tc_image){position:relative;}
  :where(.tc_image--circular .tc_image__picture__img){border-radius:50%;}
  :where([data-img-ratio-bs="square"]){aspect-ratio:1/1;}
  :where([data-img-ratio-bs="landscape"]){aspect-ratio:16/9;}
  :where([data-img-ratio-bs="portrait"]){aspect-ratio:3/4;}
  :where(.tc_image__picture__img){width:100%;max-width:100%;height:100%;aspect-ratio:inherit;object-fit:contain;background:var(--color-dark-100) url("inline!../../../../icons/logo.light.svg") no-repeat 50%;background-size:max(var(--spacing-24),20%) auto;color:transparent;}

  // selector is true if user has overriden the OS setting on tv2.dk with darkmode (using the settings switch)
  :root:has(meta[name="color-scheme"][content="dark"]) :where(.tc_image__picture__img){background-image:url("inline!../../../../icons/logo.dark.svg");}

  @media (prefers-color-scheme:dark) {
    // selector is true if user has set darkmode on OS level (but only if it's supported - ie. "dark" is present in the metatag)
    :root:has(meta[name="color-scheme"][content*="dark"]) :where(.tc_image__picture__img){background-image:url("inline!../../../../icons/logo.dark.svg");}
  }
}

@include respond-to(medium-inline) {
  :where([data-img-ratio-md="square"]){aspect-ratio:1/1;}
  :where([data-img-ratio-md="landscape"]){aspect-ratio:16/9;}
  :where([data-img-ratio-md="portrait"]){aspect-ratio:3/4;}
}

@include respond-to(large-inline) {
  :where([data-img-ratio-lg="square"]){aspect-ratio:1/1;}
  :where([data-img-ratio-lg="landscape"]){aspect-ratio:16/9;}
  :where([data-img-ratio-lg="portrait"]){aspect-ratio:3/4;}
}

@include respond-to(print) {
  .tc_image{max-width:100%;page-break-inside:avoid;}
  .tc_image--circular .tc_image__picture__img{border-radius:50%;}
  .tc_image__picture__img{width:100%;max-width:100%;max-height:100%;object-fit:contain;font-size:var(--font-12);}
}
