// BLOGPOST WRAPPER
@import "core";

@include respond-to(base-inline) {
  .tc_blogpost__wrapper{
    .tc_page__header{
      .tc_standfirst{font-size:var(--font-18-20);}
      .tc_byline{padding-top:calc(var(--spacing-0-step) + var(--spacing-4-step) + var(--spacing-4));}
      .tc_byline__author{gap:var(--spacing-16);
        .tc_image{min-width:var(--spacing-96);}
      }
    }
    .tc_page__meta{margin-bottom:calc(var(--spacing-8-step) * -1);}
  }
}

@include respond-to(medium-inline) {
  .tc_blogpost__wrapper{
    .tc_page__header{grid-template-columns:1fr min-content;grid-template-rows:min-content min-content 1fr;column-gap:var(--spacing-32);
      .tc_byline{grid-column:2;grid-row:1 / span 3;}
      .tc_byline__author .tc_image{min-width:calc(var(--spacing-72) * 2);}
      .tc_byline__author--profile{flex-direction:column;}
      .tc_byline__author__info{align-self:flex-start;padding-left:var(--spacing-8);}
      .tc_byline__author__name{white-space:normal;}
      .tc_byline__author__text{font-size:var(--font-14);}
      .tc_byline__author__location{font-size:var(--font-14);}
    }
  }
}

@include respond-to(large-inline) {
  .tc_blogpost__wrapper{
    .tc_page__header{column-gap:var(--spacing-48);margin-right:calc(-110 * var(--unit));
      .tc_byline__author .tc_image{min-width:calc(var(--spacing-88) * 2);}
    }
    .tc_page__media{margin:0 calc((100% - var(--bp-large) + (var(--gutter) * 2)) / 2);}
  }
}

@include respond-to(print) {
  .tc_blogpost__wrapper{
    .tc_page__header > .tc_byline{margin:0 0 var(--spacing-20);}
  }
}
