// LIVEWIDGET CYCLING
@import "core";

@include respond-to(base-inline) {
  .tc_livewidget{max-width:var(--main-medium);}
  .tc_livewidget__header{padding:var(--spacing-8) 0 var(--spacing-16);
    .tc_heading{display:inline-flex;flex-wrap:wrap;align-items:center;gap:var(--spacing-8);
      svg{width:var(--spacing-20);height:var(--spacing-20);}
    }
  }
  .tc_livewidget__header__subhead{display:flex;justify-content:space-between;padding-top:var(--spacing-8);font-size:var(--font-12);}

  .tc_livewidget__main{
    .tc_tabs{padding:var(--spacing-4) 0 0;background-color:var(--color-200);}
    .tc_tabs__content{background-color:var(--color-light);}
    .tc_tabs__list__item{height:calc(var(--spacing-16-jump) + var(--spacing-56));}
    .tc_tabs__list__item__link{min-width:calc(var(--spacing-8-jump) + var(--spacing-56));padding:var(--spacing-8-step);}
    .tc_tabs__group{text-align:center;}
    .tc_tabs__group__size,.tc_tabs__group__time{text-transform:initial;font-weight:var(--font-regular);color:var(--color-700);}
    .tc_tabs__group__jerseys{display:flex;justify-content:center;
      img{width:calc(17 * var(--unit));margin:calc(5 * var(--unit)) calc(3 * var(--unit));}
    }
    .tc_tabs__content{margin:0;}

    .tc_tabs--scrollable.tc_tabs--gapless{
      .tc_tabs__list__wrap::before{border-top:0;}
    }
  }
  .tc_livewidget__main__profile{position:relative;display:block;background-color:var(--color-400);min-height:var(--spacing-8);margin-top:var(--spacing-40);}
  .tc_livewidget__main__profile--graphic{margin:0;aspect-ratio:16/9;
    img{max-width:100%;}
  }
  .tc_livewidget__main__profile__finish{position:absolute;right:0;bottom:var(--spacing-16);}
  .tc_livewidget__tabgroup__text{text-align:center;padding:var(--spacing-8);font-size:var(--font-12);
    p:not(:last-child){margin-bottom:var(--spacing-8);}
  }
  .tc_livewidget__footer{
    .tc_tabs{padding:var(--spacing-24) 0 0;}
    .tc_tabs__list__item__link{padding-left:var(--spacing-8);padding-right:var(--spacing-8);}
  }
}

@include respond-to(print) {
  .tc_livewidget[data-type="cycling"]{border:var(--spacing-2) solid var(--color-black);
    .tc_livewidget__header{padding:var(--spacing-8);
      .tc_heading svg{display:inline-block;margin:0 var(--spacing-4);}
    }
    .tc_livewidget__main .tc_tabs{display:none;}
    .tc_livewidget__main__profile{display:none;}
    .tc_livewidget__main__profile--graphic{display:block;border-bottom:52.25vw solid var(--color-400);background:none!important;
      img{margin-bottom:-52.25vw;}
    }
    .tc_livewidget__footer{padding:var(--spacing-8);}
  }
}
