// TOURNAMENTS
@import "core";

@include respond-to(base-inline) {
  .tc_tournaments__list{list-style:none;display:flex;flex-direction:column;row-gap:var(--spacing-8);flex-wrap:wrap;}
  .tc_tournaments__list__item{
    &[data-gender="male"] strong::after{content:" (m)";}
    &[data-gender="female"] strong::after{content:" (k)";}
    img{min-width:var(--spacing-32);max-width:var(--spacing-32);}
  }
  .tc_tournaments__list__item__link{display:flex;gap:var(--spacing-16);align-items:center;text-decoration:none;color:var(--color-text);padding:var(--spacing-8) 0;
    strong{display:block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
    svg{margin-left:calc(var(--spacing-12) * -1);width:var(--spacing-16);height:var(--spacing-16);}
  }
}

@include respond-to(medium-inline) {
  .tc_tournaments__list{flex-direction:row;column-gap:var(--spacing-32);}
  .tc_tournaments__list__item{width:calc(50% - var(--spacing-16));}
}

@include respond-to(print) {
  .tc_tournaments{border:var(--spacing-2) solid var(--color-black);padding:var(--spacing-8);}
  .tc_tournaments__list{list-style:none;margin:0;padding:0;}
  .tc_tournaments__list__item{padding:0 0 var(--spacing-8);}
  .tc_tournaments__list__item__link{display:flex;gap:var(--spacing-4);
    img{width:var(--spacing-20);}
  }
}
