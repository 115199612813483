// WEATHER FORECAST WIDGET
@import "core";

@include respond-to(base-inline) {
  :root{
    --color-uv-1-3:  #F7E400;
    --color-uv-4:    #FCCC48;
    --color-uv-5:    #F88700;
    --color-uv-6:    #F85900;
    --color-uv-7:    #E82C0E;
    --color-uv-8:    #D8001D;
    --color-uv-9:    #FF0099;
    --color-uv-10:   #B54CFF;
    --color-uv-11:   #998CFF;
  }
  .tc_weather__forecast__widget{position:relative;display:flex;flex-direction:column;justify-content:center;align-items:center;color:var(--color-700);background:var(--color-light);}
  .tc_weather__forecast__widget__now{position:absolute;top:var(--spacing-4);left:0;font-size:var(--font-14);}
  .tc_weather__forecast__widget__temperature{position:absolute;top:var(--spacing-32);left:0;width:var(--spacing-88);text-align:center;font-size:var(--font-28-32-36);font-weight:var(--font-bold);color:var(--color-text);}
  .tc_weather__forecast__widget__windchill{position:absolute;top:var(--spacing-72);left:0;width:var(--spacing-88);text-align:center;font-size:var(--font-12);}
  .tc_weather__forecast__widget__symbol{width:var(--spacing-112);height:var(--spacing-112);margin:0 auto;}
  .tc_weather__forecast__widget__probability{display:block;padding-top:var(--spacing-4);font-size:var(--font-12);color:var(--color-link);white-space:nowrap;
    &::after{content:" risiko for nedbør";color:var(--color-700);}
  }
  .tc_weather__forecast__widget__info{position:absolute;right:var(--spacing-12);list-style:none;font-size:var(--font-12);display:flex;flex-direction:column;gap:var(--spacing-8);margin:auto;
    li{display:flex;white-space:nowrap;align-items:end;gap:var(--spacing-4);line-height:var(--line-130);}
    img,svg{width:var(--spacing-16);height:var(--spacing-16);}
    strong{font-size:var(--font-14);color:var(--color-text);}
    [data-uv],[data-uv="1"]{color:var(--color-uv-1-3);}
    [data-uv="4"]{color:var(--color-uv-4);}
    [data-uv="5"]{color:var(--color-uv-5);}
    [data-uv="6"]{color:var(--color-uv-6);}
    [data-uv="7"]{color:var(--color-uv-7);}
    [data-uv="8"]{color:var(--color-uv-8);}
    [data-uv="9"]{color:var(--color-uv-9);}
    [data-uv="10"]{color:var(--color-uv-10);}
    [data-uv^="1"]:not([data-uv="1"]){color:var(--color-uv-11);}
  }
  .tc_weather__forecast__widget__info__wind--high{color:var(--color-error);
    strong,svg{color:var(--color-error);}
  }
}

@include respond-to(small-inline) {
  .tc_weather__forecast__widget{max-width:calc(500 * var(--unit));}
  .tc_weather__forecast__widget__windchill{top:var(--spacing-80);}
  .tc_weather__forecast__widget__symbol{width:var(--spacing-128);height:var(--spacing-128);}
}

@include respond-to(medium-inline) {
  .tc_weather__forecast__widget__now{left:var(--spacing-20);}
  .tc_weather__forecast__widget__temperature,.tc_weather__forecast__widget__windchill{left:var(--spacing-12);width:var(--spacing-128);}
  .tc_weather__forecast__widget__info{right:var(--spacing-48);
    span{position:relative;top:var(--spacing-1);}
  }
}

@include respond-to(large-inline) {
  .tc_weather__forecast__widget__windchill{top:var(--spacing-88);}
}

@include respond-to(print) {
  .tc_weather__forecast__widget{display:flex;justify-content:center;}
  .tc_weather__forecast__widget__info{list-style:none;display:flex;flex-direction:column;gap:var(--spacing-16);
    img,svg{display:inline-block;width:var(--spacing-24);vertical-align:bottom;margin-right:var(--spacing-4);}
  }
  .tc_weather__forecast__widget__symbol{width:var(--spacing-128);}
  .tc_weather__forecast__widget__temperature{font-size:var(--font-36);font-weight:var(--font-bold);padding-left:var(--spacing-8);}
  .tc_weather__forecast__widget__probability{font-size:var(--font-14);
    &::after{content:" risiko for nedbør";;}
  }
}
