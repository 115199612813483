// ACCORDION
@import "core";

@include respond-to(base-inline) {
  :where(.tc_accordion){background-color:var(--color-dark-100);
    &[open]{
      .tc_accordion__summary::after{transform:rotate(180deg);}
      .tc_accordion__details{display:block;}
    }
    &:not(:last-of-type){margin-bottom:var(--spacing-8);}
  }
  :where(.tc_accordion__summary){position:relative;font-size:var(--font-16);line-height:var(--line-120);padding:var(--spacing-16) var(--spacing-64) var(--spacing-16) var(--spacing-16);cursor:pointer;font-weight:var(--font-medium);-webkit-user-select:none;user-select:none;
    &::after{content:"";position:absolute;top:0;bottom:0;right:var(--spacing-16);width:var(--spacing-24);height:var(--spacing-24);margin:auto;mask:url("inline!../../../../icons/chevron-down.svg") no-repeat 50% 50%;mask-size:var(--spacing-20) auto;background-color:var(--color-dark);}
    &[tabIndex="-1"]{pointer-events:none;
      &::after{display:none;}
    }
  }
  :where(.tc_accordion__details){display:none;padding-bottom:var(--spacing-16);
    .tc_image,.tc_video{margin:0 0 var(--spacing-16);
      &:last-child .tc_caption{border:0;}
    }
    .tc_caption{margin:0 var(--spacing-16);}
    .tc_richcontent{padding:0 var(--spacing-16);
      .tc_pageobject{margin:0 calc(var(--spacing-16) * -1);}
    }
  }
}

@include respond-to(print) {
  .tc_accordion{margin-bottom:var(--spacing-20);}
  .tc_accordion__summary{font-weight:var(--font-medium);}
  .tc_accordion__details{font-size:var(--font-16);}
}
