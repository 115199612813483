// POST
@import "core";

@include respond-to(base-inline) {
  .tc_post{position:relative;z-index:1;max-width:var(--main-medium);overflow:hidden;padding:var(--spacing-24) 0;scroll-margin-top:var(--spacing-64-jump);
    &::before{content:"";position:absolute;z-index:-1;left:var(--spacing-32);top:0;bottom:0;width:var(--spacing-2);background-color:var(--color-dark-500);}
    &[tabindex]{
      &:focus{outline:0;}
      &:focus-visible{outline:0;}
    }

    // layout
    .tc_post__header .tc_timestamp::before{background-color:var(--color-primary-tv2red);}
    .tc_post__body__status{color:light-dark(var(--color-dark-bordeaux), var(--color-bright-peach));}
    &[data-post-layout="sport"]{
      .tc_post__header .tc_timestamp::before{background-color:var(--color-primary-blue);}
      .tc_post__body__status{color:light-dark(var(--color-dark-nightblue), var(--color-bright-sky));}
    }
    &[data-post-layout="vejr"]{
      .tc_post__header .tc_timestamp::before{background-color:var(--color-primary-green);}
      .tc_post__body__status{color:light-dark(var(--color-dark-forestgreen), var(--color-light-green));}
    }
    &[data-post-layout="echo"]{
      .tc_post__header .tc_timestamp::before{background-color:var(--color-primary-violet);}
      .tc_post__body__status{color:light-dark(var(--color-dark-plum), var(--color-bright-lavendar));}
    }

    // Light theme fallback (iOS < 17.5)
    @supports not (color: light-dark(#fff, #000)) {
      .tc_post{
        .tc_post__body__status{color:var(--color-dark-bordeaux);}
        &[data-post-layout="sport"] .tc_post__body__status{color:var(--color-dark-nightblue);}
        &[data-post-layout="vejr"] .tc_post__body__status{color:var(--color-dark-forestgreen);}
        &[data-post-layout="echo"] .tc_post__body__status{color:var(--color-dark-plum);}
      }
    }
  }
  .tc_post__header{position:relative;padding:0 0 var(--spacing-8) calc(46 * var(--unit));top:calc(var(--spacing-2) * -1);
    .tc_timestamp{font-size:var(--font-12);
      &::before{content:"";position:absolute;left:calc(27 * var(--unit));top:var(--spacing-2);width:var(--spacing-12);height:var(--spacing-12);border-radius:50%;background-color:var(--color-900);}
    }
  }
  .tc_post__header__meta{display:flex;}
  .tc_post__header__meta__permalink{text-decoration:none;}
  .tc_post__body__post__share{
    .tc_share__button,.tc_share__print{
      &.tc_button--dark{color:var(--color-600);}
      &.tc_button--light{color:var(--color-300);}
    }
  }
}

@include respond-to(print) {
  .tc_post{page-break-inside:avoid;break-inside:avoid;}
  .tc_post__header{padding:0 0 var(--spacing-4);}
  .tc_post__body{border:var(--spacing-2) solid var(--color-black);padding:var(--spacing-8);margin:0 0 var(--spacing-32);}
}
