// EMBED TOOLS GRADES
@import "core";

@include respond-to(base) {
  .tc_tools__grades{
    .tc_accordion{outline:none;margin-bottom:var(--spacing-4);}
    .tc_accordion__summary{background-color:var(--color-neutral-800);color:var(--color-white);
      &::after{background-color:var(--color-white);}
    }
  }
  .tc_tools__grades__legend{list-style:none;display:flex;flex-direction:column;gap:var(--spacing-8-step);padding:var(--spacing-16) var(--spacing-16) 0;}
  .tc_tools__grades__legend__item{position:relative;font-size:var(--font-14);padding-left:calc(var(--spacing-24) + var(--spacing-4-step));}
  .tc_tools__grades__legend__item__grade{position:absolute;left:0;display:flex;justify-content:center;align-items:center;width:calc(var(--spacing-20) + var(--spacing-4-step));height:calc(var(--spacing-20) + var(--spacing-4-step));font-size:var(--font-12);font-weight:var(--font-medium);background-color:var(--color-800);color:var(--color-light);border-radius:50%;}
  .tc_tools__grades__list{list-style:none;padding:var(--spacing-jump) 0;}
  .tc_tools__grades__list__item{background-color:var(--color-light);margin-bottom:var(--spacing-4);padding:var(--spacing-8);}
  .tc_tools__grades__list__item__header{display:flex;align-items:center;margin-bottom:var(--spacing-8);
    .tc_image{width:min(calc(50 * var(--fluid-unit)), calc(70 * var(--static-unit)));margin-right:var(--spacing-8);}
  }
  .tc_tools__grades__list__item__headline__info{font-size:var(--font-14);}
  .tc_tools__grades__list__item__headline__info__club{color:var(--color-700);}
  .tc_tools__grades__list__item__headline__info__mvp{font-style:normal;color:var(--color-error);}
  .tc_tools__grades__list__item__body{display:flex;flex-direction:row-reverse;line-height:var(--line-130);
    .tc_richcontent{flex:1 1 auto;}
  }
  .tc_tools__grades__list__item__body__score{min-width:min(calc(50 * var(--fluid-unit)), calc(70 * var(--static-unit)));margin-right:var(--spacing-8);text-align:center;font-size:var(--font-28-32-36);font-weight:var(--font-bold);line-height:var(--line-110);}

  .tc_tools__grades__list__item__vote{padding-top:var(--spacing-8);border-top:var(--spacing-1) solid var(--color-100);
    .tc_form-fieldset__legend span{text-align:center;font-size:var(--font-16);}
    .tc_form-fieldset__body{justify-content:center;gap:var(--spacing-16);}
    .tc_form__group{flex:0 1 auto;width:var(--spacing-24);margin:0 0 var(--spacing-20);}
    .tc_form__radio:checked ~ .tc_form__label{
      span{color:var(--color-light);}
    }
    .tc_form__label{height:var(--spacing-24);padding:0;
      &::before{width:var(--spacing-24);height:var(--spacing-24);box-sizing:border-box;}
      span{position:absolute;z-index:2;left:0;display:flex;justify-content:center;align-items:center;width:var(--spacing-24);height:var(--spacing-24);font-size:var(--font-12);font-weight:var(--font-medium);}
      &::after{top:0;left:0;width:var(--spacing-24);height:var(--spacing-24);box-sizing:border-box;}
    }
  }
  .tc_tools__grades__list__item__vote__avg{text-align:center;font-size:var(--font-14);margin:0 0 var(--spacing-8);}

  [data-object-layout]{
    .tc_tools__grades .tc_accordion__summary,.tc_tools__grades__legend__item__grade,.tc_tools__grades__list__item__vote .tc_form__radio:checked ~ .tc_form__label::after{background-color:var(--color-primary-tv2red);}
    .tc_tools__grades__list__item__body__score{color:var(--color-primary-tv2red);}
  }
  [data-object-layout="sport"]{
    .tc_tools__grades .tc_accordion__summary,.tc_tools__grades__legend__item__grade,.tc_tools__grades__list__item__vote .tc_form__radio:checked ~ .tc_form__label::after{background-color:var(--color-primary-blue);}
    .tc_tools__grades__list__item__body__score{color:var(--color-primary-blue);}
  }
  [data-object-layout="vejr"]{
    .tc_tools__grades .tc_accordion__summary,.tc_tools__grades__legend__item__grade,.tc_tools__grades__list__item__vote .tc_form__radio:checked ~ .tc_form__label::after{background-color:var(--color-primary-green);}
    .tc_tools__grades__list__item__body__score{color:var(--color-primary-green);}
  }
  [data-object-layout="echo"]{
    .tc_tools__grades .tc_accordion__summary,.tc_tools__grades__legend__item__grade,.tc_tools__grades__list__item__vote .tc_form__radio:checked ~ .tc_form__label::after{background-color:var(--color-primary-violet);}
    .tc_tools__grades__list__item__body__score{color:var(--color-primary-violet);}
  }
}

@include respond-to(print) {
  .tc_tools__grades .tc_accordion{display:none;}
  .tc_tools__grades__list{list-style:none;margin:0;padding:0;font-size:var(--font-16);}
  .tc_tools__grades__list__item{border:var(--spacing-2) solid var(--color-black);padding:var(--spacing-8);margin-bottom:var(--spacing-8);}
  .tc_tools__grades__list__item__header{display:flex;gap:var(--spacing-8);margin-bottom:var(--spacing-8);
    .tc_image{width:var(--spacing-64);}
  }
  .tc_tools__grades__list__item__body{display:flex;flex-direction:row-reverse;gap:var(--spacing-8);
    .tc_richcontent{flex:1;}
  }
  .tc_tools__grades__list__item__body__score{width:var(--spacing-64);font-size:var(--font-24);font-weight:var(--font-bold);text-align:center;}
  .tc_tools__grades__list__item__vote{display:none;}
}