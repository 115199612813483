// SLIDER
@import "core";

@include respond-to(base-inline) {
  .tc_slider{display:flex;position:relative;
    &[style] .tc_slider__list > li{flex:1;overflow:hidden;}
    &:has(> .tc_slider__button){
      &,&.tc_slider--size-m-l{min-height:var(--spacing-40);}
      &.tc_slider--size-xs{min-height:var(--spacing-24);}
      &.tc_slider--size-s,&.tc_slider--size-s-m{min-height:var(--spacing-32);}
      &.tc_slider--size-l{min-height:var(--spacing-48);}
    }
  }
  .tc_slider__list{display:flex;flex:1;gap:var(--spacing-4-step);position:relative;list-style:none;overflow-x:auto;scrollbar-width:none;outline:none;scroll-behavior:smooth;
    &::-webkit-scrollbar{height:0;}
    & > li{position:relative;min-width:calc(90% / var(--items-bs, auto));max-width:calc(100% / var(--items-bs, none));
      :focus-visible{outline:0;}
      &:has(:focus-visible)::before{content:"";position:absolute;left:0;top:0;right:0;bottom:0;z-index:1;pointer-events:none;outline:var(--spacing-2) solid var(--color-focus);outline-offset:calc(var(--spacing-2) * -1);}
    }
  }
  .tc_slider__button{position:absolute;top:0;bottom:0%;margin:auto;z-index:3;opacity:0;pointer-events:none;transition:all var(--transition-fast);}
  .tc_slider__button--prev{left:calc(var(--spacing-8));}
  .tc_slider__button--next{right:calc(var(--spacing-8));}

  .tc_slider--gutter{
    .tc_slider__button--prev{left:0;}
    .tc_slider__button--next{right:0;}
  }

  .js_slider--snap .tc_slider__list{scroll-snap-type:x mandatory;
    & > li{scroll-snap-align:start;}
  }

  @media (scripting:none) {
    .tc_slider > .tc_button{display:none;}
  }
}

@include respond-to(medium-inline) {
  .tc_slider{
    &:has(> .tc_slider__button){
      &.tc_slider--size-s-m{min-height:var(--spacing-40);}
      &.tc_slider--size-m-l{min-height:var(--spacing-48);}
    }
  }
  .tc_slider__list{
    & > li{min-width:calc(90% / var(--items-md, var(--items-bs)));max-width:calc(100% / var(--items-md, var(--items-bs)));}
  }

  .tc_slider--gutter{padding-left:var(--spacing-48);padding-right:var(--spacing-48);
    & > .tc_button{opacity:1;pointer-events:auto;}
    &.tc_slider--size-xs{padding-left:var(--spacing-32);padding-right:var(--spacing-32);}
    &.tc_slider--size-s{padding-left:var(--spacing-40);padding-right:var(--spacing-40);}
    &.tc_slider--size-m-l,&.tc_slider--size-l{padding-left:var(--spacing-56);padding-right:var(--spacing-56);}
  }
}

@include respond-to(large-inline) {
  .tc_slider__list{
    & > li{min-width:calc(90% / var(--items-lg, var(--items-md, var(--items-bs))));max-width:calc(100% / var(--items-lg, var(--items-md, var(--items-bs))));}
  }
}

@include respond-to(hover) {
  .tc_slider:hover{
    & > .tc_button:not([disabled]){opacity:1;pointer-events:auto;}
  }
}

@include respond-to(print) {
  .tc_slider{display:none;}
}
