// CYCLINGJERSEYSTANDINGS DATATABLE

@import "core";

@include respond-to(base-inline) {
  .tc_datatable__jerseys--cycling{
    table tbody td{width:1%;
      &:first-child{text-align:left;}
      &:nth-child(2):not(:last-child){text-align:right;width:auto;}
      &:last-child{color:var(--color-text);text-align:right;}
      &.tc_datatable__jerseys__logo{font-weight:var(--font-medium);color:var(--color-text);
        img{display:inline-block;width:calc(var(--spacing-16) + var(--spacing-4-step));vertical-align:inherit;margin-right:var(--spacing-4);}
      }
    }
  }
}

@include respond-to(print) {
  .tc_datatable__jerseys__logo img{display:inline-block;margin-right:var(--spacing-4);}
}
