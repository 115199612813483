// STANDFIRST
@import "core";

@include respond-to(base-inline) {
  :where(.tc_standfirst){font-size:var(--font-20-24);line-height:var(--line-120);font-weight:var(--font-light);overflow-wrap:break-word;}
}

@include respond-to(print) {
  .tc_standfirst{font-size:var(--font-20);line-height:var(--line-120);font-weight:var(--font-light);margin:0 0 var(--spacing-20);}
}
