// SCOREBAORD
@import "core";

@include respond-to(base-inline) {
  .tc_scoreboard-racket{position:relative;background:var(--color-100);padding:var(--spacing-8-step) var(--spacing-8-step) var(--spacing-16-step);overflow:hidden;max-width:var(--main-medium);margin:0 auto;width:100%;box-sizing:border-box;
    &[aria-live]{
      caption{padding-right:var(--spacing-56);box-sizing:border-box;
        span{position:absolute;right:0;color:var(--color-live);font-size:var(--font-14);line-height:var(--line-110);}
      }
    }
  }
  .tc_scoreboard-racket__data{display:grid;grid-template-columns:auto minmax(min-content, 1fr);gap:var(--spacing-32);position:relative;
    &[data-serve="1"] th:first-child,&[data-serve="2"] th:last-child{
      &::after{position:absolute;content:"•";color:var(--color-live);right:0;top:0;}
    }
    caption{grid-column:1/3;font-size:var(--font-12);color:var(--color-700);text-align:left;padding-bottom:var(--spacing-4);border-bottom:var(--spacing-1) solid var(--color-300);}
    thead{display:flex;overflow:hidden;min-height:var(--spacing-56-step);
      tr,th{max-width:100%;}
    }
    tbody{display:flex;gap:var(--spacing-16-step);position:relative;counter-reset:set;max-width:100%;padding-right:var(--spacing-8);
      tr::before{content:counter(set);counter-increment:set;position:absolute;left:0;right:0;top:calc(var(--spacing-16) * -1);font-size:var(--font-12);font-weight:var(--font-regular);color:var(--color-600);text-align:center;}
    }
    tr{display:flex;flex-direction:column;position:relative;}
    th,td{display:flex;flex:1;align-items:center;padding:0;}
    th{position:relative;align-self:flex-start;font-size:var(--font-14-16);line-height:var(--line-120);padding-right:var(--spacing-12);text-align:left;
      span{display:block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
    }
    td{justify-content:flex-end;font-size:var(--font-20-24);font-weight:var(--font-bold);line-height:var(--line-110);color:var(--color-600);}
    [data-winner="p1"] td:first-of-type,[data-winner="p2"] td:last-of-type{color:var(--color-text);}
  }
}

@include respond-to(print) {
  .tc_scoreboard-racket{border:var(--spacing-2) solid var(--color-black);padding:var(--spacing-8);
    table{width:100%;
      th{width:50%;}
      td{text-align:center;}
    }
  }
}
