// PAGE OBJECT
@import "core";

@include respond-to(base-inline) {
  :where(.tc_pageobject){clear:both;
    &[data-object-type="ad"]{position:relative;}
    &[data-object-type="embed"]{position:relative;
      .tc_embed,.tc_embed__cookieshield{margin-bottom:var(--spacing-20);}
    }
  }
}

@include respond-to(medium-inline) {
  :where(.tc_pageobject){
    &[data-object-position="left"],&[data-object-position="left-full"]{float:left;clear:none;
      & > *{width:calc(300 * var(--unit));margin:var(--spacing-8) var(--spacing-24) var(--spacing-16) 0;}
    }
    &[data-object-position="right"]{float:right;clear:none;
      & > *{width:calc(300 * var(--unit));margin:var(--spacing-8) 0 var(--spacing-24) var(--spacing-16);}
    }
  }
}

@include respond-to(print) {
  .tc_pageobject{margin-bottom:var(--spacing-20);}
}
