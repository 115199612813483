// PAGE META
@import "core";

@include respond-to(base-inline) {
  .tc_page__meta{display:flex;flex-direction:column;gap:var(--spacing-4);}
}

@include respond-to(print) {
  .tc_page__meta{margin-bottom:var(--spacing-20);}
  .tc_timestamp{display:block;color:transparent;font-size:0;
    &::before{content:attr(title);color:var(--color-black);font-size:var(--font-14);}
  }
}
