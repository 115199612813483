// CANDIDATE TEST QUESTION RATE
@import "core";

@include respond-to(base-inline) {
  .tc_candidatetest__rating{position:relative;z-index:0;display:flex;justify-content:space-around;margin:0 0 var(--spacing-40-step);
    & + .tc_candidatetest__navigation{padding-top:var(--spacing-24);}
    &[data-useranswer-skipped="true"] + .tc_candidatetest__navigation .tc_button:last-child{color:var(--color-900);font-weight:bold;}
    .tc_candidatetest__result &[data-useranswer-skipped="true"]::before{content:"Dit svar: ubesvaret";position:absolute;z-index:2;top:calc(var(--spacing-48-step) * -1);left:0;font-size:var(--font-12-14);font-weight:var(--font-medium);padding:var(--spacing-4-step) var(--spacing-8-step);background:var(--color-election-9, var(--color-200));color:var(--color-election-6, var(--color-text));white-space:nowrap;}
  }
  .tc_candidatetest__rating__button{position:relative;display:flex;justify-content:center;width:var(--spacing-40-step);height:var(--spacing-40-step);border:var(--spacing-3) solid var(--color-election-10, var(--color-dark));border-radius:50%;background:var(--color-election-8, var(--color-light));color:var(--color-text);
    &::before{content:"";position:absolute;width:var(--spacing-24-step);height:var(--spacing-24-step);margin:auto;top:0;bottom:0;border-radius:50%;background:var(--color-election-9, var(--color-dark));opacity:0;transition:opacity var(--transition-normal);}
    &:not([disabled]){cursor:pointer;
      &:active::before{opacity:.7;}
    }
    &[data-useranswer="true"]::before,&[data-candidateanswer="true"]::before{opacity:1;}
    span{position:absolute;bottom:calc(var(--spacing-24) * -1);font-size:var(--font-12-14);font-weight:var(--font-medium);white-space:nowrap;pointer-events:none;}
    &:nth-child(even) span{color:transparent;}
    &[disabled]{
      &[data-useranswer="true"]{
        span{display:flex;justify-content:center;
          &::before{content:"Dit svar";position:absolute;z-index:2;top:calc(-82 * var(--unit));font-size:var(--font-12-14);font-weight:var(--font-medium);padding:calc(var(--spacing-4-step) - var(--spacing-1)) var(--spacing-8-step) calc(var(--spacing-4-step) + var(--spacing-1));background:var(--color-election-9, var(--color-200));color:var(--color-election-6, var(--color-text));white-space:nowrap;text-transform:none;}
          &::after{content:"";position:absolute;z-index:1;top:calc(-70 * var(--unit));width:calc(var(--spacing-12) + var(--spacing-4-step));height:calc(var(--spacing-12) + var(--spacing-4-step));background:var(--color-election-9, var(--color-200));transform:rotate(45deg);}
        }
      }
    }
    &[data-candidateanswer="true"]{
      &:not([data-useranswer="true"])::before{background-color:var(--color-election-5, var(--color-900));}
      &::after{content:"";position:absolute;z-index:1;bottom:calc(-52 * var(--unit));width:calc(var(--spacing-12) + var(--spacing-4-step));height:calc(var(--spacing-12) + var(--spacing-4-step));background:var(--color-election-4, var(--color-200));transform:rotate(45deg);}
    }
  }
  .tc_candidatetest__rating__answer{position:relative;z-index:0;background:var(--color-election-4, var(--color-200));padding:var(--spacing-8-step);
    .tc_heading{font-size:var(--font-14);
      &:not(:only-child){padding-bottom:var(--spacing-4);}
    }
    p{font-size:var(--font-14);}
  }
  [data-election-type="fv"] .tc_candidatetest__rating__answer,[data-election-type="ep"] .tc_candidatetest__rating__answer{color:var(--color-midnight);}
}

@include respond-to(small-inline) {
  .tc_candidatetest__rating{padding-left:var(--spacing-8);padding-right:var(--spacing-8);}
  .tc_candidatetest__rating__button{
    &[disabled]{
      &[data-useranswer="true"]{
        span{
          &::before{top:calc(-97 * var(--unit));}
          &::after{top:calc(-80 * var(--unit));}
        }
      }
    }
  }
}

@include respond-to(medium-inline) {
  .tc_candidatetest__rating__button{border-width:var(--spacing-4);
    &:nth-child(even) span{color:inherit;}
    &[disabled]{
      &[data-useranswer="true"]{
        span{
          &::before{top:calc(-112 * var(--unit));}
          &::after{top:calc(-89 * var(--unit));}
        }
      }
    }
    &[data-candidateanswer="true"]::after{bottom:calc(-62 * var(--unit));}
  }
}

@include respond-to(print) {
  .tc_candidatetest__rating{display:flex;justify-content:space-between;margin-bottom:var(--spacing-20);}
  .tc_candidatetest__rating__button{padding:0;border:0;background:none;color:var(--color-black);
    &[data-useranswer="true"]{font-weight:var(--font-bold);}
  }
}
