// RADAR
@import "core";

@include respond-to(base-inline) {
  .tc_radar{background-color:var(--color-100);min-height:var(--spacing-1);overflow:hidden;width:100%;margin:0 auto;max-width:var(--main-large);
    &:not([data-processed="js"]) .tc_radar__map__image[data-now="true"]{opacity:1;}
  }
  .tc_radar__map{position:relative;
    .tc_image__picture{aspect-ratio:990/790;}
  }
  .tc_radar__map__image{position:absolute;left:0;right:0;top:0;bottom:0;z-index:1;background-size:cover;opacity:0;
    &:first-of-type{z-index:1;}
    &::after{content:attr(data-radar-timestamp);display:block;position:absolute;left:var(--spacing-8-step);right:var(--spacing-8-step);bottom:calc(var(--spacing-32-step) * -1);margin:0 auto;font-size:var(--font-14);}
  }
  .tc_radar__slider{width:calc(100% - var(--spacing-16-jump));margin:var(--spacing-32-step) auto;max-width:var(--main-medium);}
  .tc_radar__slider__now{display:inline;position:relative;top:calc(var(--spacing-16) * -1);font-size:var(--font-14);margin-left:calc(var(--spacing-12) * -1);}
}

@include respond-to(large-inline) {
  .tc_deck--col2center .tc_radar{width:var(--main-large);margin-left:calc((var( --main-large) - var(--main-medium)) / 2 * -1);margin-right:calc((var(--main-large) - var(--main-medium)) / 2 * -1);}
  .tc_radar__map__image::after{max-width:var(--main-medium);}
}

@include respond-to(print) {
  .tc_radar__slider{display:none;}
}
