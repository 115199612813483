// PROFILE
@import "core";

@include respond-to(base-inline) {
  .tc_profile{display:grid;row-gap:var(--spacing-16);padding:var(--spacing-16) 0 var(--spacing-8);}
  .tc_profile__is-profile{grid-template-columns:min-content 1fr;column-gap:var(--spacing-16-jump);row-gap:var(--spacing-24);
    .tc_image{min-width:var(--spacing-96);}
    .tc_profile__name{display:flex;flex-direction:column;gap:var(--spacing-4-step);align-self:center;}
    .tc_profile__description{grid-column:1 / span 2;}
  }
  .tc_profile__name p{font-size:var(--font-14);}
  .tc_profile__description p{padding-bottom:var(--spacing-16);}
  .tc_profile__description__social{display:flex;flex-wrap:wrap;column-gap:var(--spacing-16-jump);row-gap:var(--spacing-16);list-style:none;
    li{display:flex;align-items:center;gap:var(--spacing-8);color:var(--color-600);}
    a{color:var(--color-link);text-decoration:underline;}
    a:visited{color:var(--color-link-visited);}
  }
}

@include respond-to(medium-inline) {
  .tc_profile__is-profile{
    .tc_image{min-width:calc(var(--spacing-72) * 2);}
  }
}

@include respond-to(large-inline) {
  .tc_profile__is-profile{
    .tc_image{grid-row:1 / span 2;min-width:calc(var(--spacing-88) * 2);}
    .tc_profile__name{padding-top:var(--spacing-16);}
    .tc_profile__description{grid-column:2;}
  }
}

@include respond-to(hover) {
  .tc_profile__description__social{
    a:hover{color:var(--color-link-hover);}
    a:visited:hover{color:var(--color-link-visited-hover);}
  }
}

@include respond-to(print) {
  .tc_profile{display:flex;flex-wrap:wrap;gap:3%;
    .tc_image{width:22%;}
  }
  .tc_profile__name{width:75%;align-self:center;word-wrap:break-word;
    p{margin:0;}
  }
  .tc_profile__description{padding-top:var(--spacing-16);}
  .tc_profile__description__social{list-style:none;margin:0 0 var(--spacing-20);padding:0;font-size:var(--font-16);
    li{display:flex;gap:var(--spacing-4);padding:0 0 var(--spacing-4);
      a{flex:1;position:relative;color:var(--color-black);text-decoration:none;
        &[href]:not([href^="mailto"]){color:transparent;
          &::after{content:attr(href);position:absolute;left:0;color:var(--color-black);text-decoration:none;}
        }
      }
    }
  }
}
