// NOTIFICATION
@import "core";

@include respond-to(base-inline) {
  :where(.tc_notification){display:flex;align-items:center;gap:var(--spacing-4-step);min-height:var(--spacing-24);padding:var(--spacing-4-step) var(--spacing-8-step);font-size:var(--font-12);line-height:var(--line-130);color:var(--color-text);
    svg{width:var(--spacing-20);height:var(--spacing-20);}
    &:not(.tc_notification--layout-subtle){background-color:var(--color-light);gap:var(--spacing-8-step);min-height:var(--spacing-32);
      svg{width:var(--spacing-24);height:var(--spacing-24);}
      &[class*="tc_notification--type"]{color:var(--color-midnight);}
      &.tc_notification--type-info{background-color:var(--color-info-bg);
        svg{fill:var(--color-info);}
      }
      &.tc_notification--type-success{background-color:var(--color-success-bg);
        svg{fill:var(--color-success);}
      }
      &.tc_notification--type-warning{background-color:var(--color-warning-bg);
        svg{fill:var(--color-warning);}
      }
      &.tc_notification--type-error{background-color:var(--color-error-bg);
        svg{fill:var(--color-error);}
      }
    }
  }
}

@include respond-to(print) {
  .tc_notification{display:none;}
}
