// EMBED TOOLS IMAGE-QUIZ
@import "core";

@include respond-to(base) {
  .tc_tools__image-quiz__item{padding:0 0 var(--spacing-64);
    .tc_heading{padding-bottom:var(--spacing-8);}
  }
  .tc_tools__image-quiz__item__vote{position:relative;display:flex;align-items:stretch;width:100%;}
  .tc_tools__image-quiz__item__vote__left,.tc_tools__image-quiz__item__vote__right{position:relative;width:50%;max-width:calc(50% - var(--spacing-2));background-color:var(--color-light);
    .tc_caption{border:0;color:var(--color-text);text-align:center;background-color:var(--color-light);font-size:var(--font-14);line-height:var(--line-120);padding:var(--spacing-8);}
  }
  .tc_tools__image-quiz__item__vote__left{margin-right:var(--spacing-2);}
  .tc_tools__image-quiz__item__vote__right{margin-left:var(--spacing-2);}
  .tc_tools__image-quiz__item__vote__button{position:absolute;left:0;right:0;top:0;bottom:0;z-index:1;width:100%;padding:0;background-color:transparent;cursor:pointer;border:0;color:transparent;}

  .tc_tools__image-quiz__item__result{background-color:var(--color-800);color:var(--color-light);padding:var(--spacing-16-jump) var(--spacing-8-jump);}
  .tc_tools__image-quiz__item__result__item{display:flex;padding:var(--spacing-8-jump) 0;
    .tc_image{min-width:calc(50 * var(--unit));}
  }
  .tc_tools__image-quiz__item__result__item__data{flex:1 1 auto;padding-left:var(--spacing-8-jump);max-width:calc(100% - (60 * var(--unit)));}
  .tc_tools__image-quiz__item__result__item__data__text{padding-bottom:var(--spacing-4);font-size:var(--font-14);overflow-wrap:break-word;}
  .tc_tools__image-quiz__item__result__item__data__value{display:flex;}
  .tc_tools__image-quiz__item__result__item__data__value__percent{padding-right:var(--spacing-8);font-size:var(--font-24-28);line-height:var(--line-110);font-weight:var(--font-bold);min-width:calc(65 * var(--unit));}
  .tc_tools__image-quiz__item__result__item__data__value__meter{appearance:none;background:linear-gradient(to right, var(--color-light), var(--color-light) 50%, transparent 50%, transparent 100% ) no-repeat 100% 0, var(--color-dark-700);background-size:199.9%;width:100%;height:var(--spacing-20-step);animation: 1s vote;
    &::-webkit-meter-bar{background:transparent;}
    &::-webkit-meter-optimum-value{background:transparent;}
    &::-moz-meter-bar{background:transparent;}
  }

  [data-object-layout] .tc_tools__image-quiz__item__result{background-color:var(--color-primary-tv2red);}
  [data-object-layout="sport"] .tc_tools__image-quiz__item__result{background-color:var(--color-primary-blue);}
  [data-object-layout="green"] .tc_tools__image-quiz__item__result{background-color:var(--color-primary-green);}
  [data-object-layout="echo"] .tc_tools__image-quiz__item__result{background-color:var(--color-primary-violet);}
}

@include respond-to(small) {
  .tc_tools__image-quiz__item__result__item .tc_image{min-width:calc(70 * var(--unit));}
  .tc_tools__image-quiz__item__result__item__data{max-width:calc(100% - (90 * var(--unit)));}
  .tc_tools__image-quiz__item__result__item__data__value__percent{min-width:calc(75 * var(--unit));}
}

@include respond-to(print) {
  .tc_tools__image-quiz__item__vote{display:flex;gap:var(--spacing-16);
    [class^="tc_tools__image-quiz__item__vote"]{width:50%;}
  }
  .tc_tools__image-quiz__item__vote__button{display:none;}
}
