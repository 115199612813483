// SEND COMMENT
@import "core";

@include respond-to(base-inline) {
  .tc_sendcomment{padding:var(--spacing-24) 0;
    .tc_notification{margin-bottom:var(--spacing-16);}
    .tc_accordion__summary{color:var(--color-link);font-weight:var(--font-bold);}
  }
  .tc_sendcomment__form{padding:var(--spacing-16);}
}

@include respond-to(print) {
  .tc_sendcomment{display:none;}
}
