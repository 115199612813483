// PARTNERCARD
@import "core";

@include respond-to(base-inline) {
  .tc_partnercard{background-color:var(--color-100);}
  .tc_partnercard__header{padding:var(--spacing-8-step) 0 0;margin:0 var(--spacing-16);}
  .tc_partnercard__body{font-size:var(--font-16);padding:var(--spacing-24-step) 0;margin:0 var(--spacing-16);
    &:empty{padding:0 0 var(--spacing-16-step);}
  }
}

@include respond-to(print) {
  .tc_partnercard{border:var(--spacing-1) solid var(--color-900);}
  .tc_partnercard__header{padding:var(--spacing-20) 0 0;margin:0 var(--spacing-20);border-bottom:var(--spacing-1) solid var(--color-300);}
  .tc_partnercard__body{padding:var(--spacing-20) 0;margin:0 var(--spacing-20);}
}
