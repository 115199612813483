// RICH CONTENT
@import "core";

@include respond-to(base-inline) {
  :where(.tc_richcontent){text-align:left;
    & > h2{font-size:var(--font-20);line-height:var(--line-110);font-weight:var(--font-bold);margin:0;padding-bottom:var(--spacing-4);overflow-wrap:break-word;clear:both;
      em,strong{font-weight:inherit;}
    }
    & > h3{font-size:var(--font-18);line-height:var(--line-120);font-weight:var(--font-bold);margin:0;padding-bottom:var(--spacing-4);overflow-wrap:break-word;clear:both;} /* currently only uses by recipes and migrated i2 factboxes */
    & > p,& > ul,& > ol{padding-bottom:var(--spacing-20);overflow-wrap:break-word;
      a{color:var(--color-link);text-decoration:underline;}
      a:visited{color:var(--color-link-visited);}
      small{font-size:var(--font-14);}
      a.play{display:inline-flex;align-items:center;line-height:var(--line-120);
        &::before{content:"";padding-left:var(--spacing-72);height:var(--spacing-32);background:url(https://web.tv2a.dk/gfx/logos/svg/tv2-play.svg) no-repeat 0 0;background-size:contain;}
      }
      &:last-child{padding-bottom:0;}
    }
    & > ul{margin-left:var(--spacing-16);}
    & > ol{margin-left:var(--spacing-24);
      li{
        &::marker{font-weight:var(--font-medium);}
        li::marker{font-weight:var(--font-regular);}
      }
    }
    & > ul,& > ol{clear:both;
      ul,ol{padding-bottom:0;margin:var(--spacing-4) 0 0 var(--spacing-8);}
      li{margin-left:var(--spacing-8);padding:var(--spacing-4) 0;}
    }
    & > blockquote{max-width:var(--main-medium);font-size:var(--font-24-32);line-height:var(--line-120);font-weight:var(--font-light);position:relative;z-index:1;margin:var(--spacing-12-step) calc(var(--spacing-0-step) * 8) var(--spacing-32-step);padding:var(--spacing-4) 0 0 calc(var(--spacing-40-step) + var(--spacing-0-step));overflow-wrap:break-word;clear:both;
      &::before{content:"“";position:absolute;left:0;top:0;display:flex;justify-content:center;width:var(--spacing-32-step);height:var(--spacing-32-step);border-radius:50%;background:var(--color-bright-sand);box-sizing:border-box;font-size:calc((44 * var(--unit)) + var(--spacing-0-step));font-weight:500;line-height:1.2;color:var(--color-midnight);}
      cite{font-size:var(--font-12-14);line-height:var(--line-140);display:block;font-weight:var(--font-regular);padding:var(--spacing-4-step) 0 0;color:var(--color-700);}

      [data-site="nyheder"] ~ #main &::before{background-color:var(--color-bright-peach);}
      [data-site="sport"] ~ #main &::before{background-color:var(--color-bright-sky);}
      [data-site="vejr"] ~ #main &::before{background-color:var(--color-light-green);}
      [data-site="echo"] ~ #main &::before{background-color:var(--color-bright-lavendar);}
    }
    & > dl{margin:0;padding-bottom:var(--spacing-20);
      dt{font-size:var(--font-18);line-height:var(--line-120);font-weight:var(--font-bold);margin:0;padding-bottom:var(--spacing-4);overflow-wrap:break-word;} // identical to 'h3'
      dd{margin:0;padding-bottom:var(--spacing-4);
        & > ul{list-style:none;
          li{padding:var(--spacing-4) 0 0;}
        }
      }
    }
    &::after{content:"";display:table;clear:both;}
  }

  :where(.tc_richcontent__align--center){text-align:center;}
  :where(.tc_richcontent__align--right){text-align:right;}

  // TEMP HACK (dagens overblik embed)
  .t2dailybrief .briefing-section{background:var(--color-light)!important;}

  // selector is true if user has overriden the OS setting on tv2.dk with darkmode (using the settings switch)
  :root:has(meta[name="color-scheme"][content="dark"]) :where(.tc_richcontent){
    & > p,& > ul,& > ol{
      a.play::before{background-image:url(https://web.tv2a.dk/gfx/logos/svg/tv2-play_white.svg);}
    }
  }

  @media (prefers-color-scheme:dark) {
    // selector is true if user has set darkmode on OS level (but only if it's supported - ie. "dark" is present in the metatag)
    :root:has(meta[name="color-scheme"][content*="dark"]) :where(.tc_richcontent){
      & > p,& > ul,& > ol{
        a.play::before{background-image:url(https://web.tv2a.dk/gfx/logos/svg/tv2-play_white.svg);}
      }
    }
  }
}

@include respond-to(hover) {
  :where(.tc_richcontent){
    & > p,& > ul,& > ol{
      a{transition:color var(--transition-faster) var(--link-easing-hover);
        &:hover{color:var(--color-link-hover);}
        &:visited:hover{color:var(--color-link-visited-hover);}
      }
    }
  }
}

@include respond-to(print) {
  .tc_richcontent{overflow-wrap:break-word;
    & > h2{font-size:var(--font-20);line-height:var(--line-110);padding-bottom:var(--spacing-4);margin:0;}
    & > h3{font-size:var(--font-18);line-height:var(--line-110);padding-bottom:var(--spacing-4);margin:0;}
    & > p,& > ul,& > ol{margin:0 0 var(--spacing-20);}
    & > ol{
      li{
        &::marker{font-weight:var(--font-medium);}
        li::marker{font-weight:var(--font-regular);}
      }
    }
    & > blockquote{display:block;page-break-inside:avoid;font-size:var(--font-36);line-height:var(--line-110);font-weight:var(--font-bold);margin:var(--spacing-48) var(--spacing-80);
      &::before{content:"“";display:block;font-size:var(--font-60);margin:0 0 calc(var(--spacing-56) * -1) calc(var(--spacing-40) * -1);}
      cite{display:block;text-align:right;font-size:var(--font-14);font-weight:var(--font-regular);margin-top:calc(var(--spacing-16) * -1);}
    }
    a{font-weight:var(--font-regular);text-decoration:underline;color:var(--color-black);
      &::after{content:' ('attr(href)')';color:var(--color-black);font-size:var(--font-14);font-style:italic;font-variation-settings:'slnt' 10, 'ital' 1;text-decoration:none;}
    }
    .tc_pageobject a::after{display:none;}
    & > dl{margin:0;padding-bottom:var(--spacing-20);
      dt{font-size:var(--font-18);line-height:var(--line-120);padding-bottom:var(--spacing-4);margin:0;} // identical to 'h3'
      dd{margin:0;
        & > ul{list-style:none;}
      }
    }
    &::after{content:"";display:table;clear:both;}
  }
}
