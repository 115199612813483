// Skeleton
@import "core";

@include respond-to(base-nomediaquery) {
  @keyframes skeleton {
    100%{transform:translateX(100%);}
  }
}

@include respond-to(base-inline) {
  :where(.tc_skeleton){position:relative;display:flex;flex-direction:column;background:var(--color-light);box-sizing:border-box;padding:2%;color:var(--color-light);overflow:hidden;border:var(--spacing-1) solid var(--color-100);
    svg{margin:auto;width:5em;height:5em;max-width:70%;max-height:70%;position:absolute;left:0;right:0;top:0;bottom:0;
      &[fill="#ebebed"]{fill:#ebebed;} // special tv 2 logo case
    }
    .tc_skeleton{display:flex;justify-content:center;flex:1 1 auto;padding:0;border:0;background-color:transparent;}
    &[data-skeleton-variant]{background-color:var(--color-100);margin:1%;
      :where([data-skeleton-variant]){background-color:var(--color-light);}
    }
    &[data-skeleton-variant="line"]{min-height:.5em;max-height:.5em;border-radius:.2em;}
    &[data-skeleton-variant="circle"]{border-radius:50%;clip-path:circle(100%);
      &:not([style]){padding-bottom:100%;}
    }
    &[data-skeleton-variant="rect"]{padding:1%;
      &:not([style]){padding-bottom:100%;}
    }
    &[data-skeleton-variant="spacing"]{background-color:transparent;}

    &[data-skeleton-align="left"]{margin-right:auto;}
    &[data-skeleton-align="center"]{margin-left:auto;margin-right:auto;}
    &[data-skeleton-align="right"]{margin-left:auto;}
    &[data-skeleton-align="top"]{margin-bottom:auto;}
    &[data-skeleton-align="bottom"]{margin-top:auto;}

    &[data-skeleton-direction="row"]{flex-direction:row;justify-content:flex-start;align-items:center;}
  }

  :where(.tc_skeleton--animation .tc_skeleton)[data-skeleton-variant]:not([data-skeleton-variant="spacing"])::before{content:"";position:absolute;left:0;right:0;bottom:0;top:0;animation:skeleton 1.6s linear var(--transition-slowest) infinite;background-image:linear-gradient(90deg, transparent, var(--color-dark-100), transparent);transform:translateX(-100%);}

  :where(.tc_skeleton__overlay){position:absolute;left:0;right:0;top:0;bottom:0;padding:5%;z-index:1;color:var(--color-text);display:flex;flex-direction:column;justify-content:center;align-items:center;text-align:center;
    & > *{margin:.8em 0;}
  }
}

@include respond-to(medium-inline) {
  :where(.tc_skeleton__overlay){padding-left:15%;padding-right:15%;}
}

@include respond-to(print) {
  .tc_skeleton:not(.tc_skeleton .tc_skeleton){display:flex;justify-content:center;align-items:center;border:var(--spacing-2) solid var(--color-black);aspect-ratio:16/9;overflow:hidden;}
  .tc_skeleton .tc_skeleton{display:none;}
}
