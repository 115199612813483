// LIVEWIDGET MATCHES
@import "core";

@include respond-to(base-inline) {
  .tc_livewidget{
    &.tc_livewidget--simple{
      .tc_incidents{background-color:var(--color-100);}
      .tc_tabs__list__item{display:none;}
      .tc_tabs__content{margin-top:0;}
    }
    &:not(.tc_livewidget--simple){
      .tc_scoreboard{background-color:transparent;padding-left:0;padding-right:0;}
    }
  }

  .tc_livewidget__main{
    .tc_tabs{padding:var(--spacing-12) 0 0;}
    .tc_tabs__list__item__link{padding-left:var(--spacing-8);padding-right:var(--spacing-8);}
    .tc_incidents{background-color:var(--color-light);}
    .tc_datatable__standings--match{position:relative;padding:var(--spacing-16) 0 0;
      & > .tc_heading{display:none;
        & + table caption{display:table-caption;padding:var(--spacing-20) var(--spacing-8-step);}
      }
      & + .tc_button__group{padding:var(--spacing-8) 0;}
    }
    .tc_lineup > .tc_accordion{background-color:var(--color-light);}
    .tc_comparestats{padding:var(--spacing-16);background-color:var(--color-light);}
    .tc_eventinfo{padding: 0 var(--spacing-16) var(--spacing-24);background-color:var(--color-light);
      [role=rowgroup]{padding-top: var(--spacing-16);}
    }
  }
  .tc_livewidget__footer{display:flex;flex-direction:column;gap:var(--spacing-8);padding:0 0 var(--spacing-16);}
}

@include respond-to(small-inline) {
  .tc_livewidget{width:var(--main-small);}
  .tc_livewidget__main{
    .tc_tabs{padding-top:var(--spacing-16);}
  }
}

@include respond-to(medium-inline) {
  .tc_livewidget{width:var(--main-medium);}
  .tc_livewidget__main{
    .tc_eventinfo [role=rowgroup]{grid-template-columns:1fr;}
  }
}

@include respond-to(print) {
  .tc_livewidget:not([type="cycling"]){border:var(--spacing-2) solid var(--color-black);
    .tc_livewidget__header{padding:var(--spacing-8);}
    .tc_tabs__content__pane > :first-child{border:0;border-top:var(--spacing-2) solid var(--color-black);}
      .tc_datatable{padding:var(--spacing-8);}
  }
}
