// EMBED TOOLS LINEUP
@import "core";

@include respond-to(base) {
  .tc_tools__lineup{
    &[data-widget-variant="handball"]{
      .tc_tools__lineup__avatar{width:var(--spacing-72);
        .tc_image,.tc_tools__lineup__avatar__placeholder-image{width:var(--spacing-40);height:var(--spacing-40);}
      }
      [data-widget-position="goalkeeper"]{bottom:10%;left:0;right:0;margin:auto;}
      [data-widget-position="wing_left"]{top:55%;left:0;}
      [data-widget-position="back_left"]{top:20%;left:12%;}
      [data-widget-position="playmaker"]{top:-5%;left:0;right:0;margin:auto;}
      [data-widget-position="back_right"]{top:20%;right:12%;}
      [data-widget-position="wing_right"]{top:55%;right:0;}
      [data-widget-position="pivot"]{top:30%;left:0;right:0;margin:auto;}
    }
    &[data-widget-variant="football"]{
      .tc_tools__lineup__avatar{width:var(--spacing-56);
        .tc_image,.tc_tools__lineup__avatar__placeholder-image{width:var(--spacing-32);height:var(--spacing-32);}
      }
      [data-widget-position="goalkeeper"]{bottom:0;left:0;right:0;margin:auto;width:90%;}
      [data-widget-position="wing_left"]{top:55%;left:3%;}
      [data-widget-position="back_left"]{top:25%;left:10%;}
      [data-widget-position="playmaker"]{top:-5%;left:0;right:0;margin:auto;}
      [data-widget-position="back_right"]{top:25%;right:10%;}
      [data-widget-position="wing_right"]{top:55%;right:3%;}
      [data-widget-position="pivot"]{top:25%;left:0;right:0;margin:auto;}
    }
  }
  .tc_tools__lineup__field{position:relative;max-width:calc(450 * var(--unit));margin:0 auto var(--spacing-8);
    img{width:100%;}
  }
  .tc_tools__lineup__avatar{position:absolute;display:flex;flex-direction:column;align-items:center;text-align:center;
    .tc_image__picture,.tc_tools__lineup__avatar__placeholder-image{border-radius:50%;}
  }
  .tc_tools__lineup__avatar__name{font-size:var(--font-12);line-height:var(--line-110);color:var(--color-white);text-shadow:0 0 var(--spacing-2) var(--color-neutral-900);padding-top:var(--spacing-4);}
  .tc_tools__lineup__list{list-style:none;background:var(--color-light);padding-bottom:var(--spacing-8);}
  .tc_tools__lineup__item{position:relative;display:flex;padding:var(--spacing-8) 0;margin:0 var(--spacing-8-jump);border-bottom:var(--spacing-1) solid var(--color-200);
    .tc_image,.tc_tools__lineup__list__placeholder-image{width:var(--spacing-56);margin:0 var(--spacing-8) 0 0;}
    &:last-child{border:0;}
    .tc_richcontent{
      & > *:last-child{padding:0;}
    }
  }
  .tc_tools__lineup__item__content{flex:1 1 auto;padding:var(--spacing-4) 0 0;}
  .tc_tools__lineup__item__data{font-size:var(--font-14);line-height:var(--line-120);color:var(--color-600);padding-bottom:var(--spacing-4);}
  .tc_tools__lineup__item__data__position-name:not(:first-child){text-transform:lowercase;}

  .tc_tools__lineup__avatar__placeholder-image,.tc_tools__lineup__list__placeholder-image{display:flex;justify-content:center;align-items:center;background:var(--color-neutral-100);aspect-ratio:1/1;
    svg{fill:var(--color-neutral-400);}
  }
}

@include respond-to(small) {
  .tc_tools__lineup{
    &[data-widget-variant="handball"]{
      .tc_tools__lineup__avatar{width:var(--spacing-112);
        .tc_image,.tc_tools__lineup__avatar__placeholder-image{width:var(--spacing-56);height:var(--spacing-56);}
      }
    }
    &[data-widget-variant="football"]{
      .tc_tools__lineup__avatar{width:var(--spacing-96);
        .tc_image,.tc_tools__lineup__avatar__placeholder-image{width:var(--spacing-48);height:var(--spacing-48);}
      }
    }
  }
  .tc_tools__lineup__item{
    .tc_image,.tc_tools__lineup__list__placeholder-image{width:var(--spacing-80);}
  }
}

@include respond-to(print) {
  .tc_tools__lineup__field{display:none;}
  .tc_tools__lineup__list{list-style:none;margin:0;padding:0;font-size:var(--font-16);}
  .tc_tools__lineup__item{display:flex;gap:var(--spacing-8);border:var(--spacing-2) solid var(--color-black);padding:var(--spacing-8);margin-bottom:var(--spacing-8);
    .tc_image{width:var(--spacing-64);}
  }
}