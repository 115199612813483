// MOSAIC
@import "core";

@include respond-to(base-inline) {
  .tc_mosaic__grid{display:grid;grid-template-columns:repeat(3, minmax(0, 1fr));gap:var(--spacing-8);background-color:var(--color-light);
    &[data-mosaic-size="large"]{grid-template-columns:repeat(2, minmax(0, 1fr));
      .tc_mosaic__item{
        &:nth-child(3n+2) .tc_mosaic__item__content{right:auto;}
        &:nth-child(3n+3) .tc_mosaic__item__content{right:auto;}
        &:nth-child(2n+2) .tc_mosaic__item__content{right:calc(100% + var(--spacing-8));}
      }
      .tc_mosaic__item__content{width:calc(200% + var(--spacing-8));}
    }
  }
  .tc_mosaic__item{
    &:nth-child(3n+2) .tc_mosaic__item__content{right:calc(100% + var(--spacing-8));}
    &:nth-child(3n+3) .tc_mosaic__item__content{right:calc(200% + var(--spacing-16));}
    &[open] .tc_mosaic__item__header{border-color:var(--color-400);
      &::after{display:block;}
    }
  }
  .tc_mosaic__item__header{position:relative;border:var(--spacing-1) solid var(--color-300);
    &::after{display:none;content:"";width:0;height:0;border-left:calc(var(--spacing-8) + var(--spacing-4-step)) solid transparent;border-right:calc(var(--spacing-8) + var(--spacing-4-step)) solid transparent;border-bottom:calc(var(--spacing-8) + var(--spacing-4-step)) solid var(--color-100);position:absolute;bottom:calc((var(--spacing-16-step) + var(--spacing-1)) * -1);left:calc(50% - var(--spacing-12));z-index:2;}
    & > .tc_image{width:var(--spacing-64-step);margin:var(--spacing-24-step) auto var(--spacing-16);}
    & > .tc_heading{padding:var(--spacing-4) var(--spacing-8) calc(var(--spacing-4) + var(--spacing-4-step));white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
  }
  .tc_mosaic__item__header__infobar{position:absolute;top:0;left:0;right:0;height:var(--spacing-32);background-color:var(--color-100);z-index:1;overflow:hidden;
    & + .tc_image{margin:var(--spacing-40-step) auto 0;}
  }
  .tc_mosaic__item__content{position:relative;background-color:var(--color-100);box-sizing:border-box;width:calc(300% + var(--spacing-16));margin:var(--spacing-16-step) 0 var(--spacing-8-step);padding:var(--spacing-16-step) var(--spacing-8-step) var(--spacing-8);}
}

@include respond-to(medium-inline) {
  .tc_mosaic__grid{grid-template-columns:repeat(4, minmax(0, 1fr));
    &[data-mosaic-size="large"]{grid-template-columns:repeat(3, minmax(0, 1fr));
      .tc_mosaic__item{
        &:nth-child(2n+2) .tc_mosaic__item__content{right:auto;}
        &:nth-child(4n+2) .tc_mosaic__item__content{right:auto;}
        &:nth-child(4n+3) .tc_mosaic__item__content{right:auto;}
        &:nth-child(4n+4) .tc_mosaic__item__content{right:auto;}
        &:nth-child(3n+2) .tc_mosaic__item__content{right:calc(100% + var(--spacing-8));}
        &:nth-child(3n+3) .tc_mosaic__item__content{right:calc(200% + var(--spacing-16));}
      }
      .tc_mosaic__item__header .tc_heading{font-size:var(--font-16-18);}
      .tc_mosaic__item__content{width:calc(300% + var(--spacing-16));}
    }
  }
  .tc_mosaic__item{
    &:nth-child(3n+2) .tc_mosaic__item__content{right:auto}
    &:nth-child(3n+3) .tc_mosaic__item__content{right:auto}
    &:nth-child(4n+2) .tc_mosaic__item__content{right:calc(100% + var(--spacing-8));}
    &:nth-child(4n+3) .tc_mosaic__item__content{right:calc(200% + var(--spacing-16));}
    &:nth-child(4n+4) .tc_mosaic__item__content{right:calc(300% + var(--spacing-24));}
  }
  .tc_mosaic__item__header{position:relative;border:var(--spacing-1) solid var(--color-300);
    &::after{display:none;content:"";width:0;height:0;border-left:calc(var(--spacing-8) + var(--spacing-4-step)) solid transparent;border-right:calc(var(--spacing-8) + var(--spacing-4-step)) solid transparent;border-bottom:calc(var(--spacing-8) + var(--spacing-4-step)) solid var(--color-100);position:absolute;bottom:calc((var(--spacing-16-step) + var(--spacing-1)) * -1);left:calc(50% - var(--spacing-12));z-index:2;}
  }
  .tc_mosaic__item__header__infobar{height:calc(36 * var(--unit));}
  .tc_mosaic__item__content{width:calc(400% + var(--spacing-24));}
}

@include respond-to(hover) {
  .tc_mosaic__item__header{cursor:pointer;}
}

@include respond-to(print) {
  .tc_mosaic__grid{display:flex;flex-wrap:wrap;gap:1.5%;}
  .tc_mosaic__item{width:32%;box-sizing:border-box;border:var(--spacing-2) solid var(--color-black);margin-bottom:1.5%;
    .tc_image{width:var(--spacing-80);}
  }
  .tc_mosaic__item__header{display:flex;flex-direction:column;justify-content:center;align-items:center;gap:var(--spacing-8);
    .tc_heading{padding:0 var(--spacing-8);}
  }
  .tc_mosaic__item__header__infobar{flex:1;padding:var(--spacing-8);border-bottom:var(--spacing-1) solid var(--color-black);align-self:stretch;}
  .tc_mosaic__item__content{padding:var(--spacing-8);}
}
