// NAV DROPDOWN
@import "core";

@include respond-to(base-inline) {
  .tc_navdropdown{
    .tc_form__select{font-weight:var(--font-medium);}
  }
}

@include respond-to(print) {
  .tc_navdropdown{display:none;}
}