// LIVEBLOG WRAPPER
@import "core";

@include respond-to(base-inline) {
  .tc_liveblog__wrapper{
    &[data-type="newsfeed"]{background-color:var(--color-newsfeed);
      .tc_page__header{display:flex;align-items:flex-end;
        .tc_heading{flex:1;}
      }
      .tc_pageheader__tip{display:flex;gap:var(--spacing-4);align-items:center;font-size:var(--font-14);white-space:nowrap;padding-bottom:var(--spacing-2);
        svg{width:var(--spacing-20);height:var(--spacing-20);}
        a{color:var(--color-dark);text-decoration:none;}
      }
    }
    .tc_grid.tc_grid--gap-large{row-gap:var(--spacing-32-step);}
    .tc_livewidget{padding-bottom:var(--spacing-16);}
    [data-bundle="LiveblogBody"]{width:100%;}
  }
  [data-ad="interscroll"]{
    [data-interscroll-position="true"].tc_ad--tag::before{background-color:var(--color-liveblog);}
    [data-type="newsfeed"] [data-interscroll-position="true"].tc_ad--tag::before{background-color:var(--color-newsfeed);}
  }
}

@include respond-to(medium-inline) {
  .tc_liveblog__wrapper{
    [data-bundle="LiveblogBody"]{width:auto;}
    &[data-type="newsfeed"]{
      .tc_pageheader__tip{padding-bottom:var(--spacing-4);}
    }
  }
}

@include respond-to(large-inline) {
  .tc_liveblog__wrapper{
    .tc_page__header .tc_heading{max-width:calc(755 * var(--unit));}
    .tc_page__header__meta{padding-right:calc(320 * var(--unit));}
  }
}

@include respond-to(hover) {
  .tc_liveblog__wrapper[data-type="newsfeed"]{
    .tc_pageheader__tip{
      a:hover{text-decoration:underline;}
    }
  }
}

@include respond-to(print) {
  .tc_liveblog__wrapper{max-width:var(--main-medium);margin:0 auto;
    .tc_image,.tc_video{margin:0 0 var(--spacing-20);}
    .tc_grid > .tc_byline{margin:0 0 var(--spacing-20);}
  }
  .tc_liveblog__wrapper[data-type="newsfeed"]{
    .tc_pageheader__tip{display:none}
  }
}
