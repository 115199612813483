// BASE LAYOUT (applied to all components and pages across all sites)
@import "core";

@include respond-to(base-inline) {
  html{-webkit-text-size-adjust:100%;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;
    &::after{content:"base";position:absolute;top:0;right:50%;height:0;overflow:hidden;z-index:-1;}
  }
  body{font-family:var(--font-family);font-size:var(--font-16-18);line-height:var(--line-140);color:var(--color-text);overflow-x:hidden;}
  .tc_root{position:relative;z-index:0;} // .tc_root is equivalent to #storybook-root in storybook

  @media (prefers-reduced-motion:reduce) {
    *{animation:none!important;transition:none!important;
      &::before,&::after{animation:none!important;transition:none!important;}
    }
  }
}

@include respond-to(small-inline) {
  html::after{content:"small";}
}

@include respond-to(medium-inline) {
  html::after{content:"medium";}
}

@include respond-to(large-inline) {
  html::after{content:"large";}
}

@include respond-to(print) {
  body{font-family:var(--font-family);font-size:var(--font-18);line-height:var(--line-140);color:var(--color-black);margin:var(--spacing-8);}
}
