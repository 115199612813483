@import "core";

@include respond-to(base-inline) {
  :root{
    // UNIT
    --fluid-unit: 1vw / 3.6; // takes 'px' and returns unit in 'vw' (baseline is 360px)
    --static-unit: 1rem / 16; // takes 'px' and returns unit in 'rem'

    --unit: var(--fluid-unit); // takes 'px' and makes it fluid on 'base' breakpoint and static on 'small' breakpoint and up.

    // BREAKPOINTS
    --bp-base: 100%;
    --bp-small: calc(480 * var(--static-unit));
    --bp-medium: calc(680 * var(--static-unit));
    --bp-large: calc(1000 * var(--static-unit));
    --bp: var(--bp-base);

    // WIDTH OF <MAIN> IN BREAKPOINTS
    --main-base: 100%;
    --main-small: calc(432 * var(--static-unit));
    --main-medium: calc(624 * var(--static-unit));
    --main-large: calc(944 * var(--static-unit));
    --main: var(--main-base);

    // SPACING
    --spacing-base: calc(8 * var(--unit));
    --spacing-step: 0px;
    --spacing-jump: 1;

    --spacing-1: calc(1 * var(--unit));
    --spacing-2: calc(2 * var(--unit));
    --spacing-3: calc(3 * var(--unit));
    --spacing-4: calc(var(--spacing-base) / 2);
    --spacing-8: var(--spacing-base);
    --spacing-12: calc(var(--spacing-base) * 1.5);
    --spacing-16: calc(var(--spacing-base) * 2);
    --spacing-20: calc(var(--spacing-base) * 2.5);
    --spacing-24: calc(var(--spacing-base) * 3);
    --spacing-32: calc(var(--spacing-base) * 4);
    --spacing-40: calc(var(--spacing-base) * 5);
    --spacing-48: calc(var(--spacing-base) * 6);
    --spacing-56: calc(var(--spacing-base) * 7);
    --spacing-64: calc(var(--spacing-base) * 8);
    --spacing-72: calc(var(--spacing-base) * 9);
    --spacing-80: calc(var(--spacing-base) * 10);
    --spacing-88: calc(var(--spacing-base) * 11);
    --spacing-96: calc(var(--spacing-base) * 12);
    --spacing-104: calc(var(--spacing-base) * 13);
    --spacing-112: calc(var(--spacing-base) * 14);
    --spacing-120: calc(var(--spacing-base) * 15);
    --spacing-128: calc(var(--spacing-base) * 16);

    --spacing-0-step: var(--spacing-step);
    --spacing-4-step: calc(var(--spacing-4) + (var(--spacing-step) / 2));
    --spacing-8-step: calc(var(--spacing-8) + var(--spacing-step));
    --spacing-12-step: calc(var(--spacing-12) + var(--spacing-step));
    --spacing-16-step: calc(var(--spacing-16) + var(--spacing-step));
    --spacing-20-step: calc(var(--spacing-20) + var(--spacing-step));
    --spacing-24-step: calc(var(--spacing-24) + var(--spacing-step));
    --spacing-32-step: calc(var(--spacing-32) + var(--spacing-step));
    --spacing-40-step: calc(var(--spacing-40) + var(--spacing-step));
    --spacing-48-step: calc(var(--spacing-48) + var(--spacing-step));
    --spacing-56-step: calc(var(--spacing-56) + var(--spacing-step));
    --spacing-64-step: calc(var(--spacing-64) + var(--spacing-step));
    --spacing-72-step: calc(var(--spacing-72) + var(--spacing-step));
    --spacing-80-step: calc(var(--spacing-80) + var(--spacing-step));
    --spacing-88-step: calc(var(--spacing-88) + var(--spacing-step));
    --spacing-96-step: calc(var(--spacing-96) + var(--spacing-step));
    --spacing-104-step: calc(var(--spacing-104) + var(--spacing-step));
    --spacing-112-step: calc(var(--spacing-112) + var(--spacing-step));
    --spacing-120-step: calc(var(--spacing-120) + var(--spacing-step));
    --spacing-128-step: calc(var(--spacing-128) + var(--spacing-step));

    --spacing-8-jump: calc(var(--spacing-8) * var(--spacing-jump));
    --spacing-16-jump: calc(var(--spacing-16) * var(--spacing-jump));
    --spacing-32-jump: calc(var(--spacing-32) * var(--spacing-jump));
    --spacing-64-jump: calc(var(--spacing-64) * var(--spacing-jump));
    --spacing-128-jump: calc(var(--spacing-128) * var(--spacing-jump));

    // GUTTER
    --gutter: calc(var(--spacing-24) / 2);

    // FONTS (10, 12, 14, 16, 18, 20, 24, 28, 32, 36, 48, 60, 76, 92)
    --font-family: 'TV2','Helvetica Neue','Segoe UI',sans-serif;

    // font-{b}-{m}-{l}
    --font-10: calc(10 * var(--unit));
    --font-10-12: calc(10 * var(--unit));
    --font-10-10-16: calc(10 * var(--unit));
    --font-10-14: calc(10 * var(--unit));
    --font-12: calc(12 * var(--unit));
    --font-12-14: calc(12 * var(--unit));
    --font-14: calc(14 * var(--unit));
    --font-14-16: calc(14 * var(--unit));
    --font-16: calc(16 * var(--unit));
    --font-16-18: calc(16 * var(--unit));
    --font-16-20: calc(16 * var(--unit));
    --font-18: calc(18 * var(--unit));
    --font-18-20: calc(18 * var(--unit));
    --font-18-24: calc(18 * var(--unit));
    --font-20: calc(20 * var(--unit));
    --font-20-24: calc(20 * var(--unit));
    --font-24-28: calc(24 * var(--unit));
    --font-24-32: calc(24 * var(--unit));
    --font-24-36: calc(24 * var(--unit));
    --font-28: calc(28 * var(--unit));
    --font-28-32-36: calc(28 * var(--unit));
    --font-32-36-48: calc(32 * var(--unit));
    --font-32-48-60: calc(32 * var(--unit));
    --font-48: calc(48 * var(--unit));

    --font-light: 300;
    --font-regular: 400;
    --font-medium: 500;
    --font-bold: 700;

    --line-110: 1.1;
    --line-120: 1.2;
    --line-130: 1.3;
    --line-140: 1.4;

    // FORMS
    --form-input-height: var(--spacing-48);
    --form-input-font-size: var(--font-16);
    --form-input-label-scale: 68.75;
    --form-input-label-height: var(--spacing-24);
    --form-input-label-padding: var(--spacing-12);

    // MISC
    --link-opacity-hover:               .65;
    --link-easing-hover:                ease-out;

    --transition-fastest:               .05s;
    --transition-faster:                .10s;
    --transition-fast:                  .15s;
    --transition-normal:                .20s;
    --transition-slow:                  .25s;
    --transition-slower:                .30s;
    --transition-slowest:               .50s;

    --button-active-scale:              97%;

    // COLOR CONSTANTS
    --color-charcoal:                   #121212;

    --color-neutral-100:                #f5f5f6;
    --color-neutral-200:                #ebebed;
    --color-neutral-300:                #e0e1e5;
    --color-neutral-400:                #c2c3ca;
    --color-neutral-500:                #707384;
    --color-neutral-600:                #5c5f72;
    --color-neutral-700:                #33374f;
    --color-neutral-800:                #1f233d;
    --color-neutral-900:                #141935;

    --color-midnight:                   #000523;
    --color-midnight-100:               rgba(0,5,35,.04);
    --color-midnight-200:               rgba(0,5,35,.08);
    --color-midnight-300:               rgba(0,5,35,.12);
    --color-midnight-400:               rgba(0,5,35,.16);
    --color-midnight-500:               rgba(0,5,35,.24);
    --color-midnight-600:               rgba(0,5,35,.32);
    --color-midnight-700:               rgba(0,5,35,.48);
    --color-midnight-800:               rgba(0,5,35,.64);
    --color-midnight-900:               rgba(0,5,35,.88);

    --color-white:                      #ffffff;
    --color-white-100:                  rgba(255,255,255,.12); // .04
    --color-white-200:                  rgba(255,255,255,.16); // .08
    --color-white-300:                  rgba(255,255,255,.24); // .12
    --color-white-400:                  rgba(255,255,255,.32); // .16
    --color-white-500:                  rgba(255,255,255,.40); // .24
    --color-white-600:                  rgba(255,255,255,.48); // .32
    --color-white-700:                  rgba(255,255,255,.56); // .48
    --color-white-800:                  rgba(255,255,255,.64);
    --color-white-900:                  rgba(255,255,255,.88);

    --color-primary-violet:             #734bff; // primary: echo
    --color-primary-purple:             #be32ff;
    --color-primary-pink:               #e128a0;
    --color-primary-tv2red:             #f0281e; // primary: forsiden, nyheder, livsstil, underholdning, tv, play
    --color-primary-orangered:          #ff5a00;
    --color-primary-orange:             #fa7806;
    --color-primary-yellow:             #ffd200;
    --color-primary-grassgreen:         #82cd19;
    --color-primary-green:              #19aa46; // primary: vejr
    --color-primary-blue:               #3264ff; // primary: sport
    --color-primary-skyblue:            #00aaff;
    --color-primary-turquoise:          #00d2d2;

    --color-light-violet:               #a596fa;
    --color-light-purple:               #d791ff;
    --color-light-pink:                 #e18cc3;
    --color-light-red:                  #eb8c82;
    --color-light-orangered:            #f4a480;
    --color-light-orange:               #f6be86;
    --color-light-yellow:               #f5dd89;
    --color-light-grassgreen:           #c3f087;
    --color-light-green:                #9bd7a0; // secondary: vejr
    --color-light-blue:                 #96afff;
    --color-light-skyblue:              #8cc8f5;
    --color-light-turquoise:            #9bebeb;

    --color-bright-lavendar:            #e6d7ff; // secondary: echo
    --color-bright-peach:               #ffe6d7; // secondary: nyheder
    --color-bright-sand:                #fff5be; // secondary: livsstil, underholdning, tv, play
    --color-bright-sky:                 #cdf0ff; // secondary: sport

    --color-dark-plum:                  #37005a; // echo
    --color-dark-bordeaux:              #640014; // nyheder
    --color-dark-forestgreen:           #143200; // vejr
    --color-dark-nightblue:             #1e1991; // sport

    --color-link-light:                 #0043ce;
    --color-link-light-visited:         #551a8b;
    --color-link-dark:                  #4d97ff;
    --color-link-dark-visited:          #ab49dc;

    --color-info:                       var(--color-link-light);
    --color-info-dark:                  var(--color-link-dark);
    --color-info-bg:                    #eef3ff;
    --color-success:                    #078a1d;
    --color-success-dark:               #4cbf69;
    --color-success-bg:                 #edf9ef;
    --color-warning:                    #a36b00;
    --color-warning-dark:               #f1c21b;
    --color-warning-bg:                 #fffae7;
    --color-error:                      #da1e28;
    --color-error-dark:                 #fa4d56;
    --color-error-bg:                   #fef1f1;

    --color-interactive-tv2red-dark:    #ca221f;
    --color-interactive-tv2red-light:   #f24a42;
    --color-interactive-blue-dark:      #2a55dc;
    --color-interactive-blue-light:     #537dff;
    --color-interactive-green-dark:     #159040;
    --color-interactive-green-light:    #3eb864;
    --color-interactive-violet-dark:    #6140dc;
    --color-interactive-violet-light:   #8968ff;

    --color-focus:                      var(--color-link-light);
    --color-live:                       var(--color-primary-tv2red);
    --color-breaking:                   var(--color-primary-yellow);
    --color-rm:                         #1b2a63; // royal blue
    --color-new-newsfeed:               #fff5f0; // new newsfeed color

    // election
    --color-election-unknown:           #d3d3d3;
    --color-election-a:                 #bd3246; // Socialdemokratiet
    --color-election-b:                 #723b96; // Radikale Venstre
    --color-election-c:                 #5e943e; // Konservative
    --color-election-d:                 #306a6c; // Nye Borgerlige
    --color-election-f:                 #e05eb5; // SF
    --color-election-i:                 #6bbccf; // Liberal Alliance
    --color-election-k:                 #fd9702; // Kristendemokraterne
    --color-election-m:                 #b48cd2; // Moderaterne
    --color-election-o:                 #f4c357; // Dansk Folkeparti
    --color-election-q:                 #66ab31; // Frie Grønne
    --color-election-v:                 #2960aa; // Venstre
    --color-election-æ:                 #3e597e; // Danmarksdemokraterne
    --color-election-ø:                 #ed5741; // Enhedslisten
    --color-election-å:                 #8bbe4c; // Alternativet

    --color-election-northatlantic:     var(--color-700);

    // THEME (light/dark)
    --color-body-bg:                    light-dark(var(--color-white), var(--color-midnight));
    --color-text:                       light-dark(var(--color-midnight), var(--color-neutral-100));
    --color-newsfeed:                   light-dark(var(--color-new-newsfeed), var(--color-neutral-900));
    --color-liveblog:                   light-dark(var(--color-neutral-200), var(--color-neutral-800));

    --color-100:                        light-dark(var(--color-neutral-100), var(--color-neutral-900));
    --color-200:                        light-dark(var(--color-neutral-200), var(--color-neutral-800));
    --color-300:                        light-dark(var(--color-neutral-300), var(--color-neutral-700));
    --color-400:                        light-dark(var(--color-neutral-400), var(--color-neutral-600));
    --color-500:                        light-dark(var(--color-neutral-500), var(--color-neutral-500));
    --color-600:                        light-dark(var(--color-neutral-600), var(--color-neutral-400));
    --color-700:                        light-dark(var(--color-neutral-700), var(--color-neutral-300));
    --color-800:                        light-dark(var(--color-neutral-800), var(--color-neutral-200));
    --color-900:                        light-dark(var(--color-neutral-900), var(--color-neutral-100));

    --color-light:                      light-dark(var(--color-white), var(--color-midnight));
    --color-light-100:                  light-dark(var(--color-white-100), var(--color-midnight-100));
    --color-light-200:                  light-dark(var(--color-white-200), var(--color-midnight-200));
    --color-light-300:                  light-dark(var(--color-white-300), var(--color-midnight-300));
    --color-light-400:                  light-dark(var(--color-white-400), var(--color-midnight-400));
    --color-light-500:                  light-dark(var(--color-white-500), var(--color-midnight-500));
    --color-light-600:                  light-dark(var(--color-white-600), var(--color-midnight-600));
    --color-light-700:                  light-dark(var(--color-white-700), var(--color-midnight-700));
    --color-light-800:                  light-dark(var(--color-white-800), var(--color-midnight-800));
    --color-light-900:                  light-dark(var(--color-white-900), var(--color-midnight-900));

    --color-dark:                       light-dark(var(--color-midnight), var(--color-white));
    --color-dark-special:               light-dark(var(--color-midnight), var(--color-neutral-900));
    --color-dark-100:                   light-dark(var(--color-midnight-100), var(--color-white-100));
    --color-dark-200:                   light-dark(var(--color-midnight-200), var(--color-white-200));
    --color-dark-300:                   light-dark(var(--color-midnight-300), var(--color-white-300));
    --color-dark-400:                   light-dark(var(--color-midnight-400), var(--color-white-400));
    --color-dark-500:                   light-dark(var(--color-midnight-500), var(--color-white-500));
    --color-dark-600:                   light-dark(var(--color-midnight-600), var(--color-white-600));
    --color-dark-700:                   light-dark(var(--color-midnight-700), var(--color-white-700));
    --color-dark-800:                   light-dark(var(--color-midnight-800), var(--color-white-800));
    --color-dark-900:                   light-dark(var(--color-midnight-900), var(--color-white-900));

    --color-link:                       light-dark(var(--color-link-light), var(--color-link-dark));
    --color-link-hover:                 light-dark(var(--color-link-dark), var(--color-link-light));
    --color-link-visited:               light-dark(var(--color-link-light-visited), var(--color-link-dark-visited));
    --color-link-visited-hover:         light-dark(var(--color-link-dark-visited), var(--color-link-light-visited));
    --color-interactive-tv2red:         light-dark(var(--color-interactive-tv2red-light), var(--color-interactive-tv2red-dark));
    --color-interactive-blue:           light-dark(var(--color-interactive-blue-light), var(--color-interactive-blue-dark));
    --color-interactive-green:          light-dark(var(--color-interactive-green-light), var(--color-interactive-green-dark));
    --color-interactive-violet:         light-dark(var(--color-interactive-violet-light), var(--color-interactive-violet-dark));

    // election
    [data-election-type="fv"]{
      --color-election-1:               #ffebd7; // main card background, sticky candidate header background, party guide background
      --color-election-3:               #cdc3b9; // secondary progress number
      --color-election-4:               #dbd4cd; // candidate answer textbox background
      --color-election-5:               #bcafa2; // candidate answer rating button background
      --color-election-6:               var(--color-white); // candidate answer rating tooltip color
      --color-election-8:               var(--color-election-1); // user rating button background
      --color-election-9:               var(--color-midnight); // user rating button selection and tooltip background
      --color-election-10:              var(--color-election-3); // user rating button border color
    }

    [data-election-type="ep"]{
      --color-election-1:               var(--color-link-light); // main card background, sticky candidate header background, party guide background
      --color-election-2:               var(--color-light); // sticky candidate header color
      --color-election-3:               var(--color-light-red); // secondary progress number
      --color-election-4:               var(--color-light-red); // candidate answer textbox background
      --color-election-5:               var(--color-light-red); // candidate answer rating button background
      --color-election-6:               var(--color-light); // candidate answer rating tooltip color
      --color-election-7:               light-dark(var(--color-midnight), var(--color-neutral-400)); // user rating background
      --color-election-8:               light-dark(var(--color-bright-peach), var(--color-neutral-800)); // user rating button background
      --color-election-9:               light-dark(var(--color-dark-bordeaux), var(--color-bright-peach)); // user rating button selection and tooltip background
      --color-election-10:              light-dark(var(--color-midnight), var(--color-neutral-400)); // user rating button border color
      --color-election-11:              light-dark(var(--color-neutral-500), var(--color-neutral-300)); // candidate test bottom navigation button color
      --color-election-12:              light-dark(var(--color-neutral-100), var(--color-white-400)); // candidate rating accordion
    }

    // Light theme fallback (iOS < 17.5)
    @supports not (color: light-dark(#fff, #000)) {
      --color-body-bg:                  var(--color-neutral-200);
      --color-text:                     var(--color-midnight);

      --color-100:                      var(--color-neutral-100);
      --color-200:                      var(--color-neutral-200);
      --color-300:                      var(--color-neutral-300);
      --color-400:                      var(--color-neutral-400);
      --color-500:                      var(--color-neutral-500);
      --color-600:                      var(--color-neutral-600);
      --color-700:                      var(--color-neutral-700);
      --color-800:                      var(--color-neutral-800);
      --color-900:                      var(--color-neutral-900);

      --color-light:                    var(--color-white);
      --color-light-100:                var(--color-white-100);
      --color-light-200:                var(--color-white-200);
      --color-light-300:                var(--color-white-300);
      --color-light-400:                var(--color-white-400);
      --color-light-500:                var(--color-white-500);
      --color-light-600:                var(--color-white-600);
      --color-light-700:                var(--color-white-700);
      --color-light-800:                var(--color-white-800);
      --color-light-900:                var(--color-white-900);

      --color-dark:                     var(--color-midnight);
      --color-dark-special:             var(--color-midnight);
      --color-dark-100:                 var(--color-midnight-100);
      --color-dark-200:                 var(--color-midnight-200);
      --color-dark-300:                 var(--color-midnight-300);
      --color-dark-400:                 var(--color-midnight-400);
      --color-dark-500:                 var(--color-midnight-500);
      --color-dark-600:                 var(--color-midnight-600);
      --color-dark-700:                 var(--color-midnight-700);
      --color-dark-800:                 var(--color-midnight-800);
      --color-dark-900:                 var(--color-midnight-900);

      --color-link:                     var(--color-link-light);
      --color-link-hover:               var(--color-link-dark);
      --color-link-visited:             var(--color-link-light-visited);
      --color-link-visited-hover:       var(--color-link-dark-visited);
      --color-interactive-tv2red:       var(--color-interactive-tv2red-light);

      // election
      [data-election-type="ep"]{
        --color-election-1:             var(--color-link-light); // main card background, sticky candidate header background, party guide background
        --color-election-2:             var(--color-white); // sticky candidate header color
        --color-election-3:             var(--color-light-red); // secondary progress number
        --color-election-4:             var(--color-light-red); // candidate answer textbox background
        --color-election-5:             var(--color-light-red); // candidate answer rating button background
        --color-election-6:             var(--color-light); // candidate answer rating tooltip color
        --color-election-8:             var(--color-bright-peach); // user rating button background
        --color-election-9:             var(--color-dark-bordeaux); // user rating button selection and tooltip background
        --color-election-11:            var(--color-500); // candidate test bottom navigation button color
        --color-election-12:            var(--color-100); // candidate rating accordion
      }
    }
  }
  ::backdrop{
    --color-backdrop:                   rgba(0,5,35,.64); // --color-midnight-800
  }
}

@include respond-to(small-inline) {
  :root{
    // UNIT
    --unit: var(--static-unit);

    // BREAKPOINTS
    --bp: var(--bp-small);

    // WIDTH OF <MAIN> IN BREAKPOINTS
    --main: var(--main-small);

    // GUTTER
    --gutter: calc(var(--spacing-48) / 2);

    // THEME
    --color-body-bg:                    var(--color-200);

  }
}

@include respond-to(medium-inline) {
  :root{
    // BREAKPOINTS
    --bp: var(--bp-medium);

    // WIDTH OF <MAIN> IN BREAKPOINTS
    --main: var(--main-medium);

    // SPACING
    --spacing-step: var(--spacing-base);
    --spacing-jump: 2;

    // GUTTER
    --gutter: calc(var(--spacing-56) / 2);

    // FONTS
    --font-10-12: calc(12 * var(--unit));
    --font-10-14: calc(14 * var(--unit));
    --font-12-14: calc(14 * var(--unit));
    --font-14-16: calc(16 * var(--unit));
    --font-16-18: calc(18 * var(--unit));
    --font-16-20: calc(20 * var(--unit));
    --font-18-20: calc(20 * var(--unit));
    --font-18-24: calc(24 * var(--unit));
    --font-20-24: calc(24 * var(--unit));
    --font-24-28: calc(28 * var(--unit));
    --font-24-32: calc(32 * var(--unit));
    --font-24-36: calc(36 * var(--unit));
    --font-28-32-36: calc(32 * var(--unit));
    --font-32-36-48: calc(36 * var(--unit));
    --font-32-48-60: calc(48 * var(--unit));
  }
}

@include respond-to(large-inline) {
  :root{
    // BREAKPOINTS
    --bp: var(--bp-large);

    // WIDTH OF <MAIN> IN BREAKPOINTS
    --main: var(--main-large);

    // FONTS
    --font-10-10-16: calc(16 * var(--unit));
    --font-28-32-36: calc(36 * var(--unit));
    --font-32-36-48: calc(48 * var(--unit));
    --font-32-48-60: max(3.2vw, calc(60 * var(--unit))); // scale up when font is more than 60rem and more than 3.2vw
  }
}

@include respond-to(print) {
  :root{
    color-scheme:light; // force 'light' color-scheme on print

    // UNIT
    --unit: (72 / 96) * 1pt; // takes 'px' and returns unit in 'pt'

    // WIDTH OF <MAIN> IN BREAKPOINTS
    --main-small: calc(432 * var(--unit));
    --main-medium: calc(624 * var(--unit));

    // SPACING
    --spacing-base: calc(8 * var(--unit));

    --spacing-1: calc(1 * var(--unit));
    --spacing-2: calc(2 * var(--unit));
    --spacing-3: calc(3 * var(--unit));
    --spacing-4: calc(var(--spacing-base) * .5);
    --spacing-8: var(--spacing-base);
    --spacing-12: calc(var(--spacing-base) * 1.5);
    --spacing-16: calc(var(--spacing-base) * 2);
    --spacing-20: calc(var(--spacing-base) * 2.5);
    --spacing-24: calc(var(--spacing-base) * 3);
    --spacing-32: calc(var(--spacing-base) * 4);
    --spacing-40: calc(var(--spacing-base) * 5);
    --spacing-48: calc(var(--spacing-base) * 6);
    --spacing-56: calc(var(--spacing-base) * 7);
    --spacing-64: calc(var(--spacing-base) * 8);
    --spacing-72: calc(var(--spacing-base) * 9);
    --spacing-80: calc(var(--spacing-base) * 10);
    --spacing-88: calc(var(--spacing-base) * 11);
    --spacing-96: calc(var(--spacing-base) * 12);
    --spacing-108: calc(var(--spacing-base) * 13);
    --spacing-112: calc(var(--spacing-base) * 14);
    --spacing-120: calc(var(--spacing-base) * 15);
    --spacing-128: calc(var(--spacing-base) * 16);

    // FONTS
    --font-family: 'TV2','Helvetica Neue','Segoe UI',sans-serif;

    --font-10: calc(10 * var(--unit));
    --font-12: calc(12 * var(--unit));
    --font-14: calc(14 * var(--unit));
    --font-16: calc(16 * var(--unit));
    --font-18: calc(18 * var(--unit));
    --font-20: calc(20 * var(--unit));
    --font-24: calc(24 * var(--unit));
    --font-28: calc(28 * var(--unit));
    --font-36: calc(36 * var(--unit));
    --font-48: calc(48 * var(--unit));
    --font-60: calc(60 * var(--unit));

    --font-light: 300;
    --font-regular: 400;
    --font-medium: 500;
    --font-bold: 700;

    --line-110: 1.1;
    --line-120: 1.2;
    --line-130: 1.3;
    --line-140: 1.4;

    // COLOR
    --color-black:  #000;
    --color-400:    #c2c3ca;
  }
}
