// CAROUSEL
@import "core";

@include respond-to(base-inline) {
  .tc_videocarousel{display:flex;flex-direction:column;gap:var(--spacing-4-step);}
  .tc_videocarousel__item{position:relative;cursor:pointer;overflow:hidden;
    &[aria-current="true"]{background-color:var(--color-100);
      .tc_videocarousel__item__button{cursor:default;}
    }
    .tc_teaser__link{gap:var(--spacing-8);}
    .tc_teaser__header{padding:0 var(--spacing-4) var(--spacing-4);}
    .tc_teaser__tagline{font-size:var(--font-10);
      &::before,&::after{display:none;}
    }
    .tc_heading{font-size:var(--font-12);font-weight:var(--font-regular);overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:3;line-clamp:3;-webkit-box-orient:vertical;}
  }
  .tc_videocarousel__item__button{position:absolute;top:0;bottom:0;left:0;right:0;z-index:3;width:100%;margin:0;padding:0;background:transparent;border:0;color:transparent;cursor:pointer;}
}

@include respond-to(print) {
  .tc_videocarousel{display:none;}
}
