// RECIPE INFO
@import "core";

@include respond-to(base-inline) {
  .tc_recipe-info{columns:2;background-color:var(--color-100);padding:var(--spacing-8-step);font-size:var(--font-12-14);margin:0;
    div{display:flex;justify-content:center;gap:var(--spacing-4);white-space:nowrap;}
    dt{font-weight:var(--font-medium);}
    dd{margin:0;}
  }
}

@include respond-to(large-inline) {
  .tc_recipe-info{columns:4;}
}

@include respond-to(print) {
  .tc_recipe-info{columns:2;border:var(--spacing-1) solid var(--color-black);padding:var(--spacing-8);font-size:var(--font-14);margin:0 0 var(--spacing-32);
    div{display:flex;justify-content:center;gap:var(--spacing-4);white-space:nowrap;}
    dt{font-weight:var(--font-medium);}
    dd{margin:0;}
  }
  .tc_recipe__body__ingredients{
    dd{margin:0;
      ul{padding:0;}
    }
  }
}
