// CANDIDATETEST RESULTS
@import "core";

@include respond-to(base-inline) {
  /* candidate result */
  .tc_candidatetest__main--results{
    .tc_candidatetest__card__header__question{
      p{font-size:var(--font-18);line-height:var(--line-140);}
    }
  }
  .tc_candidatetest__result{display:flex;flex-direction:column;position:relative;padding-top:calc(var(--spacing-32) + var(--spacing-32-jump));
    .tc_survey{padding:0 0 var(--spacing-32);line-height:var(--line-140);
      .tc_survey__text{font-size:var(--font-18);}
      .tc_survey__link{font-size:var(--font-18);}
    }
  }
  .tc_candidatetest__result__notification{margin-top:calc(var(--spacing-24) * -1);
    .tc_button__group{padding-top:var(--spacing-24-step);}
  }
  .tc_candidatetest__result__heading{font-size:var(--font-20-24);}
  .tc_candidatetest__result__candidates{padding:var(--spacing-0-step) 0 var(--spacing-24-step);
    &:last-child{padding-bottom:var(--spacing-88-step);}
  }
  .tc_candidatetest__result__candidates__list{list-style:none;}
  .tc_candidatetest__result__buttons{padding-top:calc(var(--spacing-32) + var(--spacing-32-jump));border-top:var(--spacing-1) solid var(--color-300);}

  /* candidate */
  .tc_candidatetest__candidate{position:relative;overflow-anchor:none;
    &:focus-within{z-index:1;}
    &[data-open]{border-bottom:var(--spacing-1) solid var(--color-300);
      .tc_candidatetest__candidate__summary{position:sticky;top:calc(var(--spacing-1) * -1);left:0;right:0;z-index:2;background-color:var(--color-light);box-shadow:0 var(--spacing-8) var(--spacing-8) calc(var(--spacing-8) * -1) var(--color-500);
        &::after{transform:rotate(180deg);}
      }
      &.tc_candidatetest__candidate__small-sticky{
        &::before{content:"";display:block;position:sticky;z-index:1;top:0;left:0;right:0;height:var(--spacing-48-step);margin-bottom:calc((var(--spacing-48-step) + var(--spacing-1)) * -1);}
        .tc_candidatetest__candidate__summary{background-color:var(--color-election-1, var(--color-100));margin:0 calc(var(--spacing-8-step) * -1);padding:0;border:0;pointer-events:none;
          &::after{background-image:none;}
          .tc_candidate-summary{
            .tc_image{width:var(--spacing-32-step);}
          }
          .tc_candidate-summary__info{padding:var(--spacing-8) var(--spacing-8-step);column-gap:var(--spacing-8);color:var(--color-election-2, var(--color-text));}
          .tc_candidate-summary__info__basic__name .tc_heading{font-size:var(--font-14-16);padding-bottom:0;}
          [data-candidate-party],.tc_candidate-summary__info__basic__match{display:none;}
        }
      }
      .tc_candidate-summary__info{transition:padding,column-gap var(--transition-faster);}
    }
    .tc_candidate-summary .tc_image{transition:width var(--transition-faster);}
    .tc_candidate-summary__info{border-bottom:0;}
    .tc_candidate-summary__info__basic__name .tc_heading{transition:font-size,padding var(--transition-faster);}
    .tc_candidate-details{padding-top:var(--spacing-16-step);}
  }
  .tc_candidatetest__candidate__summary{position:relative;padding-right:var(--spacing-40);border-bottom:var(--spacing-1) solid var(--color-300);background-color:var(--color-light);-webkit-user-select:none;user-select:none;cursor:pointer;transition:top var(--transition-slower);
    &::after{content:"";position:absolute;top:0;bottom:0;right:var(--spacing-8);width:var(--spacing-32);height:var(--spacing-20);margin:auto;mask:url("inline!../../../../../../icons/chevron-down.svg") no-repeat 50% 50%;mask-size:var(--spacing-20) auto;background-color:var(--color-dark);}
    .tc_candidate-summary{background-color:transparent;}
  }
  .tc_candidatetest__candidate__neutral-answers{margin-top:calc(var(--spacing-16-jump) * -1);padding-bottom:calc(var(--spacing-64) + var(--spacing-32-jump) - var(--spacing-20));
    p{font-size:var(--font-16);}
  }
  .tc_header--sticky-show ~ #main .tc_candidatetest__candidate[data-open="true"] .tc_candidatetest__candidate__summary{top:calc(104 * var(--unit) + (var(--spacing-0-step) * 2));} // compensate for sticky tv2 header

  /* candidate search */
  .tc_candidatetest__search{padding-top:calc(var(--spacing-32) + var(--spacing-32-jump));
    .tc_form__group{max-width:calc(330 * var(--unit));margin:0 auto;margin-bottom:calc(var(--spacing-32) + var(--spacing-32-jump));}
  }
  .tc_candidatetest__search__results{padding:0 0 var(--spacing-24-step);}

  .tc_candidatetest__partyguide{padding:var(--spacing-32) 0;}
}

@include respond-to(print) {
  .tc_candidatetest__search{display:none;}
  .tc_candidatetest__result__candidates__list{list-style:none;margin:0 0 var(--spacing-20);padding:0;}
}
