// COMPARE STATS
@import "core";

@include respond-to(base-inline) {
  .tc_comparestats{background:var(--color-100);padding:var(--spacing-8-step);
    table,tbody{display:block;}
    th{position:relative;flex:1;}
    td{align-self:flex-end;order:3;min-width:var(--spacing-32-step);font-weight:var(--font-medium);font-size:var(--font-14);text-align:right;
      &:first-of-type{order:1;text-align:left;}
    }
  }
  .tc_comparestats__item{position:relative;display:flex;align-items:stretch;height:var(--spacing-40);margin-bottom:var(--spacing-24);}
  .tc_comparestats__item__title{order:2;padding:0;font-size:var(--font-14);font-weight:var(--font-regular);}
  .tc_comparestats__item__graph{position:absolute;bottom:.8ex;left:0;width:100%;height:var(--spacing-8);overflow:hidden;background-color:var(--color-400);}
  .tc_comparestats__item__graph__bar{position:relative;height:var(--spacing-8);background-color:var(--color-600);
    &::after{content:"";position:absolute;width:var(--spacing-2);height:var(--spacing-8);right:calc(var(--spacing-1));background:var(--color-light);}
  }
}

@include respond-to(print) {
  .tc_comparestats{padding:var(--spacing-8);border:var(--spacing-2) solid var(--color-black);
    table{width:100%;}
    th{text-align:left;width:30%;}
    td{text-align:center;width:35%;}
  }
}
