// EMBED
@import "core";

@include respond-to(base) {
  [data-embed-vendor="tv2"]{
    &[data-embed-name="matchstandings"],&[data-embed-name="formulaonestandings"],&[data-embed-name="classificationsstandings"],&[data-embed-name="stageresults"],&[data-embed-name="jerseystandings"],&[data-embed-name="matchstatistic"]{
      .tc_datatable{background-color:var(--color-100);padding:var(--spacing-24-step) var(--spacing-16-jump) var(--spacing-16-step);}
    }
  }
  [data-embed-vendor="facebook"]{
    .fb_iframe_widget_fluid{
      span{width:calc(100vw - var(--spacing-20))!important; // override inline styles
        iframe{width:calc(100vw - var(--spacing-20))!important;} // override inline styles
      }
    }
  }
  [data-embed-vendor="instagram"]{
    iframe{min-width:var(--spacing-1)!important;} // override inline styles
  }
  [data-embed-vendor="twitter"]{overflow:hidden;}
  [data-embed-vendor="acast"] > div{max-width:auto!important;} // override inline styles

  .tc_embed--shielded{display:none;}
}

@include respond-to(small) {
  [data-embed-vendor="facebook"]{
    .fb_iframe_widget_fluid{
      span{width:100%!important; // override inline styles
        iframe{width:100%!important;} // override inline styles
      }
    }
  }
}

@include respond-to(print) {
  .tc_embed{page-break-inside:avoid;break-inside:avoid;}
}
