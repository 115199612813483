// GRID
@import "core";

@include respond-to(base-inline) {
  :where(.tc_grid){display:grid;row-gap:var(--spacing-24-step);column-gap:var(--spacing-8-step);grid-auto-rows:min-content;grid-auto-columns:minmax(0,100%);list-style:none;
    .tc_grid{row-gap:var(--spacing-16);}
    &.tc_grid--gap-small{row-gap:var(--spacing-16);}
    &.tc_grid--gap-medium{row-gap:var(--spacing-16-jump);}
    &.tc_grid--gap-large{row-gap:var(--spacing-24-step);}
    &.tc_grid--gap-xlarge{row-gap:var(--spacing-32-jump);}
  }
  :where(.tc_grid--gap-none){row-gap:0;}
  :where(.tc_grid--gutter){padding-left:var(--gutter);padding-right:var(--gutter);}
  :where(.tc_grid--align-left){justify-items:start;}
  :where(.tc_grid--align-center){justify-items:center;}
  :where(.tc_grid--align-right){justify-items:end;}
  :where([data-grid-bs="col"]){grid-template-columns:auto;}
  :where([data-grid-bs="row"]){grid-template-columns:repeat(auto-fit, minmax(0, 1fr));}
}

@include respond-to(medium-inline) {
  :where([data-grid-md="col"]){grid-template-columns:auto;}
  :where([data-grid-md="row"]){grid-template-columns:repeat(auto-fit, minmax(0, 1fr));}
  :where([data-grid-md="row_1-2"]){grid-template-columns:repeat(3, 1fr);
    & > :first-child{grid-column:1 / 2;}
    & > :nth-child(2){grid-column:2 / 4;}
  }
  :where([data-grid-md="row_2-1"]){grid-template-columns:repeat(3, 1fr);
    & > :first-child{grid-column:1 / 3;}
    & > :nth-child(2){grid-column:3 / 4;}
  }
  :where([data-grid-md="row_2"]){grid-template-columns:repeat(2, 1fr);}
}

@include respond-to(large-inline) {
  :where([data-grid-lg="col"]){grid-template-columns:auto;}
  :where([data-grid-lg="row"]){grid-template-columns:repeat(auto-fit, minmax(0, 1fr));}
  :where([data-grid-lg="row_1-2"]){grid-template-columns:repeat(3, 1fr);
    & > :first-child{grid-column:1 / 2;}
    & > :nth-child(2){grid-column:2 / 4;}
  }
  :where([data-grid-lg="row_2-1"]){grid-template-columns:repeat(3, 1fr);
    & > :first-child{grid-column:1 / 3;}
    & > :nth-child(2){grid-column:3 / 4;}
  }
  :where([data-grid-lg="row_3"]){grid-template-columns:repeat(3, 1fr);}
}

@include respond-to(print) {
  :where(.tc_grid){list-style:none;margin:0;padding:0;}
  :where([data-grid-bs="col"]){display:block;}
  :where([data-grid-bs*="row"],[data-grid-md*="row"]){display:grid;gap:var(--spacing-16);grid-auto-rows:min-content;grid-auto-columns:minmax(0,100%);grid-template-columns:repeat(2, 1fr);}
}
