// GALLERY BODY
@import "core";

@include respond-to(base-inline) {
  .tc_gallery-body__list{display:flex;flex-direction:column;gap:var(--spacing-32-jump);list-style:none;counter-reset:image;
    &[data-gallery-layout] .tc_gallery-body__list__item::before{color:light-dark(var(--color-dark-bordeaux), var(--color-bright-peach));}
    &[data-gallery-layout="sport"] .tc_gallery-body__list__item::before{color:light-dark(var(--color-dark-nightblue), var(--color-bright-sky));}
    &[data-gallery-layout="vejr"] .tc_gallery-body__list__item::before{color:light-dark(var(--color-dark-forestgreen), var(--color-light-green));}
    &[data-gallery-layout="echo"] .tc_gallery-body__list__item::before{color:light-dark(var(--color-dark-plum), var(--color-bright-lavendar));}
  }
  // Light theme fallback (iOS < 17.5)
  @supports not (color: light-dark(#fff, #000)) {
    .tc_gallery-body__list{
      &[data-gallery-layout] .tc_gallery-body__list__item::before{color:var(--color-dark-bordeaux);}
      &[data-gallery-layout="sport"] .tc_gallery-body__list__item::before{color:var(--color-dark-nightblue);}
      &[data-gallery-layout="vejr"] .tc_gallery-body__list__item::before{color:var(--color-dark-forestgreen);}
      &[data-gallery-layout="echo"] .tc_gallery-body__list__item::before{color:var(--color-dark-plum);}
    }
  }
  .tc_gallery-body__list__item{width:100%;margin:0 auto;
    &[data-images]{counter-increment:image;
      &::before{content:counter(image)' af 'attr(data-images);display:block;font-size:var(--font-24-28);font-weight:var(--font-light);padding-bottom:var(--spacing-4);}
    }
    &[data-ratio="landscape"]{max-width:145vh;}
    &[data-ratio="square"]{max-width:85vh;}
    &[data-ratio="portrait"]{max-width:65vh;}
  }

  .tc_textbox__header__label{font-size:var(--font-12);padding-bottom:var(--spacing-4);color:light-dark(var(--color-dark-bordeaux), var(--color-bright-peach));}
  .tc_textbox__header__label--color-sport{color:light-dark(var(--color-dark-nightblue), var(--color-bright-sky));}
  .tc_textbox__header__label--color-vejr{color:light-dark(var(--color-dark-forestgreen), var(--color-light-green));}
  .tc_textbox__header__label--color-echo{color:light-dark(var(--color-dark-plum), var(--color-bright-lavendar));}
}

@include respond-to(large-inline) {
  .tc_deck--col2center .tc_gallery-body__list{margin-left:calc((var(--bp) - var(--bp-medium)) / 2 * -1);margin-right:calc((var(--bp) - var(--bp-medium)) / 2 * -1);
    .tc_ad{margin-left:calc(var(--gutter) * -1);margin-right:calc(var(--gutter) * -1);}
  }

}

@include respond-to(print) {
  .tc_gallery-body__list{list-style:none;margin:var(--spacing-16) 0 0;padding:0;}
}
