// EMBED TOOLS TIMELINE
@import "core";

@include respond-to(base) {
  .tc_tools__timeline{position:relative;padding-top:var(--spacing-8);list-style:none;background-color:var(--color-light);
    &::before{content:"";position:absolute;left:0;right:0;bottom:0;z-index:1;height:calc(var(--spacing-16-jump) + var(--spacing-8));background-color:var(--color-light);}
    &::after{content:"";position:absolute;left:calc(18 * var(--unit));right:0;bottom:calc(var(--spacing-16-jump) + var(--spacing-8));z-index:1;width:calc(5 * var(--unit));height:calc(5 * var(--unit));background-color:var(--color-300);border-radius:50%;}
    .tc_richcontent{padding-bottom:var(--spacing-20);
      p:last-child{padding-bottom:0;}
    }
  }
  .tc_tools__timeline__year{position:relative;padding:var(--spacing-8) var(--spacing-8-step) 0 var(--spacing-20);margin-left:var(--spacing-20);border-left:var(--spacing-1) dashed var(--color-300);
    & > .tc_heading{padding:var(--spacing-20) 0;}
    &:first-child{
      & > .tc_heading{padding-top:var(--spacing-32);padding-left:var(--spacing-40);margin:calc(calc(var(--spacing-8) * -1)) 0 0 calc(var(--spacing-40) * -1);background-color:var(--color-light);}
    }
  }
  .tc_tools__timeline__year__list{list-style:none;}
  .tc_tools__timeline__year__item{position:relative;padding-bottom:var(--spacing-20);
    &::before{content:"";position:absolute;z-index:1;top:0;left:calc(-28 * var(--unit));width:calc(15 * var(--unit));height:calc(15 * var(--unit));background:var(--color-light);}
    &::after{content:"";position:absolute;z-index:2;top:0;left:calc(-28 * var(--unit));width:calc(15 * var(--unit));height:calc(15 * var(--unit));mask:url("../../../../../../icons/clock.svg") no-repeat 50% 100%;mask-size:contain;background-color:var(--color-dark-500);}
    & > .tc_timestamp{padding:0 0 var(--spacing-12);position:relative;top:calc(var(--spacing-1) * -1);font-size:var(--font-12);font-weight:var(--font-medium);}
    & > .tc_heading{padding-bottom:var(--spacing-4);position:relative;top:calc(var(--spacing-4) * -1);margin-bottom:calc(var(--spacing-4) * -1);}
    & > .tc_image,& > .tc_video{padding-bottom:var(--spacing-20);}
  }
}

@include respond-to(print) {
  .tc_tools__timeline{list-style:none;margin:0;padding:0;}
  .tc_tools__timeline__year__list{list-style:none;margin:0;padding:0;
    .tc_timestamp{font-weight:var(--font-bold);}
  }
}
