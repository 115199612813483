// EMBED TOOLS LIST
@import "core";

@include respond-to(base) {
  .tc_tools__list{list-style:none;}
  .tc_tools__list__item{position:relative;background-color:var(--color-light);padding:var(--spacing-24) var(--spacing-8-step) var(--spacing-8);margin-bottom:var(--spacing-4);
    & > .tc_heading{padding-bottom:var(--spacing-16);}
    & > .tc_image,& > .tc_video{padding-bottom:var(--spacing-20);}
  }

  ol.tc_tools__list{counter-reset:item;
    .tc_tools__list__item{padding-top:var(--spacing-64);
      &::before{content:counter(item);counter-increment:item;color:var(--color-800);font-size:var(--font-32-36-48);font-weight:var(--font-bold);position:absolute;top:var(--spacing-8);left:0;right:0;text-align:center;}
      &:nth-child(-n+9)::before{content:'0'counter(item);}
    }
    &[reversed]{
      .tc_tools__list__item{
        &::before{counter-increment:item -1;}
        &:nth-child(-n+9)::before{content:counter(item);}
        &:nth-last-child(-n+9)::before{content:'0'counter(item);}
      }
    }
  }

  [data-object-layout] .tc_tools__list__item::before{color:var(--color-primary-tv2red);}
  [data-object-layout="sport"] .tc_tools__list__item::before{color:var(--color-primary-blue);}
  [data-object-layout="vejr"] .tc_tools__list__item::before{color:var(--color-primary-green);}
  [data-object-layout="echo"] .tc_tools__list__item::before{color:var(--color-primary-violet);}
}

@include respond-to(small) {
  ol.tc_tools__list{
    .tc_tools__list__item{padding-top:var(--spacing-96);
      &::before{top:0;}
    }
  }
}

@include respond-to(print) {
  ul.tc_tools__list{list-style:none;margin:0;padding:0;}
}
