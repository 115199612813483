// EMBED TOOLS VERSUS
@import "core";

@include respond-to(base) {
  .tc_tools__versus{background-color:var(--color-light);max-width:var(--main-medium);margin:0 auto;padding:0 0 var(--spacing-8);}
  .tc_tools__versus__header{display:flex;padding:var(--spacing-16-jump) var(--spacing-8) var(--spacing-8-step);
    .tc_image{flex:1 1 auto;margin:0 auto;max-width:calc(var(--spacing-32-jump) + var(--spacing-48) + var(--spacing-0-step));}
    .tc_caption{border:0;text-align:center;line-height:var(--line-120);margin:0 calc(var(--spacing-20) * -1);}
  }
  .tc_tools__versus__header__vs{position:absolute;top:var(--spacing-64-jump);left:0;right:0;text-align:center;font-size:var(--font-28-32-36);font-weight:var(--font-bold);}
  .tc_tools__versus__list{list-style:none;
    &[data-versus-type="text"]{
      .tc_tools__versus__item__body{padding-top:var(--spacing-8);color:var(--color-white);}
      .tc_tools__versus__item__body__1{background-color:var(--color-800);margin-right:var(--spacing-1);}
      .tc_tools__versus__item__body__2{background-color:var(--color-600);margin-left:var(--spacing-1);}
      .tc_tools__versus__item__body__1,.tc_tools__versus__item__body__2{padding:var(--spacing-8-step);}
    }
    &[data-versus-type="numbers"]{
      .tc_tools__versus__item__body{padding-bottom:var(--spacing-16-jump);}
      .tc_tools__versus__item__body__1 .tc_tools__versus__item__body__graph{left:0;z-index:1;background-color:var(--color-800);}
      .tc_tools__versus__item__body__2{text-align:right;
        .tc_tools__versus__item__body__graph{right:0;background-color:var(--color-600);}
      }
      .tc_tools__versus__item__body__1,.tc_tools__versus__item__body__2{padding:0 var(--spacing-4) var(--spacing-4);}
    }
    &[data-versus-type="choice"]{
      .tc_tools__versus__item__body{padding-top:var(--spacing-8);}
      .tc_tools__versus__item__body__1 .tc_tools__versus__item__body__button{background-color:var(--color-800);}
      .tc_tools__versus__item__body__2 .tc_tools__versus__item__body__button{background-color:var(--color-600);}
    }
  }
  .tc_tools__versus__item{padding:var(--spacing-8) var(--spacing-8) var(--spacing-16);}
  .tc_tools__versus__item__body{position:relative;display:flex;font-size:var(--font-14);line-height:var(--line-120);}
  .tc_tools__versus__item__body__1,.tc_tools__versus__item__body__2{box-sizing:border-box;width:50%;overflow-wrap:break-word;}
  .tc_tools__versus__item__body__graph{position:absolute;bottom:0;width:100%;height:var(--spacing-16-jump);}
  .tc_tools__versus__item__body__button{width:100%;border:0;height:var(--spacing-40);font-size:var(--font-16);font-weight:var(--font-medium);color:var(--color-light);cursor:pointer;}

  /* colors */
  [data-object-layout]{
    .tc_tools__versus__header__vs{color:var(--color-primary-tv2red);}
    .tc_tools__versus__list{
      &[data-versus-type="text"]{
        .tc_tools__versus__item__body__1{background-color:var(--color-primary-tv2red);}
        .tc_tools__versus__item__body__2{background-color:var(--color-interactive-tv2red-dark);}
      }
      &[data-versus-type="numbers"]{
        .tc_tools__versus__item__body__1 .tc_tools__versus__item__body__graph{background-color:var(--color-primary-tv2red);}
        .tc_tools__versus__item__body__2 .tc_tools__versus__item__body__graph{background-color:var(--color-interactive-tv2red-dark);}
      }
      &[data-versus-type="choice"]{
        .tc_tools__versus__item__body__1 .tc_tools__versus__item__body__button{background-color:var(--color-primary-tv2red);}
        .tc_tools__versus__item__body__2 .tc_tools__versus__item__body__button{background-color:var(--color-interactive-tv2red-dark);}
      }
    }
  }
  [data-object-layout="sport"]{
    .tc_tools__versus__header__vs{color:var(--color-primary-blue);}
    .tc_tools__versus__list{
      &[data-versus-type="text"]{
        .tc_tools__versus__item__body__1{background-color:var(--color-primary-blue);}
        .tc_tools__versus__item__body__2{background-color:var(--color-interactive-blue-dark);}
      }
      &[data-versus-type="numbers"]{
        .tc_tools__versus__item__body__1 .tc_tools__versus__item__body__graph{background-color:var(--color-primary-blue);}
        .tc_tools__versus__item__body__2 .tc_tools__versus__item__body__graph{background-color:var(--color-interactive-blue-dark);}
      }
      &[data-versus-type="choice"]{
        .tc_tools__versus__item__body__1 .tc_tools__versus__item__body__button{background-color:var(--color-primary-blue);}
        .tc_tools__versus__item__body__2 .tc_tools__versus__item__body__button{background-color:var(--color-interactive-blue-dark);}
      }
    }
  }
  [data-object-layout="vejr"]{
    .tc_tools__versus__header__vs{color:var(--color-primary-green);}
    .tc_tools__versus__list{
      &[data-versus-type="text"]{
        .tc_tools__versus__item__body__1{background-color:var(--color-primary-green);}
        .tc_tools__versus__item__body__2{background-color:var(--color-interactive-green-dark);}
      }
      &[data-versus-type="numbers"]{
        .tc_tools__versus__item__body__1 .tc_tools__versus__item__body__graph{background-color:var(--color-primary-green);}
        .tc_tools__versus__item__body__2 .tc_tools__versus__item__body__graph{background-color:var(--color-interactive-green-dark);}
      }
      &[data-versus-type="choice"]{
        .tc_tools__versus__item__body__1 .tc_tools__versus__item__body__button{background-color:var(--color-primary-green);}
        .tc_tools__versus__item__body__2 .tc_tools__versus__item__body__button{background-color:var(--color-interactive-green-dark);}
      }
    }
  }
  [data-object-layout="echo"]{
    .tc_tools__versus__header__vs{color:var(--color-primary-green);}
    .tc_tools__versus__list{
      &[data-versus-type="text"]{
        .tc_tools__versus__item__body__1{background-color:var(--color-primary-violet);}
        .tc_tools__versus__item__body__2{background-color:var(--color-interactive-violet-dark);}
      }
      &[data-versus-type="numbers"]{
        .tc_tools__versus__item__body__1 .tc_tools__versus__item__body__graph{background-color:var(--color-primary-violet);}
        .tc_tools__versus__item__body__2 .tc_tools__versus__item__body__graph{background-color:var(--color-interactive-violet-dark);}
      }
      &[data-versus-type="choice"]{
        .tc_tools__versus__item__body__1 .tc_tools__versus__item__body__button{background-color:var(--color-primary-violet);}
        .tc_tools__versus__item__body__2 .tc_tools__versus__item__body__button{background-color:var(--color-interactive-violet-dark);}
      }
    }
  }
}

@include respond-to(medium) {
  .tc_tools__versus__header{
    .tc_image{max-width:calc(var(--spacing-80) * 2);}
  }
}

@include respond-to(print) {
  .tc_tools__versus__header{display:flex;justify-content:space-between;gap:var(--spacing-32);margin-bottom:var(--spacing-32);}
  .tc_tools__versus__list{list-style:none;margin:0;padding:var(--spacing-8);border:var(--spacing-2) solid var(--color-black);margin-bottom:var(--spacing-8);}
  .tc_tools__versus__item__header{text-align:center;}
  .tc_tools__versus__item__body{display:flex;justify-content:space-between;gap:var(--spacing-32);
    [class^="tc_tools__versus__item__body"]{width:50%;}
  }
}
