// CANDIDATE ANSWER
@import "core";

@include respond-to(base-inline) {
  /* candidate rating */
  .tc_candidatetest__candidate__rating{padding-bottom:calc(var(--spacing-32) + var(--spacing-32-jump));
    .tc_heading + &{padding-top:var(--spacing-16-step);}
    & > .tc_heading{position:relative;margin-bottom:var(--spacing-16-step);padding-left:var(--spacing-24);
      &::before{content:"";position:absolute;left:0;top:0;bottom:0;margin:auto 0;width:var(--spacing-16);height:var(--spacing-16);border-radius:50%;}
    }
    &[data-rating-color="green"] > .tc_heading::before{background-color:var(--color-success);}
    &[data-rating-color="yellow"] > .tc_heading::before{background-color:var(--color-warning-dark);}
    &[data-rating-color="red"] > .tc_heading::before{background-color:var(--color-error);}
  }
  .tc_candidatetest__candidate__rating__item{margin-bottom:var(--spacing-8-step);border:var(--spacing-1) solid var(--color-300);background-color:var(--color-election-12, inherit);
    &[open] .tc_candidatetest__candidate__rating__item__summary::after{transform:rotate(180deg);}
    &:last-child{margin-bottom:0;}
  }
  .tc_candidatetest__candidate__rating__item__summary{position:relative;font-size:var(--font-16);font-weight:var(--font-medium);padding:var(--spacing-16) var(--spacing-40) var(--spacing-16) var(--spacing-8-step);-webkit-user-select:none;user-select:none;cursor:pointer;
    &::after{content:"";position:absolute;top:0;bottom:0;right:var(--spacing-8);width:var(--spacing-32);height:var(--spacing-24);margin:auto;mask:url("inline!../../../../../../icons/chevron-down.svg") no-repeat 50% 50%;mask-size:var(--spacing-20) auto;background-color:var(--color-dark);}
  }
  .tc_candidatetest__candidate__rating__item__body{padding:var(--spacing-32-step) var(--spacing-8-step);border-bottom:var(--spacing-1) solid var(--color-300);
    &:last-child{border-bottom:0;}
    p{font-size:var(--font-14-16);}
    .tc_candidatetest__rating{margin-top:calc(var(--spacing-64-step) + var(--spacing-0-step));}
  }

  /* FV DESIGN */
  [data-election-type="ep"]{
    .tc_candidatetest__candidate__rating__item{border:0;}
  }
}

@include respond-to(print) {
  .tc_candidatetest__candidate__rating{margin-bottom:var(--spacing-20);}
  .tc_candidatetest__candidate__rating__item{display:list-item;list-style:disc;font-weight:var(--font-medium);margin-left:var(--spacing-20);}
  .tc_candidatetest__candidate__rating__item__body{border:var(--spacing-1) solid var(--color-black);padding:var(--spacing-8);margin-bottom:var(--spacing-16);font-size:var(--font-16);
    .tc_form-fieldset{border:0;padding:0;margin:0;}
  }
}
