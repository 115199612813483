// DECK
@import "core";

@include respond-to(base-inline) {
  // Deck
  :where(.tc_deck){width:var(--bp);position:relative;background-color:var(--color-light);margin:0 auto;padding-top:var(--spacing-24-step);padding-bottom:var(--spacing-24-step);
    .tc_deck:not([class*="tc_deck--bg"]){background-color:transparent;}
  }
  :where(.tc_deck--gap-small){padding-top:var(--spacing-8-jump);padding-bottom:var(--spacing-8-jump);}
  :where(.tc_deck--gap-medium){padding-top:var(--spacing-16);padding-bottom:var(--spacing-16);}
  :where(.tc_deck--gap-large){padding-top:var(--spacing-32-jump);padding-bottom:var(--spacing-32-jump);}
  :where(.tc_deck--gap-none,.tc_deck--gapless,.tc_deck:empty){padding-top:0;padding-bottom:0;}
  :where(.tc_deck--full,.tc_deck--fullcol2center,.tc_deck--fullcol3center){width:auto;margin:0;}

  // handle full width remotes
  main:has([class*="tc_deck--full"]) .tc_deck:not([class*="tc_deck--full"]){width:auto;margin:0;}

  // Deck header
  :where(.tc_deck__header){display:flex;flex-direction:column;column-gap:var(--spacing-12);row-gap:var(--spacing-16);margin:0 var(--gutter) var(--spacing-24-step);line-height:var(--line-120);
    .tc_grid--gutter &{margin-left:0;margin-right:0;margin-bottom:0;}
    & > :last-child:not(:only-child){
      &:empty{display:none;}
    }
    [data-grid-lg="row_2-1"] .tc_grid:not(:first-child) &{padding-top:var(--spacing-16);} // hack to set extra padding on top of deck header
    & > .tc_richcontent{font-size:var(--font-16);line-height:var(--line-140);color:var(--color-700);padding-top:var(--spacing-8-step);
      .tc_deck--bg-900 &{color:var(--color-400);}
      p:last-child{padding-bottom:0;}
    }
  }

  // Deck colors
  :where(.tc_deck--bg-transparent){background-color:transparent;}

  // Deck colors (themed)
  :where(.tc_deck--bg-100){background-color:var(--color-100);}

  // Deck colors (not themed: light decks - dark text (default))
  :where(.tc_deck--bg-peach){background-color:var(--color-bright-peach);color:var(--color-midnight);}
  :where(.tc_deck--bg-sky){background-color:var(--color-bright-sky);color:var(--color-midnight);}
  :where(.tc_deck--bg-lightgreen){background-color:var(--color-light-green);color:var(--color-midnight);}
  :where(.tc_deck--bg-sand){background-color:var(--color-bright-sand);color:var(--color-midnight);}
  :where(.tc_deck--bg-lavendar){background-color:var(--color-bright-lavendar);color:var(--color-midnight);}
  :where(.tc_deck--bg-breaking){background-color:var(--color-breaking);color:var(--color-midnight);}
  :where(.tc_deck[style]){color:var(--color-midnight);color-scheme:light;}

  // Deck colors (not themed: dark decks - light text)
  :where(.tc_deck--bg-900){background-color:var(--color-neutral-900);color:var(--color-white);}
  :where(.tc_deck--bg-midnight){background-color:var(--color-midnight);color:var(--color-white);}
  :where(.tc_deck--bg-tv2red){background-color:var(--color-primary-tv2red);color:var(--color-white);}
  :where(.tc_deck--bg-blue){background-color:var(--color-primary-blue);color:var(--color-white);}
  :where(.tc_deck--bg-green){background-color:var(--color-primary-green);color:var(--color-white);}
  :where(.tc_deck--bg-violet){background-color:var(--color-primary-violet);color:var(--color-white);}
  :where(.tc_deck--bg-dark-bordeaux){background-color:var(--color-dark-bordeaux);color:var(--color-white);}
  :where(.tc_deck--bg-dark-nightblue){background-color:var(--color-dark-nightblue);color:var(--color-white);}
  :where(.tc_deck--bg-dark-forestgreen){background-color:var(--color-dark-forestgreen);color:var(--color-white);}
  :where(.tc_deck--bg-live){background-color:var(--color-live);color:var(--color-white);}
  :where(.tc_deck--bg-rm){background-color:var(--color-rm);color:var(--color-white);}
  :where(.tc_deck--bg-play){background-color:var(--color-dark-special);color:var(--color-white);}
  :where(.tc_deck--invert){background-color:var(--color-neutral-900);color:var(--color-white);color-scheme:light;
    :where([data-teaser-media-type]:not([data-teaser-media-type="play"]) .tc_teaser__tagline){
      &::before,&::after{color-scheme:dark;}
    }
  }

  :where(.tc_deck--bg-900,.tc_deck--bg-midnight,.tc_deck--bg-tv2red,.tc_deck--bg-blue,.tc_deck--bg-green,.tc_deck--bg-violet,.tc_deck--bg-dark-bordeaux,.tc_deck--bg-dark-nightblue,.tc_deck--bg-dark-forestgreen,.tc_deck--bg-live,.tc_deck--bg-rm){
    .tc_richcontent a, a.tc_byline__author .tc_byline__author__name{color:var(--color-link-dark);}
    .tc_byline--prefix::before,.tc_timestamp,.tc_caption,.tc_ad--tag::before{color:var(--color-neutral-400);}
  }

  :where([class*="tc_deck--bg"]:not(.tc_deck--bg-transparent):not(.tc_deck--bg-100):not(.tc_deck--bg-play)){color-scheme:light;}
}

@include respond-to(medium-inline) {
  :where(.tc_deck--fullcol2center,.tc_deck--fullcol3center){padding-left:calc((100% - var(--bp)) / 2);padding-right:calc((100% - var(--bp)) / 2);}

  // handle full width remotes
  main:has([class*="tc_deck--full"]) .tc_deck:not([class*="tc_deck--full"]){padding-left:calc((100% - var(--bp)) / 2);padding-right:calc((100% - var(--bp)) / 2);}

  // Deck header
  :where(.tc_deck__header){
    [data-grid-lg="row_2-1"] .tc_grid:not(:first-child) &{padding-top:0;} // hack to remove extra padding on top of deck header
    [data-grid-lg="row_2-1"] > .tc_grid + .tc_grid &{padding-top:var(--spacing-16);} // hack to set extra padding on top of deck header
  }
}

@include respond-to(large-inline) {
  :where(.tc_deck--fullcol2center){padding-left:calc((100% - var(--bp-medium)) / 2);padding-right:calc((100% - var(--bp-medium)) / 2);}
  :where(.tc_deck--fullcol3center){padding-left:calc((100% - var(--bp-large)) / 2);padding-right:calc((100% - var(--bp-large)) / 2);}
  :where(.tc_deck--col2center){width:var(--bp-medium);padding-left:calc((var(--bp) - var(--bp-medium)) / 2);padding-right:calc((var(--bp) - var(--bp-medium)) / 2);}
  :where(.tc_deck--fullcol2center,.tc_deck--col2center){
    :where(.tc_deck){margin-left:calc((var(--bp) - var(--bp-medium)) / 2 * -1);margin-right:calc((var(--bp) - var(--bp-medium)) / 2 * -1);}
  }
  // Deck header
  :where(.tc_deck__header){flex-direction:row;flex-wrap:wrap;
    [data-grid-lg="row_2-1"] > .tc_grid + .tc_grid &{padding-top:0;} // hack to remove extra padding on top of deck header
    & > :last-child:not(:only-child){margin-left:auto;}
    & > .tc_richcontent{width:100%;padding-right:calc(100% - var(--main-medium));}
  }
}

@include respond-to(hover) {
  :where(.tc_deck--bg-900,.tc_deck--bg-midnight,.tc_deck--bg-tv2red,.tc_deck--bg-blue,.tc_deck--bg-green,.tc_deck--bg-violet,.tc_deck--bg-dark-bordeaux,.tc_deck--bg-dark-nightblue,.tc_deck--bg-dark-forestgreen,.tc_deck--bg-live){
    .tc_richcontent a:hover, a.tc_byline__author:hover .tc_byline__author__name{color:var(--color-link-dark-hover);}
  }
}

@include respond-to(print) {
  .tc_deck{max-width:var(--main-medium);margin:0 auto;
    &[data-not-printable]{display:none!important;}
  }
}
