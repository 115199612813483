// BYLINE
@import "core";

@include respond-to(base-inline) {
  :where(.tc_byline){font-size:var(--font-12-14);line-height:var(--line-110);
    strong{font-weight:var(--font-medium);}
  }
  :where(.tc_byline--prefix){display:flex;gap:var(--spacing-4);
    .tc_byline__prefix{color:var(--color-600);}
  }
  :where(.tc_byline__author){text-decoration:none;color:inherit;column-gap:var(--spacing-8);
    .tc_image{width:var(--spacing-48);}
  }
  :where(.tc_byline__author[href] .tc_byline__author__name){color:var(--color-link);}
  :where(.tc_byline__author__placeholder-image){display:flex;justify-content:center;align-items:center;max-width:100%;border-radius:50%;aspect-ratio:1;background-color:var(--color-100);
    svg{fill:var(--color-400);}
  }
  :where(.tc_byline__author--profile){display:flex;row-gap:var(--spacing-16);
    &:not(:last-child){margin-bottom:var(--spacing-16);}
    .tc_byline__author__info{display:flex;flex-direction:column;align-self:center;}
    .tc_byline__author__text{font-size:var(--font-12);line-height:var(--line-120);color:var(--color-600);}
  }
  :where(.tc_byline__author__text) span{color:var(--color-600);}
  :where(.tc_byline__author__location){font-size:var(--font-12);line-height:var(--line-120);color:var(--color-600);}

  :where(.tc_byline--s){font-size:var(--font-12);
    :where(.tc_byline__author) .tc_image{width:var(--spacing-40);}
    :where(.tc_byline__author--profile) .tc_byline__author__text,:where(.tc_byline__author__location){font-size:var(--font-10);}
  }
  :where(.tc_byline--l){font-size:var(--font-16);
    :where(.tc_byline__author) .tc_image{width:var(--spacing-56);}
    :where(.tc_byline__author--profile) .tc_byline__author__text,:where(.tc_byline__author__location){font-size:var(--font-14);}
  }
}

@include respond-to(hover) {
  :where(.tc_byline__author[href]:hover .tc_byline__author__name){transition:color var(--transition-faster) var(--link-easing-hover);color:var(--color-link-hover);}
}

@include respond-to(print) {
  .tc_byline{font-size:var(--font-14);}
  .tc_byline__author{text-decoration:none;color:inherit;
    .tc_image{width:var(--spacing-40);margin-right:var(--spacing-8);}
    .tc_byline__author__placeholder-image{border-radius:50%;}
  }
  .tc_byline__author__placeholder-image{max-width:100%;}
  .tc_byline__author--profile{display:flex;
    &:not(:last-child){margin-bottom:var(--spacing-20);}
    .tc_byline__author__info{display:flex;flex-direction:column;align-self:center;}
    .tc_byline__author__text{font-size:var(--font-12);line-height:var(--line-120);}
  }
  .tc_byline__author__name{white-space:nowrap;
    a.tc_byline__author{color:inherit;}
  }
  .tc_byline__author__location{font-size:var(--font-12);line-height:var(--line-120);}
}
