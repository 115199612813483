// CAPTION
@import "core";

@include respond-to(base-inline) {
  :where(.tc_caption){clear:both;color:var(--color-600);font-size:var(--font-14);line-height:var(--line-130);padding:var(--spacing-4-step) 0 0;overflow-wrap:break-word;max-width:var(--main-medium);margin:0 auto;}
}

@include respond-to(print) {
  .tc_caption{clear:both;font-size:var(--font-14);line-height:var(--line-130);padding:var(--spacing-8);overflow-wrap:break-word;}
}
