// TABS
@import "core";

@include respond-to(base-inline) {
  :where(.tc_tabs--scrollable){
    .tc_tabs__list{flex-wrap:nowrap;padding-bottom:var(--spacing-20);margin:0 0 calc(var(--spacing-20) * -1);}
  }
  :where(.tc_tabs--gapless){
    .tc_tabs__list{gap:0;}
  }
  :where(.tc_tabs--inverted){
    .tc_tabs__list__item__link--active{background:var(--color-light);color:var(--color-text);}
    .tc_tabs__content{margin-top:var(--spacing-4);}
  }

  :where(.tc_tabs__list__wrap){position:relative;overflow:hidden;}
  :where(.tc_tabs__list){list-style:none;position:relative;display:flex;flex-wrap:wrap;gap:var(--spacing-4-step);overflow:hidden;overflow-x:auto;scrollbar-width:none;
    &::-webkit-scrollbar{height:0;}
  }
  :where(.tc_tabs__list__item){display:flex;
    &:only-child{pointer-events:none;}
  }
  :where(.tc_tabs__list__item__link){display:flex;justify-content:center;align-items:center;color:var(--color-dark-800);transition:background-color var(--transition-faster), color var(--transition-faster);min-width:calc(var(--spacing-16-jump) + var(--spacing-24-step));padding:var(--spacing-8) var(--spacing-8-step);white-space:nowrap;text-decoration:none;font-size:var(--font-12);font-weight:var(--font-medium);
    &:focus{outline-offset:calc(var(--spacing-1) * -1);}
    &:focus-visible{outline-offset:calc(var(--spacing-1) * -1);}
  }
  :where(.tc_tabs__list__item__link--active){background:var(--color-dark-200);color:var(--color-text);}
  :where(.tc_tabs__arrows){opacity:0;pointer-events:none;transition:all var(--transition-fast);
    .tc_button{position:absolute;top:0;bottom:0;z-index:3;margin:auto 0;
      &:first-child{left:0;}
      &:last-child{right:0;}
    }
  }
  :where(.tc_tabs__content){margin-top:var(--spacing-20);}
  :where(.tc_tabs__content__pane){display:none;
    &.tc_tabs__content__pane--active{display:block;}
  }

  @media (scripting:none) {
    .tc_tabs__content{
      &:has(.tc_tabs__content__pane:target) :where(.tc_tabs__content__pane){display:none;}
      .tc_tabs__content__pane{
        &:not(.tc_tabs__content__pane--active){display:none;}
        &:target{display:block;}
      }
    }
    .tc_tabs{
      &:has(.tc_tabs__content__pane:target) .tc_tabs__list__item__link--active{background:inherit;color:var(--color-dark-800);}
      &:has(.tc_tabs__content__pane:nth-child(1):target) .tc_tabs__list__item:nth-child(1) .tc_tabs__list__item__link{background:var(--color-dark-200);color:var(--color-text);}
      &:has(.tc_tabs__content__pane:nth-child(2):target) .tc_tabs__list__item:nth-child(2) .tc_tabs__list__item__link{background:var(--color-dark-200);color:var(--color-text);}
      &:has(.tc_tabs__content__pane:nth-child(3):target) .tc_tabs__list__item:nth-child(3) .tc_tabs__list__item__link{background:var(--color-dark-200);color:var(--color-text);}
      &:has(.tc_tabs__content__pane:nth-child(4):target) .tc_tabs__list__item:nth-child(4) .tc_tabs__list__item__link{background:var(--color-dark-200);color:var(--color-text);}
      &:has(.tc_tabs__content__pane:nth-child(5):target) .tc_tabs__list__item:nth-child(5) .tc_tabs__list__item__link{background:var(--color-dark-200);color:var(--color-text);}
      &:has(.tc_tabs__content__pane:nth-child(6):target) .tc_tabs__list__item:nth-child(6) .tc_tabs__list__item__link{background:var(--color-dark-200);color:var(--color-text);}
      &:has(.tc_tabs__content__pane:nth-child(7):target) .tc_tabs__list__item:nth-child(7) .tc_tabs__list__item__link{background:var(--color-dark-200);color:var(--color-text);}
      &:has(.tc_tabs__content__pane:nth-child(8):target) .tc_tabs__list__item:nth-child(8) .tc_tabs__list__item__link{background:var(--color-dark-200);color:var(--color-text);}
      &:has(.tc_tabs__content__pane:nth-child(9):target) .tc_tabs__list__item:nth-child(9) .tc_tabs__list__item__link{background:var(--color-dark-200);color:var(--color-text);}
      &:has(.tc_tabs__content__pane:nth-child(10):target) .tc_tabs__list__item:nth-child(10) .tc_tabs__list__item__link{background:var(--color-dark-200);color:var(--color-text);}
    }
  }
}

@include respond-to(hover) {
  :where(.tc_tabs--scrollable .tc_tabs__list):hover ~ .tc_tabs__arrows{opacity:1;pointer-events:auto;}
  :where(.tc_tabs__list__item__link:not(.tc_tabs__list__item__link--active)):hover{background:var(--color-dark-100);}
  :where(.tc_tabs--inverted .tc_tabs__list__item__link:not(.tc_tabs__list__item__link--active)):hover{background:var(--color-light-600);}
}

@include respond-to(print) {
  .tc_tabs__list__wrap{display:none;}
}
