// REEL
@import "core";

@include respond-to(base-nomediaquery) {
  @keyframes hint {
    0% {background-position-y:var(--spacing-4);}
    5% {background-position-y:var(--spacing-2);}
    10% {background-position-y:var(--spacing-6);}
    25% {background-position-y:var(--spacing-4);}
  }
  @keyframes pause {
    0% {scale:1;}
    20% {opacity:1;}
    99% {opacity:0;scale:2;}
    100% {scale:1;}
  }
  @keyframes play {
    0% {scale:1;}
    20% {opacity:1;}
    99% {opacity:0;scale:2;}
    100% {scale:1;}
  }
}

@include respond-to(base-inline) {
  .tc_reel{scroll-snap-type:y mandatory;overflow-x:hidden;overflow-y:auto;height:100dvh;overscroll-behavior:contain;-webkit-user-select:none;user-select:none;color-scheme:light;
    &:not(.tc_reel--ready){
      &::before,&::after{content:"";display:block;position:fixed;top:calc(var(--spacing-16) * -1);bottom:calc(var(--spacing-16) * -1);left:0;right:0;z-index:6;}
      &::before{background:var(--color-700);}
      &::after{background:url('inline!../../../../icons/spinner.svg') no-repeat 50% 50%;background-size:var(--spacing-48) auto;filter:invert(1) brightness(2);}
    }
    &:not(.tc_reel--hide-hint) .tc_reel__list__item:first-of-type{
      .tc_reel__list__item__overlay__main__meta{padding-bottom:var(--spacing-40);
        &::after{content:"Swipe op for næste video";display:block;position:absolute;left:0;right:0;bottom:calc(var(--spacing-8) * -1);padding:var(--spacing-20) 0 0;width:50%;margin:auto;font-size:var(--font-12);color:var(--color-dark);text-align:center;background:url('inline!../../../../icons/chevron-up.svg') no-repeat 50% var(--spacing-4);background-size:var(--spacing-20) auto;animation:hint 2s 2s ease-in infinite;opacity:.8;filter:invert(1) brightness(2);}
      }
    }
  }
  .tc_reel__list{display:grid;justify-content:center;
    &::after{content:"";display:block;position:fixed;top:0;left:0;right:0;bottom:0;background-color:var(--color-midnight-700);backdrop-filter:blur(200px);pointer-events:none;z-index:-1;}
  }
  .tc_reel__list__nav{height:0;position:sticky;top:0;display:flex;justify-content:flex-end;}
  .tc_reel__list__nav__buttons{display:none;flex-direction:column;gap:var(--spacing-16);justify-content:flex-end;align-items:flex-end;height:94vh;margin-right:calc(var(--spacing-72) * -1);
    .tc_button{visibility:visible;
      &[disabled]{visibility:hidden;}
    }
  }
  .tc_reel__list__item{position:relative;max-width:100vw;height:100dvh;scroll-snap-align:start;scroll-snap-stop:always;overflow:hidden;
    &::before{content:"";display:block;opacity:0;transition:opacity var(--transition-slowest);background-image:var(--reel-bg);}
    &.tc_reel__list__item--active{
      &::before{position:fixed;top:0;left:0;right:0;bottom:0;background-image:var(--reel-bg);background-repeat:no-repeat;background-position:50% 50%;background-size:cover;pointer-events:none;z-index:-1;opacity:1;}
    }
  }
  .tc_reel__list__item--hide{display:none;}
  .tc_reel__list__item__video{position:relative;width:100vw;height:100%;background-color:var(--color-700);
    video{width:100vw;height:100%;object-fit:cover;aspect-ratio:404/720;pointer-events:none;}
    .tc_button{position:absolute;top:0;bottom:0;width:100%;height:100%;border-radius:0;justify-content:center;opacity:0;aspect-ratio:unset;max-width:none;
      &:focus-visible{outline:0;
        &[data-button-play-state]{opacity:1;animation:none;}
        span{outline:var(--spacing-2) solid var(--color-focus);outline-offset:var(--spacing-2);}
      }
      &[data-button-play-state="paused"]{animation:play var(--transition-slowest);}
      &[data-button-play-state="playing"]{animation:pause var(--transition-slowest);}
    }
  }
  .tc_reel__list__item__error{position:absolute;top:0;left:0;right:0;bottom:0;z-index:1;display:flex;flex-direction:column;align-items:center;justify-content:center;row-gap:var(--spacing-16);background:var(--color-700);color:var(--color-light);line-height:var(--line-120);
    p{max-width:calc(var(--unit) * 260);text-align:center;}
    span{display:block;padding-top:var(--spacing-16);}
  }
  .tc_reel__list__item__overlay{position:absolute;top:0;left:0;right:0;height:100%;z-index:4;display:flex;transition:height var(--transition-faster);pointer-events:none;}
  .tc_reel__list__item__overlay__main{position:relative;bottom:0;display:flex;flex-direction:column;gap:var(--spacing-8);margin-top:auto;flex:1;padding:0 0 var(--spacing-16-jump);max-width:100%;transition:transform var(--transition-normal);color:var(--color-light);background:linear-gradient(to bottom, transparent, var(--color-midnight-800) var(--spacing-64));pointer-events:auto;
    &:has(.tc_text-expand[open]){background:linear-gradient(to bottom, transparent, var(--color-midnight-900) var(--spacing-64));}
  }
  .tc_reel__list__item__overlay__main__buttons{display:flex;flex-direction:column;gap:calc(var(--spacing-32) + var(--spacing-4-step));position:absolute;bottom:100%;right:0;padding:var(--spacing-32-step) var(--gutter);
    .tc_share__popover{color-scheme:dark;}
  }
  .tc_reel__list__item__overlay__main__scrubber{flex:1;margin:0 var(--gutter);
    .tc_form__label span{position:absolute;left:0;top:calc(var(--spacing-4) * -1);font-size:var(--font-12);visibility:hidden;}
    &:has(.tc_form__range:active:not([disabled])){
      .tc_form__label span{visibility:visible;}
    }
  }
  .tc_reel__list__item__overlay__main__meta{position:relative;display:flex;flex-direction:column;justify-content:center;gap:var(--spacing-8);
    &::after{display:none;}
    .tc_timestamp{color:var(--color-400);font-size:var(--font-12);font-weight:var(--font-medium);}
  }
  .tc_reel__list__item__overlay__main__meta__header{display:flex;justify-content:space-between;padding:0 var(--gutter);}
  .tc_reel__list__item__overlay__main__meta__description{max-height:40vh;overflow-y:auto;
    .tc_text-expand{font-size:var(--font-14-16);line-height:var(--line-130);overflow:auto;padding:0 var(--gutter);max-height:var(--spacing-24);transition:max-height var(--transition-normal);
      &[open]{max-height:40vh;}
      .tc_text-expand__summary{padding:0 var(--gutter);}
      .tc_text-expand__body{
        .tc_button{align-self:center;margin:var(--spacing-16) 0;max-width:calc(100% - var(--spacing-32));overflow:hidden;}
      }
    }
  }

  .tc_reel__dialog{width:100%;background:transparent;box-sizing:border-box;max-width:calc(var(--unit) * 430);margin:auto;padding:0;border:0;}
  .tc_reel__playback_disabled{background-color:var(--color-900);color:var(--color-light);display:flex;flex-direction:column;align-items:center;justify-content:center;row-gap:var(--spacing-32);padding:var(--spacing-48);font-size:var(--font-16);max-width:calc(300 * var(--unit));margin:auto;}

  @supports not (height:1dvh){
    .tc_reel,.tc_reel__list__item{height:100vh;}
  }

  @media (pointer: fine) {
    .tc_reel:not(.tc_reel--hide-hint) .tc_reel__list__item:first-of-type .tc_reel__list__item__overlay__main__meta::after{content:"Scroll for næste video";}
  }
}

@include respond-to(medium-inline) {
  @media only screen and (min-height: #{$bp-small}) {
    .tc_reel__list{gap:1dvh;}
    .tc_reel__list__item{height:94dvh;padding-top:1dvh;}
    .tc_reel__list__item__video{width:auto;
      video{width:auto;}
    }

    @supports not (height:1dvh){
      .tc_reel__list{gap:1vh;}
      .tc_reel__list__item{height:95vh;padding-top:1vh;}
    }
    @media (pointer: fine) {
      .tc_reel__list__nav__buttons{display:flex;}
    }
  }
}

@include respond-to(hover) {
  .tc_reel__list__item__video{
    .tc_button:hover .tc_button__icon{color:var(--color-light);background-color:var(--color-midnight-700);}
  }
}

@include respond-to(print) {
  .tc_reel__wrapper::before{content:"Nothing to see here...";}
  .tc_reel{display:none;}
}
