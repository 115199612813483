// TEXT BOX
@import "core";

@include respond-to(base-inline) {
  .tc_textbox{--bgcolor:var(--color-100);padding:var(--spacing-16) var(--spacing-16) 0;background-color:var(--bgcolor);}
  .tc_textbox--toggle{position:relative;max-height:calc(220 * var(--unit));overflow:auto;}
  .tc_textbox__header{
    .tc_heading{padding-bottom:var(--spacing-4);
      & + .tc_byline{padding-top:var(--spacing-12);}
    }
    .tc_byline{padding-bottom:var(--spacing-16);}
  }
  .tc_textbox__body{padding-bottom:var(--spacing-20);
    .tc_image{margin-bottom:var(--spacing-16);}
    .tc_caption{border:0;}
  }
  .tc_textbox__header__label{font-size:var(--font-12);padding-bottom:var(--spacing-4);color:light-dark(var(--color-dark-bordeaux), var(--color-bright-peach));}
  .tc_textbox__header__label--color-sport{color:light-dark(var(--color-dark-nightblue), var(--color-bright-sky));}
  .tc_textbox__header__label--color-vejr{color:light-dark(var(--color-dark-forestgreen), var(--color-light-green));}
  .tc_textbox__header__label--color-echo{color:light-dark(var(--color-dark-plum), var(--color-bright-lavendar));}

  // Light theme fallback (iOS < 17.5)
  @supports not (color: light-dark(#fff, #000)) {
    .tc_textbox__header__label{color:var(--color-dark-bordeaux);}
    .tc_textbox__header__label--color-sport{color:var(--color-dark-nightblue);}
    .tc_textbox__header__label--color-vejr{color:var(--color-dark-forestgreen);}
    .tc_textbox__header__label--color-echo{color:var(--color-dark-plum);}
  }

  @media (scripting:none) {
    .tc_textbox__footer{display:none;}
  }
}

@include respond-to(base) {
  .tc_textbox--toggle{
    .tc_textbox__footer{display:none;position:absolute;bottom:0;left:0;right:0;z-index:1;background-color:inherit;height:calc(60 * var(--unit));padding-top:var(--spacing-8);text-align:center;
      &__hidden {display: none;}
      &::before{content:"";position:absolute;z-index:1;bottom:calc(68 * var(--unit));left:0;right:0;height:calc(100 * var(--unit));background:linear-gradient(to bottom,transparent, var(--bgcolor));pointer-events:none;}
    }
    &.js_textbox--toggle{overflow:hidden;
      .tc_textbox__footer{display:block;}
    }
    &.js_textbox--notoggle{max-height:none;overflow:visible;
      .tc_textbox__footer{display:none;}
    }
    &.js_textbox--open{max-height:none;width:auto;
      .tc_textbox__footer{display:none;}
    }
  }
}

@include respond-to(print) {
  .tc_textbox{padding:var(--spacing-20);border:var(--spacing-2) solid var(--color-black);}
  .tc_textbox__header{margin-bottom:var(--spacing-8);}
  .tc_textbox__footer{display:none;}
}
