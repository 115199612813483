// SCOREBAORD
@import "core";

@include respond-to(base-inline) {
  .tc_scoreboard{position:relative;background:var(--color-100);padding:var(--spacing-16) calc(var(--spacing-8-step));overflow:hidden;
    &[data-state="NOTSTARTED"],&[data-state="CANCELLED"]{
      .tc_scoreboard__status{top:var(--spacing-32-step);}
    }
    &[data-state="INPROGRESS"]{
      .tc_scoreboard__status{color:var(--color-live);}
    }
    &[data-state="CANCELLED"],&[data-state="INTERRUPTED"]{
      .tc_scoreboard__status{color:var(--color-error);}
    }
    &[data-state="UNKNOWN"],&[data-state="DELETED"]{
      .tc_scoreboard__status{position:static;}
    }
  }
  .tc_scoreboard__match{position:relative;z-index:0;display:flex;justify-content:space-between;}
  .tc_scoreboard__match__team{position:relative;padding-top:var(--spacing-56-step);background-repeat:no-repeat;background-position:50% var(--spacing-8);background-size:var(--spacing-40-step) auto;min-width:40%;max-width:40%;text-align:center;font-size:var(--font-14-16);font-weight:var(--font-medium);line-height:var(--line-120);overflow-wrap:break-word;
    &::before{content:"";position:absolute;z-index:-1;top:0;margin:0 auto;width:var(--spacing-56-step);height:var(--spacing-56-step);background:var(--color-light);border-radius:50%;}
    &:first-child{text-align:left;background-position:var(--spacing-8) var(--spacing-8);
      &::before{left:0;}
    }
    &:last-child{text-align:right;background-position:right var(--spacing-8) top var(--spacing-8);
      &::before{right:0;}
    }
  }
  .tc_scoreboard__match__vs{position:absolute;top:calc(-100 * var(--unit));}
  .tc_scoreboard__result{position:absolute;top:var(--spacing-20);left:25%;right:25%;font-weight:var(--font-bold);font-size:var(--font-28-32-36);line-height:var(--line-120);display:flex;justify-content:center;}
  .tc_scoreboard__result__score{padding:0 var(--spacing-3);flex:1 1 0%;white-space:nowrap;}
  .tc_scoreboard__result__score:first-child{text-align:right;}
  .tc_scoreboard__status{position:absolute;top:calc(var(--spacing-48) + var(--spacing-4) + var(--spacing-0-step));left:25%;right:25%;text-align:center;font-size:var(--font-16-18);}
  .tc_scoreboard--national-teams{padding-top:var(--spacing-24);
    .tc_scoreboard__match__team{padding-top:var(--spacing-32);background-size:auto calc(var(--spacing-20) + var(--spacing-4));
      &::before{display:none;}
      &:first-child{background-position:0 0;}
      &:last-child{background-position:100% 0;}
    }
  }
}

@include respond-to(small-inline) {
  .tc_scoreboard{
    &[data-state="NOTSTARTED"],&[data-state="CANCELLED"]{
      &.tc_scoreboard--national-teams{
        .tc_scoreboard__status{top:var(--spacing-32);}
      }
    }
  }
  .tc_scoreboard--national-teams{
    .tc_scoreboard__result{top:var(--spacing-16);}
    .tc_scoreboard__status{top:calc(var(--spacing-48) + var(--spacing-4));}
  }
}

@include respond-to(print) {
  .tc_scoreboard{display:flex;flex-direction:column;border:var(--spacing-2) solid var(--color-black);padding:var(--spacing-8);}
  .tc_scoreboard__match{display:flex;justify-content:space-between;}
  .tc_scoreboard__match__vs{display:none}
  .tc_scoreboard__match__team{font-size:var(--font-28);font-weight:var(--font-bold);background:none!important;}
  .tc_scoreboard__result{font-size:var(--font-20);font-weight:var(--font-bold);text-align:center;}
  .tc_scoreboard__status{text-align:center;}
}
