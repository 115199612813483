// LIST
@import "core";

@include respond-to(base-inline) {
  .tc_list{list-style:none;display:flex;flex-wrap:wrap;row-gap:var(--spacing-32);
    &[data-size="small"]{row-gap:var(--spacing-16);
      .tc_list__item{
        &::after{bottom:calc(var(--spacing-16) * -1);}
        &:last-child::after{bottom:0;}
      }
    }
    &.tc_list--related .tc_list__item::after{bottom:0;}
  }
  .tc_list__item{position:relative;padding:0 var(--spacing-16) 0 var(--spacing-20);box-sizing:border-box;width:100%;
    &::after{content:"";position:absolute;left:0;top:0;bottom:calc(var(--spacing-32) * -1);border-left:var(--spacing-4) solid var(--color-dark-200);}
    &:last-child::after{bottom:0;}
    .tc_teaser--bg-breaking{background:transparent;color:var(--color-text);
      .tc_label{background-color:var(--color-breaking);color:var(--color-midnight);}
      .tc_teaser__header{padding:0!important;}
    }
  }

  ol.tc_list{counter-reset:item;
    .tc_list__item{padding-left:var(--spacing-56);
      &::before{position:absolute;top:var(--spacing-16);left:var(--spacing-20);content:counter(item);counter-increment:item;font-size:var(--font-28-32-36);font-weight:var(--font-bold);}
    }
  }
}

@include respond-to(medium-inline) {
  .tc_list{
    &[data-columns="2"][data-items="4"]{
      .tc_list__item{width:50%;
        &:nth-child(1){order:1;}
        &:nth-child(2){order:3;
          &::after{bottom:0;}
        }
        &:nth-child(3){order:2;}
        &:nth-child(4){order:4;}
      }
    }
    &[data-columns="2"][data-items="6"]{
      .tc_list__item{width:50%;
        &:nth-child(1){order:1;}
        &:nth-child(2){order:3;}
        &:nth-child(3){order:5;
          &::after{bottom:0;}
        }
        &:nth-child(4){order:2;}
        &:nth-child(5){order:4;}
        &:nth-child(6){order:6;}
      }
    }
    &[data-columns="3"][data-items="6"]{
      .tc_list__item{width:50%;
        &:nth-child(1){order:1;}
        &:nth-child(2){order:3;}
        &:nth-child(3){order:5;
          &::after{bottom:0;}
        }
        &:nth-child(4){order:2;}
        &:nth-child(5){order:4;}
        &:nth-child(6){order:6;}
      }
    }
  }
}

@include respond-to(large-inline) {
  .tc_list{
    &[data-columns="3"][data-items="6"]{
      .tc_list__item{width:33.33%;
        &:nth-child(1){order:1;}
        &:nth-child(2){order:4;
          &::after{bottom:0;}
        }
        &:nth-child(3){order:2;
          &::after{bottom:calc(var(--spacing-32) * -1);}
        }
        &:nth-child(4){order:5;
          &::after{bottom:0;}
        }
        &:nth-child(5){order:3;}
        &:nth-child(6){order:6;}
      }
    }
  }
  ol.tc_list .tc_list__item{padding-left:var(--spacing-72);
    &::before{top:var(--spacing-12);left:var(--spacing-24);}
  }
}

@include respond-to(print) {
  .tc_list{list-style:none;padding:0 var(--spacing-16);margin:0;
    &[data-columns="1"]{padding:0;}
    .tc_teaser{margin:0 0 var(--spacing-8);}
  }
}
