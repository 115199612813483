// AD
@import "core";

@include respond-to(base-inline-nomediaquery) {
  // safari hack to invalidate :empty by running a continuous CSS animation
  @keyframes redraw{
    0% {opacity:1;}
    100% {opacity:.999;}
  }
}

@include respond-to(base-inline) {
  // default
  :where(.tc_ad){position:relative;display:flex;justify-content:center;align-items:center;margin:0 auto;overflow:hidden;color:transparent;cursor:pointer;
    .tc_grid--gutter &{margin-left:calc(var(--gutter) * -1);margin-right:calc(var(--gutter) * -1);}
  }

  // we lock in the height on the first mobile banner to prevent CLS
  :where([data-ad="topbanner-2"]){min-height:calc(320 * var(--static-unit));
    &.tc_ad--tag{min-height:calc((320 * var(--static-unit)) + var(--spacing-20));}
  }

  // hide these until the decider banner choose to display them
  :where(.tc_ad--skyscraperleft,.tc_ad--skyscraperright,.tc_ad--dd){display:none;overflow:visible;}

  // optional wrapper
  :where(.tc_adwrap){position:relative;
    td{padding:0!important;}
  }

  // 'annonce' tag
  :where(.tc_ad--tag){
    &::before{content:"Annonce";position:absolute;left:0;right:0;top:0;display:block;height:var(--spacing-20);font-size:var(--font-12);line-height:var(--spacing-20);text-align:center;color:var(--color-600);pointer-events:none;}
    & > div:not(.tc_ad__object){margin-top:var(--spacing-20);}
    .tc_pageobject &::before{content:"Artiklen fortsætter efter annoncen";}
  }

  // sticky
  :where(.tc_ad--sticky){position:sticky;top:var(--spacing-4);will-change:transform;}

  // MIDSCROLL
  [class*="tc_ad--midscroll"]{
    // container
    &[data-midscroll-position="false"]{display:none;}
    &[data-midscroll-position="true"]{clip-path:inset(var(--spacing-1) 0);height:80vh;max-height:calc(600 * var(--static-unit));
      &.tc_ad--tag{padding-top:var(--spacing-20);
        &::before{background-color:var(--color-light);}
      }
    }
    & > div:not(.tc_ad__object){width:100%!important;height:100%!important;pointer-events:none;visibility:hidden;
      iframe{width:100%!important;height:100%!important;}
    }
  }
  // safari/chrome hack to enable clip-path overflow in tables
  :where(.tc_datatable):has([class*="tc_ad--midscroll"]){overflow:visible;}

  .tc_ad--midscroll-dynamic .tc_ad__object::before{content:"";position:absolute;left:0;right:0;top:0;bottom:0;z-index:1;cursor:pointer;}

  // INTERSCROLL
  :where([data-ad="interscroll"]){
    // container
    [data-interscroll-position="false"]{display:none;}
    [data-interscroll-position="true"]{
      &.tc_ad--tag{padding-top:var(--spacing-20);
        &::before{top:calc(var(--spacing-1) * -1);background-color:var(--color-light);}
      }
    }

    // container in <main>
    #main [data-interscroll-position="true"]{clip-path:inset(0 calc(var(--spacing-8) * -1));height:80vh;max-height:calc(600 * var(--static-unit));}

    // hide banners that needs to be excluded on interscroll
    [data-exclude-decider="1"] > :not(.tc_ad__object),[data-exclude-on*="interscroll"] > :not(.tc_ad__object){display:none!important;}

    // safari/chrome hack to enable clip-path overflow in tables
    :where(.tc_datatable):has([data-interscroll-position="true"]){overflow:visible;}
  }

  // an object containing the actual creative
  :where([data-ad="interscroll"],[class*="tc_ad--midscroll"]){
    .tc_ad__object{position:fixed;top:0;bottom:0;left:0;right:0;background-repeat:no-repeat;background-position:50% 0;z-index:-1;will-change:transform;cursor:pointer;}
  }

  // collapse empty ad placeholders (but still enable GAM to lazyload ads)...
  body:not([data-ad="interscroll"]){
    // ...the 'pageobject' wrapper only (articles)
    .tc_pageobject[data-object-type="ad"]:has(.tc_ad[data-collapse="true"]:empty, .tc_ad[data-collapse="true"] > div:not(.tc_ad__object):empty){height:0;min-height:0;margin:0;overflow:hidden;
      &::before{display:none;}
    }
    // ...the 'adwrap' wrapper only (liveblogs and other lists and tables...)
    .tc_adwrap:has(.tc_ad[data-collapse="true"]:empty, .tc_ad[data-collapse="true"] > div:not(.tc_ad__object):empty){height:0;padding:0;overflow:hidden;
      &::before{display:none;}
    }
    // ...the 'adwrap' wrapper only (kort nyt extra)
    .tc_newsfeed__body__posts .tc_adwrap:has(.tc_ad[data-collapse="true"]:empty, .tc_ad[data-collapse="true"] > div:not(.tc_ad__object):empty){margin-bottom:calc(var(--spacing-20) * -1);}
    // ....all ad placeholders (inside 'pageobject' or 'adwrap' containers)
    .tc_ad[data-collapse="true"]{
      &:not(.tc_ad__object):empty,&:has(div:not(.tc_ad__object):empty),div:not(.tc_ad__object):empty{height:0;min-height:0;margin:0;overflow:hidden;
        &::before{display:none;}
      }
    }

    // safari hack to invalidate :empty by running a continuous CSS animation
    @supports (background:filter(url(),blur(1px))){
      .tc_pageobject[data-object-type="ad"]:has(.tc_ad[data-collapse="true"]),.tc_ad[data-collapse="true"]{animation:redraw 1s linear infinite;}
    }
  }

  // sizes
  :where(.tc_ad[data-sizes="none"]){display:none;}
  :where(.tc_adwrap:has([data-sizes="none"]):not(:has([data-sizes*="x"]))){display:none;}

  // set a shadow on all decks with 'dd' and 'interscroll' (and Adnami) banners
  :where([data-ad="dd"],[data-ad="interscroll"],.adsm-skin){
    #main .tc_deck{
      &:not(.tc_deck--bg-transparent)::after{content:"";position:absolute;z-index:-1;top:0;bottom:0;left:0;right:0;box-shadow:0 0 var(--spacing-4) 0 var(--color-midnight-800);}
      .tc_deck::after{display:none;}
    }
  }
  // optimize dedicated interscroll decks on frontpages (make deck shadows work)
  :where([data-ad="interscroll"]){
    .tc_deck:has(> [data-interscroll-position="true"][data-sizes*="x"]){background:transparent;padding:0;height:var(--spacing-20);margin-bottom:min(80vh, calc(600 * var(--static-unit)));
      .tc_ad{z-index:-1;}
    }
  }
}

@include respond-to(medium-inline) {
  // we try to scale desktop banners to work in portrait tablet breakpoint
  body:not([data-ad="interscroll"]) :where([data-sizes-medium*="930x"]){
    & > div{transform:scale(.7311827);transform-origin:50% 50% 0;min-width:calc(930 * var(--static-unit));}
  }

  // we lock in the height on the first desktop banner to prevent CLS
  :where([data-ad="topbanner-1"],[data-ad="inline-1"]){min-height:calc(132 * var(--static-unit));max-height:calc(132 * var(--static-unit));
    &.tc_ad--tag{min-height:calc(132 * var(--static-unit) + var(--spacing-20));max-height:calc(132 * var(--static-unit) + var(--spacing-20));}
  }

  // and set a min and max height on the rest to improve CLS
  :where(.tc_ad--inline:not([data-ad="inline-1"])){min-height:calc(132 * var(--static-unit));max-height:calc(438 * var(--static-unit));
    &.tc_ad--tag{min-height:calc(132 * var(--static-unit) + var(--spacing-20));max-height:calc(438 * var(--static-unit) + var(--spacing-20));}
  }

  // interscroll
  :where([data-ad="interscroll"]){
    // handle interscroll on full width pages (ie. feature articles)
    .tc_deck--fullcol2center .tc_ad{margin-left:calc(50% - 50vw);margin-right:calc(50% - 50vw);}

    // compensate for interscroll creative on desktop when header is without local nav (ie. frontpage)
    .tc_header:not(:has(.tc_header__local)){
      ~ .tc_ad--topbanner .tc_ad__object{top:calc(var(--spacing-40) * -1);}
      ~ main .tc_ad__object{top:calc(var(--spacing-40) * -1);}
    }
  }

  // sizes
  :where(.tc_ad){
    &[data-sizes-medium="none"]{display:none;}
    &[data-sizes-medium*="x"]{display:flex;}
  }
  :where(.tc_adwrap){
    &:has([data-sizes="none"]):not(:has([data-sizes*="x"])){display:block;}
    &:has([data-sizes-medium="none"]):not(:has([data-sizes-medium*="x"])){display:none;}
  }
  // optimize dedicated interscroll decks on frontpages (make deck shadows work)
  :where([data-ad="interscroll"]){
    .tc_deck:has(> [data-interscroll-position="true"][data-sizes-medium="none"]){height:0;margin-bottom:0;}
    .tc_deck:has(> [data-interscroll-position="true"][data-sizes-medium*="x"]){background:transparent;padding:0;height:var(--spacing-20);margin-bottom:min(80vh, calc(600 * var(--static-unit)));
      .tc_ad{z-index:-1;}
    }
  }
}

@include respond-to(large-inline) {
  // we reset the scaling of desktop banners to work in portrait tablet breakpoint
  body:not([data-ad="interscroll"]) :where([data-sizes-medium*="930x"]){
    & > div{transform:none;min-width:auto;}
  }

  // we lock in the height on the first desktop banner to prevent CLS
  :where([data-ad="topbanner-1"],[data-ad="inline-1"]){min-height:calc(180 * var(--static-unit));max-height:calc(180 * var(--static-unit));
    .tc_header ~ &{width:calc(930 * var(--static-unit));}
    &.tc_ad--tag{min-height:calc((180 * var(--static-unit)) + var(--spacing-20));max-height:calc(180 * var(--static-unit) + var(--spacing-20));}
  }

  // and set a min and max height on the rest to improve CLS
  :where(.tc_ad--inline:not([data-ad="inline-1"])){min-height:calc(180 * var(--static-unit));max-height:calc(600 * var(--static-unit));
    &.tc_ad--tag{min-height:calc((180 * var(--static-unit)) + var(--spacing-20));max-height:calc(600 * var(--static-unit) + var(--spacing-20));}
  }

  // handle the width of banners when they're wider than the page column
  :where(.tc_ad){
    :where(.tc_deck--fullcol2center,.tc_deck--col2center) &{margin-left:calc((var(--bp) - var(--bp-medium)) / 2 * -1);margin-right:calc((var(--bp) - var(--bp-medium)) / 2 * -1);}
    :where(.tc_deck--fullcol2center,.tc_deck--col2center) .tc_grid--gutter &{margin-left:calc((var(--bp) - var(--bp-medium) + (var(--gutter) * 2)) / 2 * -1);margin-right:calc((var(--bp) - var(--bp-medium) + (var(--gutter) * 2)) / 2 * -1);}
  }

  // SKYSCRAPERS
  .tc_ad--skyscraperleft,.tc_ad--skyscraperright{display:flex;max-width:var(--bp-large);height:0!important;min-height:0!important;overflow:hidden;
    & > div{position:absolute;top:0;overflow:hidden;width:0;height:calc(600 * var(--static-unit));}
  }
  .tc_ad--skyscraperleft > div{right:calc(100% + var(--spacing-4));}
  .tc_ad--skyscraperright > div{left:calc(100% + var(--spacing-4));}

  // skyscrapers 160x600 (84em = 1344px)
  @media only screen and (min-width: 84em) {
    .tc_ad--skyscraperleft,.tc_ad--skyscraperright{overflow:visible;
      & > div{width:calc(160 * var(--static-unit));}
    }
  }

  // skyscrapers 300x600 (101em = 1616px)
  @media only screen and (min-width: 101em) {
    .tc_ad--skyscraperleft,.tc_ad--skyscraperright{
      & > div{width:calc(300 * var(--static-unit));}
    }
  }

  // DD (wallpaper)
  :where([data-ad="dd"]){
    .tc_ad--topbanner iframe{color-scheme:light;}
    .tc_ad--dd{display:block;position:absolute;left:0;right:0;top:0;bottom:0;z-index:-1;}

    // hide banners that needs to be excluded on DD
    [data-exclude-on*="dd"]{display:none!important;}

    // an object containing the actual creative
    .tc_ad__object{height:100vh;position:sticky;top:0;background-repeat:no-repeat;background-position:50% 0;cursor:pointer;will-change:transform;margin:calc(80 * var(--unit)) 0 0;}

    // compensate for DD creative on desktop when header is higher because of local navigation
    .tc_header:has(.tc_header__local) ~ .tc_ad--dd .tc_ad__object{margin:calc(120 * var(--unit)) 0 0;}
  }

  // sizes
  :where(.tc_ad){
    &[data-sizes-large="none"]{display:none;}
    &[data-sizes-large*="x"],&[data-sizes-skyscrapers*="x"]{display:flex;}
  }

  // handle the width of banners when they're wider than the page column
  :where([data-ad="interscroll"]){
    .tc_deck:has(> [data-interscroll-position="true"][data-sizes-medium="none"],> [data-interscroll-position="true"][data-sizes-large="none"]){height:0;margin-bottom:0;}
    .tc_deck:has(> [data-interscroll-position="true"][data-sizes-large*="x"]){background:transparent;padding:0;height:var(--spacing-20);margin-bottom:min(80vh, calc(600 * var(--static-unit)));
      .tc_ad{z-index:-1;}
    }
  }
}

@include respond-to(print) {
  body .tc_ad[aria-hidden="true"]{display:none!important;} // specificity because of Adnami
}
