// DATEPICKER
@import "core";

@include respond-to(base-inline) {
  .tc_datepicker{background-color:var(--color-light);padding:var(--spacing-4) 0;}
  .tc_datepicker__link{position:relative;display:flex;justify-content:center;align-items:center;color:var(--color-text);text-decoration:none;min-width:var(--spacing-48);min-height:var(--spacing-48);box-sizing:border-box;
    .tc_datepicker__item--today &{border:var(--spacing-1) solid var(--color-300);}
    &[aria-current="true"]{background-color:var(--color-300);}
  }
  .tc_datepicker__time{text-align:center;
    .tc_timestamp{display:block;text-transform:lowercase;font-weight:var(--font-regular);font-size:var(--font-12);white-space:nowrap;}
  }
  .tc_datepicker__weekday{display:block;font-size:var(--font-14);font-weight:var(--font-bold);color:var(--color-text);white-space:nowrap;
    &::first-letter{text-transform:uppercase;}
  }
}

@include respond-to(hover) {
  .tc_datepicker__link:not([aria-current="true"]):hover{background-color:var(--color-100);}
}

@include respond-to(print) {
  .tc_datepicker{display:none;}
}
