// LOAD MORE
@import "core";

@include respond-to(base-inline) {
  .tc_load-more{display:flex;justify-content:center;}

  @media (scripting:none) {
    .tc_load-more{display:none;}
  }
}

@include respond-to(print) {
  .tc_load-more{display:none;}
}