// FEATURE HEADER
@import "core";

@include respond-to(base-inline) {
  .tc_feature__header{position:relative;overflow:hidden;text-align:center;background-color:var(--color-neutral-900);margin:0 calc(-50vw + 50%) var(--spacing-32);
    .tc_page__media{display:flex;flex-direction:column;justify-content:center;align-items:center;position:absolute;left:0;right:0;top:0;overflow:hidden;max-height:100vw;will-change:transform;
      .tc_image,.tc_video{flex:0 1 auto;width:100vw;
        &::before{content:"";position:absolute;left:0;right:0;bottom:0;top:0;z-index:2;background:linear-gradient(to top,var(--color-neutral-900) 0,var(--color-midnight-400) 40%);}
      }
    }
    .tc_heading{font-size:var(--font-32-48-60);color:var(--color-white);padding-bottom:var(--spacing-0-step);}
    .tc_standfirst{color:var(--color-white);}
    & + div .tc_page__meta{margin-bottom:calc(var(--spacing-8) * -1);}
  }
  .tc_feature__header__content{display:flex;flex-direction:column;align-items:center;gap:var(--spacing-16);position:relative;padding:calc(100% * 9 / 16) var(--spacing-24-step) var(--spacing-32-step);z-index:2;max-width:100vw;margin:0 auto;}
}

@include respond-to(medium-inline) {
  .tc_feature__header{height:100vh;max-height:80vw;
    .tc_standfirst{width:var(--main-medium);margin:0 auto;}
  }
  .tc_feature__header__content{position:absolute;left:0;right:0;bottom:0;}

  .tc_page__media{max-height:calc(100vw * 9 / 16);}
}

@include respond-to(large-inline) {
  :root{
    --font-32-48-60: max(3.2vw, calc(60 * var(--unit))); // scale font up when it's more than 60rem and more than 3.2vw
  }
  .tc_feature__header{max-height:70vw;
    .tc_heading + div .tc_byline{max-width:var(--main-medium);}
  }
  .tc_feature__header__content{max-width:max(50vw, var(--main-large));} // scale up width when it's more than 'large' and more than 50%
}

@include respond-to(print) {
  .tc_feature__header{
    .tc_page__media{transform:translateY(0)!important;opacity:1!important;} // override inline styles
    .tc_heading{font-size:var(--font-36);}
  }
  .tc_feature__header__content{text-align:center;}
}
