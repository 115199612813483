// FEATURE WRAPPER
@import "core";

@include respond-to(base-inline) {
  .tc_feature__wrapper{
    .tc_pageobject[data-object-position="full"]{margin:0 calc(-50vw + 50%);}
    .tc_richcontent{
      & > blockquote{
        &.js_page__body__effect{transform:translateY(-5rem);will-change:transform;opacity:0;transition:transform 1s,opacity 1s;
          &.js_page__body__effect--active{transform:translateY(0);opacity:1;}
        }
      }
    }
    [data-object-effects*="zoom"].js_page__body__effect{
      .tc_image__picture{overflow:hidden;}
      .tc_image__picture__img{transform:scale(1.1);opacity:.7;transition:transform 4s,opacity 4s;will-change:transform;}
      &.js_page__body__effect--active{
        .tc_image__picture__img{transform:scale(1);opacity:1;}
      }
    }
    .tc_page__footer__share{justify-content:center;}
  }
}

@include respond-to(large-inline) {
  .tc_feature__wrapper{
    .tc_pageobject[data-object-position="default"]{
      [data-embed-name="image"],[data-embed-name="video"],[data-embed-name="videoloop"]{margin-left:calc(-100 * var(--unit));margin-right:calc(-100 * var(--unit));}
    }
    .tc_pageobject[data-object-type="embed"]{
      &[data-object-position="left"]{
        [data-embed-name="image"],[data-embed-name="video"],[data-embed-name="videoloop"]{margin-left:calc(-100 * var(--unit));width:calc(400 * var(--unit));}
      }
      &[data-object-position="right"]{
        [data-embed-name="image"],[data-embed-name="video"],[data-embed-name="videoloop"]{margin-right:calc(-100 * var(--unit));width:calc(400 * var(--unit));}
      }
    }
  }
}

@include respond-to(print) {
  .tc_feature__wrapper{
    & > .tc_grid > .tc_byline{display:flex;justify-content:center;margin:var(--spacing-8) 0 var(--spacing-16);
      .tc_byline__prefix,.tc_byline__author{padding-right:var(--spacing-4);}
    }
    .tc_page__footer{display:none;}
  }
}
