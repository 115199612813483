// NEWSFEED BODY
@import "core";

@include respond-to(base-inline) {
  .tc_newsfeed__body{position:relative;overflow-anchor:none;
    & > .tc_button__group{margin-top:var(--spacing-40);}
  }
  .tc_newsfeed__body__ping{display:flex;align-items:center;
    p{font-size:var(--font-14);line-height:var(--line-110);}
    .tc_ping{height:var(--spacing-16);}
  }
  .tc_newsfeed__body__posts{scroll-margin-top:var(--spacing-112-step);display:flex;flex-direction:column;gap:var(--spacing-20);
    .tc_post__header__label{display:flex;position:relative;top:var(--spacing-8);}
    & > .tc_heading{position:relative;top:var(--spacing-8);}
    .tc_post{background-color:var(--color-light);padding:var(--spacing-16) 0 0;
      &::before{display:none;}
      .tc_post__header{position:static;padding:0 var(--spacing-16);}
      .tc_post__header__meta{gap:var(--spacing-8);padding:0 0 var(--spacing-8);color:var(--color-600);
        .tc_timestamp::before{display:none;}
        .tc_byline{position:relative;line-height:var(--line-140);margin-left:calc(var(--spacing-8));
          &::after{content:"";position:absolute;left:calc((var(--spacing-8) + var(--spacing-1)) * -1);width:var(--spacing-2);height:var(--spacing-16);background:var(--color-300);}
        }
      }
      .tc_post__body{background-color:transparent;}
    }
  }

  .tc_newsfeed__body__updates{position:sticky;z-index:10;top:var(--spacing-16);text-align:center;height:0;}
}

@include respond-to(base) {
  .tc_newsfeed__body__posts__up-to-date{position:relative;display:flex;flex-direction:column;align-items:center;padding:var(--spacing-32) 0;font-size:var(--font-18);
    strong{display:block;font-size:var(--font-20);font-weight:var(--font-bold);}
    &::before{content:"";width:var(--spacing-40);height:var(--spacing-40);background-color:var(--color-success);border-radius:50%;margin-bottom:var(--spacing-16);}
    &::after{content:"";position:absolute;top:var(--spacing-32);left:0;right:0;margin:0 auto;width:var(--spacing-40);height:var(--spacing-40);mask:url(../../../../icons/check.svg) no-repeat 50% 50%;mask-size:var(--spacing-24) auto;background-color:var(--color-white);}
  }
}

@include respond-to(print) {
  .tc_newsfeed__body__updates{display:none;}
  .tc_newsfeed__body__posts{
    + .tc_button__group{display:none;}
    .tc_post .tc_post__header__meta{display:flex;gap:var(--spacing-8);}
  }
}
