// FORM FIELDSET
@import "core";

@include respond-to(base-inline) {
  :where(.tc_form-fieldset){margin:0;padding:0;border:0;min-width:0;
    &[data-required="true"] > .tc_form-fieldset__legend span::after{content:" *";font-weight:var(--font-bold);}
    :where(.tc_form-fieldset .tc_form-fieldset__legend span){font-size:var(--font-16-18);}
  }
  :where(.tc_form-fieldset__legend){position:relative;display:block;width:100%;padding:0;
    span{display:block;font-size:var(--font-20-24);font-weight:var(--font-bold);padding-bottom:var(--spacing-16);line-height:var(--line-120);}
  }
  :where(.tc_form-fieldset__body){position:relative;display:flex;flex-direction:column;gap:var(--spacing-32);
    & > .tc_tooltip{position:absolute;z-index:1;right:0;top:calc(var(--spacing-48) * -1);color:var(--color-500);}
    & + .tc_form__error,& + .tc_form__help{position:static;}
    &:not(:last-child){padding-bottom:var(--spacing-20);}
  }
  :where(.tc_form-fieldset--row) > .tc_form-fieldset__body{flex-direction:row;gap:var(--spacing-16-jump);
    & > .tc_form__group{flex:1;}
  }
  :where(.tc_form-fieldset--with-info) > .tc_form-fieldset__legend span{padding-right:var(--spacing-32);}
  :where(.tc_form-fieldset--with-error > .tc_form-fieldset__body > .tc_form__group){
    .tc_form__radio,.tc_form__checkbox{
      & ~ .tc_form__label::before{border-color:var(--color-error);}
    }
  }
}

@include respond-to(print) {
  .tc_form-fieldset{margin:0;padding:0;border:0;min-width:0;
    .tc_form-fieldset{padding:0 0 var(--spacing-20);}
  }
  .tc_form-fieldset__legend{padding:0;font-weight:var(--font-bold);}
}
