// TV LISTING
@import "core";

@include respond-to(base-inline) {
  .tc_tvlisting{display:flex;flex-direction:column;max-width:var(--main-medium);
    &[data-ended="true"] .tc_tvlisting__main{
      .tc_heading,.tc_tvlisting__info{opacity:.6;}
    }
    &[data-cancelled="true"] .tc_tvlisting__info{
      strong{color:var(--color-error);display:inline-block;}
      span + strong{
        &::before{content:"(";padding-left:var(--spacing-4);}
        &::after{content:")";}
      }
    }
    .tc_timestamp{font-size:var(--font-12);}
  }
  .tc_tvlisting__main{flex:1;position:relative;display:flex;flex-direction:column;gap:var(--spacing-4);padding:var(--spacing-16);background-color:var(--color-100);
    .tc_heading{font-size:var(--font-14-16);
      span{display:block;}
    }
  }
  .tc_tvlisting__label{display:flex;padding:0 var(--spacing-80) var(--spacing-8) 0;}
  .tc_tvlisting__info{font-size:var(--font-12);line-height:var(--line-120);font-weight:var(--font-medium);

  }
  .tc_tvlisting__progress{display:block;width:100%;height:var(--spacing-4);appearance:none;border:none;background-color:var(--color-400);border-radius:var(--spacing-4);margin-top:var(--spacing-4);margin-bottom:var(--spacing-4-step);
    &::-webkit-progress-bar{background-color:var(--color-300);border-radius:var(--spacing-4);}
    &::-webkit-progress-value{background-color:var(--color-dark);border-radius:var(--spacing-4);}
    &::-moz-progress-bar{background-color:var(--color-dark);border-radius:var(--spacing-4);}
  }
  .tc_tvlisting__channel{position:absolute;top:var(--spacing-16);right:var(--spacing-16);width:calc(66 * var(--unit));background-size:0 0;
    img{height:var(--spacing-24);}
  }
  .tc_tvlisting__footer{display:flex;align-items:center;gap:var(--spacing-8-step);padding-top:var(--spacing-4);
    p{color:var(--color-600);font-size:var(--font-12);line-height:var(--line-120);}
    .tc_button__group{margin-left:auto;}
  }

  // selector is true if user has overriden the OS setting on tv2.dk with light/darkmode (using the settings switch)
  :root:has(meta[name="color-scheme"][content="light"]) .tc_tvlisting__channel{background:none!important;}
  :root:has(meta[name="color-scheme"][content="dark"]) .tc_tvlisting__channel{background-repeat:no-repeat;background-size:contain;
    img{opacity:0;}
  }

  @media (prefers-color-scheme:light) {
    // selector is true if user has set lightmode on OS level (but only if it's supported - ie. "light" is present in the metatag)
    :root:has(meta[name="color-scheme"][content*="light"]) .tc_tvlisting__channel{background:none!important;}
  }

  @media (prefers-color-scheme:dark) {
    // selector is true if user has set darkmode on OS level (but only if it's supported - ie. "dark" is present in the metatag)
    :root:has(meta[name="color-scheme"][content*="dark"]) .tc_tvlisting__channel{background-repeat:no-repeat;background-size:contain;
      img{opacity:0;}
    }
  }
}

@include respond-to(base) {
  [data-national-attendance=true]::after{content:"";background:url(https://flags.tv2a.dk/iso-3166/alpha-2/dk.svg) no-repeat 0 50%;background-size:auto var(--spacing-12);width:var(--spacing-32);margin-left:var(--spacing-8);}
}

@include respond-to(print) {
  .tc_tvlisting{position:relative;border:var(--spacing-2) solid var(--color-black);padding:var(--spacing-8);margin-bottom:var(--spacing-20);}
  .tc_tvlisting__channel{position:absolute;top:var(--spacing-8);right:var(--spacing-8);
    img{height:var(--spacing-32);}
  }
  .tc_tvlisting__footer{font-size:var(--font-16);font-style:italic;font-variation-settings:'slnt' 10, 'ital' 1;}
}
