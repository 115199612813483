// RESET
@import "core";

@include respond-to(base-inline) {
  :where(main,aside,img,video,iframe,picture,details,summary){display:block;}
  :where([tabindex],a,button,input,label,select,summary,textarea){touch-action:manipulation;
    &:focus-visible{outline:var(--spacing-2) solid var(--color-focus);outline-offset:var(--spacing-2);}
  }
  :where(summary)::-webkit-details-marker{display:none;} // safari only
  :where(ul,ol,li,p){margin:0;padding:0;}
  :where(h1,h2,h3,h4,h5,h6,figure){margin:0;}
  :where(img,iframe){border:0;}
  :where(svg){display:block;overflow:visible;}
  :where(svg:not(.sb_icon-list svg)){fill:currentColor;}
  :where([popover]){padding:0;border:0;}
  :where(button)::-moz-focus-inner{border:0;padding:0;}
  :where(input)::-webkit-outer-spin-button,:where(input)::-webkit-inner-spin-button{-webkit-appearance:none;margin:0;}
  :where(input[type=number]){appearance:textfield;}
  .sr-only{position:absolute!important;width:var(--spacing-1)!important;height:var(--spacing-1)!important;overflow:hidden;white-space:nowrap;clip:rect(0 0 0 0);clip-path:inset(50%);}
  [hidden]{display:none!important;}
}

@include respond-to(print) {
  :where(main,aside,img,details,summary){display:block;}
  :where(summary)::-webkit-details-marker{display:none;} // safari only
  :where(h1,h2,h3,h4,h5,h6,figure){margin:0;}
  :where(svg){display:block;overflow:visible;fill:currentColor;}
  :where(p){margin:0 0 var(--spacing-20);}
  .sr-only,[hidden]{display:none!important;}
}
