// TOOLTIP
@import "core";

@include respond-to(base-inline) {
  :where(.tc_tooltip){position:relative;z-index:1;display:inline-flex;justify-content:center;align-items:center;font-size:var(--font-14);text-align:left;white-space:normal;color:var(--color-text);border-radius:50%;width:var(--spacing-40);height:var(--spacing-40);border:0;padding:0;background:transparent;
    svg{width:var(--spacing-24);height:var(--spacing-24);}
    [role="tooltip"]{opacity:0;pointer-events:none;align-self:flex-start;position:absolute;top:var(--spacing-48);z-index:5;width:max-content;min-width:var(--spacing-48);max-width:calc(var(--spacing-128) * 2);background:var(--color-200);padding:var(--spacing-4) var(--spacing-8);color:var(--color-text);overflow-wrap:break-word;}
    &::before{content:"";position:absolute;top:var(--spacing-40);left:0;right:0;margin:0 auto;z-index:3;width:var(--spacing-16);height:var(--spacing-16);background:var(--color-200);transform:rotate(45deg);opacity:0;pointer-events:none;}
    &:focus-visible{outline-offset:calc(var(--spacing-8) * -1);
      [role="tooltip"]{opacity:1;}
      &::before{opacity:1;}
    }
    &[data-position="left"]{
      [role="tooltip"]{right:var(--spacing-48);top:var(--spacing-8);}
      &::before{top:var(--spacing-12);left:calc(var(--spacing-64) * -1);}
    }
    &[data-position="right"]{
      [role="tooltip"]{left:var(--spacing-48);top:var(--spacing-8);}
      &::before{top:var(--spacing-12);right:calc(var(--spacing-64) * -1);}
    }
    &[data-position="top"]{
      [role="tooltip"]{bottom:var(--spacing-56);top:auto;}
      &::before{top:calc(var(--spacing-24) * -1);}
    }
  }
  :where(.tc_tooltip):hover{
    [role="tooltip"],&::before{opacity:1;}
  }
  :where(.tc_tooltip){
    [role="tooltip"]:hover,&::before:hover{opacity:1;}
  }
}

@include respond-to(hover) {

}

@include respond-to(print) {
  .tc_tooltip{display:none;}
}