// FORM LABEL
@import "core";

@include respond-to(base-inline) {
  // handle labels in general if no :has-support
  @supports not selector(:has(div)) {
    :where(.tc_form__label:first-child,.tc_form__svg + .tc_form__label){position:absolute;z-index:1;height:var(--form-input-label-height);left:calc(var(--form-input-label-padding) + var(--spacing-1));top:0;bottom:0;margin:var(--form-input-label-padding) 0 0;color:var(--color-600);transform-origin:0 0;cursor:text;font-size:var(--form-input-font-size);line-height:1.5;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;-webkit-user-select:none;user-select:none;text-align:left;pointer-events:none;transform:scale(calc(var(--form-input-label-scale) / 100)) translateY(-50%);width:calc((100% - (2 * var(--form-input-label-padding))) * (100 / var(--form-input-label-scale)));}
  }
}

@include respond-to(print) {
  .tc_form__group{padding-bottom:var(--spacing-12);
    &:has(.tc_form__label + input, .tc_form__label + select, .tc_form__label + textarea) .tc_form__label{display:block;}
  }
}
