// MATCH REPORT
@import "core";

@include respond-to(base-inline) {
  .tc_matchreport{
    &[data-match-type="handball"]{
      .tc_matchreport__main__datatable--topscorer caption::before{content:"";background-image:url("https://gfx.tv2a.dk/sportsdata-services/handball/icons/goal.svg");}
      .tc_matchreport__main__datatable--sentoff caption::before{content:"";background-image:url("https://gfx.tv2a.dk/sportsdata-services/handball/icons/twomin.svg");}
    }
    &[data-match-type="football"]{
      .tc_matchreport__main__datatable--topscorer caption::before{content:"";background-image:url("https://gfx.tv2a.dk/sportsdata-services/football/icons/goal.svg");}
      .tc_matchreport__main__datatable--sentoff caption::before{content:"";background-image:url("https://gfx.tv2a.dk/sportsdata-services/football/icons/redcard.svg");}
    }
  }
  .tc_matchreport__main{background-color:var(--color-light);padding:calc(var(--spacing-8) + var(--spacing-4-step)) var(--spacing-8);border:var(--spacing-8) solid var(--color-100);border-top:0;}
  .tc_matchreport__main__football{background-color:var(--color-light);border:var(--spacing-8) solid var(--color-100);border-top:0;}

  .tc_matchreport__main__datatable{position:relative;overflow:hidden;background:var(--color-light);
    &:not(:first-child){border-top:var(--spacing-1) solid var(--color-300);padding-top:calc(var(--spacing-8) + var(--spacing-4-step));margin-top:var(--spacing-16);}
    table{width:100%;display:flex;flex-direction:column;font-size:var(--font-16);}
    caption{display:flex;flex-direction:column;align-items:center;
      &::before{width:var(--spacing-32);height:var(--spacing-32);background-repeat:no-repeat;background-position:50% 50%;background-size:70% auto;}
    }
    tbody{display:flex;justify-content:space-between;margin-top:calc(var(--spacing-20) * -1);
      tr{display:flex;flex-direction:column-reverse;justify-content:flex-end;text-align:left;max-width:50%;
        &:last-child{text-align:right;
          td{padding:0 0 0 var(--spacing-16);}
        }
      }
      td{padding:0 var(--spacing-16) 0 0;}
      span{display:block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;font-size:var(--font-14);}
    }
  }

  .tc_matchreport__footer{background-color:var(--color-100);padding-bottom:var(--spacing-8);}
}

@include respond-to(print) {
  .tc_matchreport{border:var(--spacing-2) solid var(--color-black);padding:var(--spacing-8);}
}
