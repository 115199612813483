// RECIPE BODY
@import "core";

@include respond-to(base-inline) {
  .tc_recipe__body{
    &.tc_grid{row-gap:var(--spacing-24-step);}
    .tc_ingredients{padding:var(--spacing-8) 0;}
    .tc_richcontent ol li{padding-left:var(--spacing-4);padding-bottom:var(--spacing-20);}
  }
}

@include respond-to(large-inline) {
  .tc_recipe__body{
    & > .tc_grid{
      & > :first-child{grid-row:1;grid-column:2 / 4;}
      & > :last-child{grid-row:1;grid-column:1 / 2;}
    }
  }
}

@include respond-to(print) {
  .tc_recipe__body{
    .tc_richcontent ol li{padding-bottom:var(--spacing-20);}
  }
}
