// EMBED TOOLS SMS
@import "core";

@include respond-to(base) {
  :root{
    --color-sms: #3277c7;
  }
  .tc_tools__sms{max-width:var(--main-medium);margin:0 auto;background-color:var(--color-light);}
  .tc_tools__sms__list{list-style:none;max-width:var(--bp-small);margin:auto;padding:var(--spacing-32) var(--spacing-1);}
  .tc_tools__sms__message{display:flex;flex-direction:column;padding:0 var(--spacing-8-step) var(--spacing-20);
    .tc_timestamp{text-align:center;padding:var(--spacing-4) 0 var(--spacing-12);}
    .tc_image__picture__img,.tc_video__video,.tc_video__video > *{border-radius:var(--spacing-12);}
    .tv2-player__placeholder__video-info{transform:scale(.5);transform-origin:0 100%;width:calc(200%);}
    .tv2-player__placeholder__play-button{transform:scale(.5);}
    &[data-sms-from="Afsender"]{
      .tc_tools__sms__message__body{margin-left:auto;background:var(--color-sms);color:var(--color-white);
        &::before{right:calc((var(--spacing-4) + var(--spacing-1))* -1);background:var(--color-sms);border-bottom-left-radius:var(--spacing-16);}
        &::after{right:calc((var(--spacing-8) + var(--spacing-2)) * -1);background:var(--color-light);border-bottom-left-radius:var(--spacing-8);}
      }
      .tc_tools__sms__message__media{margin-left:auto;}
      .tc_tools__sms__message__name{text-align:right;}
    }
    &[data-sms-from="Modtager"]{
      .tc_tools__sms__message__body{margin-right:auto;background:var(--color-200);color:var(--color-text);
        &::before{left:calc(var(--spacing-4) * -1);background-color:var(--color-200);border-bottom-right-radius:var(--spacing-16);}
        &::after{left:calc((var(--spacing-8) + var(--spacing-2)) * -1);background:var(--color-light);border-bottom-right-radius:var(--spacing-8);}
      }
    }
  }
  .tc_tools__sms__message__body{position:relative;max-width:min(80%,calc(258 * var(--static-unit)));padding:var(--spacing-8) var(--spacing-12);border-radius:var(--spacing-16);line-height:var(--line-120);font-size:var(--font-16);
    a{color:inherit;text-decoration:underline;}
    &::before{content:"";position:absolute;bottom:0;height:var(--spacing-16);width:var(--spacing-16);}
    &::after{content:"";position:absolute;bottom:0;width:calc(var(--spacing-8) + var(--spacing-2));height:var(--spacing-16);}
  }
  .tc_tools__sms__message__media{position:relative;width:min(80%,calc(276 * var(--static-unit)));
    & + *{margin-top:var(--spacing-8);}
  }
  .tc_tools__sms__message__media__credit{font-size:var(--font-12);color:var(--color-700);padding:0 var(--spacing-8);text-align:right;}
  .tc_tools__sms__message__date{padding-right:var(--spacing-4);}
  .tc_tools__sms__message__name{margin-left:var(--spacing-8);margin-right:var(--spacing-8);font-size:var(--font-14);color:var(--color-700);}
}

@include respond-to(print) {
  .tc_tools__sms__list{list-style:none;margin:0 var(--spacing-20);padding:0;font-size:var(--font-16);}
  .tc_tools__sms__message{display:flex;flex-direction:column;margin-bottom:var(--spacing-20);
    .tc_timestamp{align-self:center;}
    div,p{width:60%;}
  }
  .tc_tools__sms__message__body{padding:var(--spacing-8);border:var(--spacing-2) solid var(--color-black);border-radius:var(--spacing-8);}
  .tc_tools__sms__message__media{padding-bottom:var(--spacing-4);}
  [data-sms-from="Afsender"]{align-items:flex-end;
    .tc_tools__sms__message__name{text-align:right;}
  }
}
