// WEATHER SEARCH
@import "core";

@include respond-to(base-inline) {
  .tc_weather__search{
    .tc_form__autocomplete{padding-bottom:0;}
    .tc_weather__search__autocomplete__item{display:flex;flex-direction:column;padding-left:var(--spacing-32);background-repeat:no-repeat;background-position:0 calc(var(--spacing-4) * 1.5);background-size:var(--spacing-24) auto;
      strong{font-size:var(--font-14);text-overflow:ellipsis;overflow:hidden;white-space:nowrap;}
      span{font-size:var(--font-12);line-height:var(--line-120);color:var(--color-700);text-overflow:ellipsis;overflow:hidden;white-space:nowrap;padding-bottom:var(--spacing-2);}
    }
  }
  .tc_weather__search__history{display:flex;flex-wrap:wrap;gap:var(--spacing-16);row-gap:var(--spacing-4);font-size:var(--font-14);color:var(--color-700);margin:var(--spacing-16) 0 0;
    dt{white-space:nowrap;}
    dd{white-space:nowrap;margin:0;overflow:hidden;text-overflow:ellipsis;}
    &:not(:has(dd)){visibility:hidden;}
  }
  .tc_weather__search__footer{font-size:var(--font-12);text-align:right;color:var(--color-600);padding-top:var(--spacing-16);}
}

@include respond-to(medium-inline) {
  .tc_weather__search{
    .tc_form__autocomplete{max-width:calc(656 * var(--unit));padding-top:var(--spacing-8);}
  }
}

@include respond-to(print) {
  .tc_weather__search{display:none;}
}
