// CANDIDATE SUMMARY
@import "core";

@include respond-to(base-inline) {
  .tc_candidate-summary{background-color:var(--color-light);
    .tc_image{width:var(--spacing-64-step);margin-bottom:auto;}
  }
  .tc_candidate-summary__elected{display:flex;justify-content:space-between;background-color:var(--color-100);padding:var(--spacing-8) var(--spacing-8-step);
    strong{font-size:var(--font-16-18);font-weight:var(--font-bold);}
    &::after{content:attr(data-elected-type);}
  }
  .tc_candidate-summary__info{position:relative;display:flex;align-items:center;column-gap:var(--spacing-8-step);padding:var(--spacing-16) 0;color:var(--color-midnight);text-decoration:none;border-bottom:var(--spacing-1) solid var(--color-300);
    &[href]{padding-right:var(--spacing-40-step);
      &::after{content:"";position:absolute;right:0;top:0;bottom:0;width:var(--spacing-40-step);mask:url("inline!../../../../../../icons/chevron-right.svg") no-repeat top 50% right var(--spacing-20);mask-size:auto var(--spacing-20);background-color:var(--color-dark);}
    }
    .tc_candidate-summary__info__basic .tc_heading{margin:0;padding:0 0 var(--spacing-4);}
    .tc_image--circular + .tc_candidate-summary__info__basic [data-candidate-party]::before{border-radius:0;}
  }
  .tc_candidate-summary__info__basic{flex:1;display:flex;flex-direction:column;}
  .tc_candidate-summary__info__basic__name .tc_heading{font-size:var(--font-16-18);}
  .tc_candidate-summary__info__basic__name__votes{font-size:var(--font-14);line-height:var(--line-140);padding:0 0 var(--spacing-4);}

  [data-candidate-party]{display:flex;font-size:var(--font-12-14);line-height:var(--line-110);align-items:center;padding:0 0 var(--spacing-4-step);
    &::before{content:attr(data-candidate-party);position:relative;display:flex;justify-content:center;align-items:center;box-sizing:border-box;min-width:var(--spacing-16-step);max-width:var(--spacing-16-step);height:var(--spacing-16-step);margin-right:var(--spacing-4);background-color:var(--color-election-unknown);color:var(--color-white);text-transform:uppercase;font-size:var(--font-10-14);font-weight:var(--font-bold);border-radius:50%;}
  }
  [data-candidate-party="a"]::before{background-color:var(--color-election-a);}
  [data-candidate-party="b"]::before{background-color:var(--color-election-b);}
  [data-candidate-party="c"]::before{background-color:var(--color-election-c);}
  [data-candidate-party="d"]::before{background-color:var(--color-election-d);}
  [data-candidate-party="f"]::before{background-color:var(--color-election-f);}
  [data-candidate-party="i"]::before{background-color:var(--color-election-i);}
  [data-candidate-party="k"]::before{background-color:var(--color-election-k);}
  [data-candidate-party="m"]::before{background-color:var(--color-election-m);}
  [data-candidate-party="o"]::before{background-color:var(--color-election-o);}
  [data-candidate-party="q"]::before{background-color:var(--color-election-q);}
  [data-candidate-party="v"]::before{background-color:var(--color-election-v);}
  [data-candidate-party="æ"]::before{background-color:var(--color-election-æ);}
  [data-candidate-party="ø"]::before{background-color:var(--color-election-ø);}
  [data-candidate-party="å"]::before{background-color:var(--color-election-å);}

  .tc_candidate-summary__info__basic__match{display:flex;align-items:center;font-size:var(--font-12-14);line-height:var(--line-110);
    strong{font-size:var(--font-16-18);font-weight:var(--font-bold);padding-right:var(--spacing-4);}
  }

  .tc_candidate-summary__elected-type{font-size:var(--font-14);font-weight:var(--font-bold);padding-right:var(--spacing-4);padding-bottom: var(--spacing-4);}

  .tc_candidate-summary[data-layout="large"]{
    .tc_image{width:var(--spacing-96);}

    .tc_candidate-summary__elected{padding:var(--spacing-8-step);
    }

    .tc_candidate-summary__info{padding:var(--spacing-24-step) 0;
      .tc_candidate-summary__info__basic .tc_heading{padding:0 0 var(--spacing-8);}
    }

    .tc_candidate-summary__info__basic{position:relative;top:calc(var(--spacing-4) * -1);}

    .tc_candidate-summary__info__basic__match{
      span::after{content:" med dig";}
      strong{font-size:var(--font-20-24);}
    }
  }

  .tc_candidate-summary[data-layout="card"]{background-color:var(--color-election-1, var(--color-100));color-scheme:light;
    .tc_image{width:var(--spacing-112);overflow:hidden;
      &[data-image]::after{content:attr(data-image);background-color:var(--color-900);color:var(--color-light);position:absolute;bottom:0;display:inline-flex;align-items:center;font-size:var(--font-12);font-weight:var(--font-bold);line-height:var(--line-110);height:var(--spacing-20);padding:0 var(--spacing-8);overflow:hidden;white-space:nowrap;text-decoration:none;}
    }
    .tc_candidate-summary__info{align-items:flex-start;background:inherit;padding:var(--spacing-8);border:0;column-gap:var(--spacing-8);}
    .tc_candidate-summary__info__basic__name{
      .tc_heading{font-size:var(--font-16);}
      [data-candidate-party]{padding:0;font-size:var(--font-14);
        &::before{margin-right:var(--spacing-4);}
      }
    }
    .tc_candidate-summary__info__basic__name__votes{font-size:var(--font-14);padding-bottom:var(--spacing-8-step);}
  }
}

@include respond-to(medium-inline) {
  .tc_candidate-summary__info__basic{flex-direction:row;}
  .tc_candidate-summary__info__basic__match{min-width:calc(130 * var(--unit));padding:0 var(--spacing-16);margin-left:auto;}

  .tc_candidate-summary[data-layout="large"]{
    .tc_image{width:calc(140 * var(--unit));}
    .tc_candidate-summary__info__basic{flex-direction:column;}
    .tc_candidate-summary__info__basic__match{min-width:auto;padding:0;margin-left:0;}
  }
}

@include respond-to(print) {
  .tc_candidate-summary{border:var(--spacing-2) solid var(--color-black);padding:var(--spacing-8);margin:0 0 var(--spacing-16);}
  .tc_candidate-summary__info{display:flex;gap:var(--spacing-16);
    .tc_image{width:var(--spacing-64);margin:0;}
  }
  .tc_candidate-summary__info__basic{flex:1;display:flex;justify-content:space-between;align-items:center;}
}
