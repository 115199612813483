// WEATHER FORECAST LIST
@import "core";

@include respond-to(base-inline) {
  .tc_weather__forecast__list__wrap{background:var(--color-light);
    &[data-direction="vertical"]{display:block;
      .tc_weather__forecast__list{
        caption{width:var(--spacing-1)!important;height:var(--spacing-1)!important;clip:rect(0 0 0 0);clip-path:inset(50%);}
        tbody{
          th,td{padding-top:var(--spacing-12);padding-bottom:var(--spacing-12);}
          .tc_weather__forecast__list__time{min-width:var(--spacing-56);padding:0;text-align:left;
            span{display:block;white-space:nowrap;}
          }
          .tc_weather__forecast__list__symbol{min-width:var(--spacing-40);padding-right:0;}
          .tc_weather__forecast__list__temperature{min-width:var(--spacing-32);padding-left:0;padding-right:var(--spacing-32-step);text-align:right;}
          .tc_weather__forecast__list__temperature__night{min-width:var(--spacing-24-step);font-size:var(--font-14);font-weight:var(--font-regular);color:var(--color-500);padding-left:var(--spacing-4);padding-right:var(--spacing-4);text-align:left;}
          .tc_weather__forecast__list__precipitation{width:100%;text-align:left;
            img{position:relative;top:var(--spacing-1);margin-right:calc(var(--spacing-4-step) / 2);}
          }
          .tc_weather__forecast__list__precipitation__probability{padding-left:var(--spacing-8);}
          .tc_weather__forecast__list__wind{min-width:var(--spacing-56-step);padding-right:0;text-align:right;white-space:nowrap;}
          .tc_weather__forecast__list__toggle{padding-right:0;}

          .tc_weather__forecast__list__row--has-toggle{background:var(--color-light);
            .tc_weather__forecast__list__temperature{padding-right:0;}
            & ~ .tc_weather__forecast__list__row--sublist{background-image:linear-gradient(var(--color-200), transparent 80%);background-size:100% var(--spacing-12);background-repeat:no-repeat;
              th:first-child{position:relative;
                &::before{content:"";display:block;width:var(--spacing-24);height:var(--spacing-12);position:absolute;left:0;top:0;background:linear-gradient(to right, var(--color-light), transparent 60%);}
              }
              td:last-child{position:relative;
                &::after{content:"";display:block;width:var(--spacing-24);height:var(--spacing-12);position:absolute;right:0;top:0;background:linear-gradient(to left, var(--color-light), transparent 60%);}
              }
              & + .tc_weather__forecast__list__row--sublist{background:transparent;
                th:first-child::before,td:last-child::after{display:none;}
              }
            }
            &[data-toggle-active="true"] th{font-weight:var(--font-medium);color:var(--color-text);}
          }
        }
      }
    }
    &[data-direction="horizontal"]{display:flex;position:relative;overflow-x:scroll;
      .tc_weather__forecast__list{padding-bottom:var(--spacing-12);overflow:visible;
        &:not(:last-child) tr:last-child{border-right:var(--spacing-2) solid var(--color-100);}
        caption{position:sticky;width:var(--spacing-64);padding:var(--spacing-8) var(--spacing-8) 0;margin-bottom:calc(var(--spacing-24) * -1);}
        thead{position:absolute!important;width:var(--spacing-1)!important;height:var(--spacing-1)!important;overflow:hidden;white-space:nowrap;clip:rect(0 0 0 0);clip-path:inset(50%);}
        tbody{display:flex;gap:var(--spacing-8);padding:0;
          tr{display:block;width:var(--spacing-64);margin-top:var(--spacing-20);}
          th,td{display:block;border:0;height:auto;padding:var(--spacing-4) var(--spacing-4-step);text-align:center;}
          .tc_weather__forecast__list__time{padding-top:var(--spacing-24);padding-bottom:var(--spacing-8);}
          .tc_weather__forecast__list__symbol{padding-bottom:0;
            img{margin:0 auto;}
          }
          .tc_weather__forecast__list__temperature{padding-top:0;height:var(--spacing-24);line-height:var(--spacing-24);}
          .tc_weather__forecast__list__precipitation{padding:var(--spacing-12) 0 var(--spacing-4);line-height:var(--spacing-16);height:var(--spacing-40);white-space:nowrap;
            img{width:calc(var(--spacing-12) + var(--spacing-4-step));height:calc(var(--spacing-12) + var(--spacing-4-step));}
          }
          .tc_weather__forecast__list__precipitation__probability{display:block;padding-top:var(--spacing-4);}
          .tc_weather__forecast__list__wind__speed span{position:absolute;width:var(--spacing-1);height:var(--spacing-1);overflow:hidden;white-space:nowrap;clip:rect(0 0 0 0);clip-path:inset(50%);}
        }
      }
    }
  }
  .tc_weather__forecast__list{font-size:var(--font-12);padding-bottom:var(--spacing-8);
    .tc_weather__forecast__list__time{font-size:var(--font-14);}
    .tc_weather__forecast__list__symbol{
      img{width:var(--spacing-40);height:var(--spacing-40);}
    }
    .tc_weather__forecast__list__temperature{font-size:var(--font-16);font-weight:var(--font-medium);color:var(--color-text);}
    .tc_weather__forecast__list__precipitation__probability{color:var(--color-link);min-height:var(--spacing-16);line-height:var(--spacing-16);}
    .tc_weather__forecast__list__wind--high{color:var(--color-error);
      svg{color:var(--color-error);}
    }
  }
  .tc_weather__forecast__list__precipitation{white-space:nowrap;
    img{display:inline-block;vertical-align:calc(var(--spacing-3) * -1);}
  }
  .tc_weather__forecast__list__wind{
    svg{display:inline-block;vertical-align:text-bottom;color:var(--color-600);}
  }
  .tc_weather__forecast__list__wind__speed{padding-left:var(--spacing-4);}
  .tc_weather__forecast__list__toggle{min-width:var(--spacing-40);
    .tc_button{
      &[aria-expanded="true"]{transform:rotate(180deg);}
    }
  }

  .tc_weather__forecast__widget + [data-bundle="WeatherForecastList"] .tc_datatable{border-top:var(--spacing-1) solid var(--color-300);}

  @media (scripting:none) {
    .tc_weather__forecast__list__toggle .tc_button{opacity:0;}
  }
}

@include respond-to(small-inline) {
  .tc_weather__forecast__list__wrap{
    &[data-direction="horizontal"]{padding:0 var(--spacing-8);}
    &[data-direction="vertical"]{
      .tc_weather__forecast__list{
        tbody{
          .tc_weather__forecast__list__time{padding-right:var(--spacing-32-step);}
          .tc_weather__forecast__list__temperature{font-size:var(--font-18);padding-left:var(--spacing-8);}
          .tc_weather__forecast__list__precipitation{padding-left:var(--spacing-32-jump);}
          .tc_weather__forecast__list__row--has-toggle .tc_weather__forecast__list__time{font-size:var(--font-14);}
        }
      }
    }
  }
}

@include respond-to(medium-inline) {
  .tc_weather__forecast__list__wrap[data-direction="vertical"]{
    .tc_weather__forecast__list{
      tbody{
        .tc_weather__forecast__list__time{padding-left:var(--spacing-16);}
        .tc_weather__forecast__list__temperature{padding-left:var(--spacing-64);}
        .tc_weather__forecast__list__precipitation{font-size:var(--font-14);
          img{top:var(--spacing-2);width:var(--spacing-20);height:var(--spacing-20);}
        }
        .tc_weather__forecast__list__wind{font-size:var(--font-14);padding-right:var(--spacing-16);}
      }
    }
    &.tc_weather__forecast__list--horizontal{padding-bottom:var(--spacing-8);
      tbody{padding:0;
        tr{width:var(--spacing-72);}
      }
    }
  }
}

@include respond-to(large-inline) {
  .tc_weather__forecast__list__wrap{
    &[data-direction="vertical"]{
      .tc_weather__forecast__list{
        tbody{padding:0;
          tr{width:var(--spacing-64);}
          .tc_weather__forecast__list__time{padding-top:var(--spacing-8);}
        }
      }
    }
  }
}

@include respond-to(print) {
  .tc_weather__forecast__list__toggle{display:none;}
}
