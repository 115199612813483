// INCIDENTS
@import "core";

@include respond-to(base-inline) {
  .tc_incidents{max-width:var(--main-medium);background:var(--color-100);padding-top:var(--spacing-8);padding-bottom:var(--spacing-24);}
  .tc_incidents__list{position:relative;list-style:none;display:flex;flex-direction:column;gap:var(--spacing-16-step);padding:var(--spacing-8) 0;font-size:var(--font-14);line-height:var(--line-120);margin-bottom:var(--spacing-8);background-color:inherit;
    &::before{content:"";position:absolute;top:var(--spacing-8);bottom:var(--spacing-8);left:50%;width:var(--spacing-1);background-color:var(--color-300);}
  }
  .tc_incidents__list__event{position:relative;z-index:1;display:flex;justify-content:center;align-items:center;gap:var(--spacing-16);background-color:inherit;min-height:var(--spacing-32);
    span{flex:1 1 auto;width:calc(50% - var(--spacing-20));min-height:var(--spacing-20);}
    img{margin:0 auto;width:var(--spacing-20);}
    .tc_incidents__list__event__elapsed{font-size:var(--font-14);}
    .tc_incidents__list__event__type{width:var(--spacing-20);padding:0;background:inherit;}
    .tc_incidents__list__event__title{font-weight:var(--font-bold);overflow:hidden;
      small{display:block;color:var(--color-700);font-size:var(--font-12);font-weight:var(--font-regular);}
    }
    &[data-team="home"]{flex-direction:row-reverse;
      .tc_incidents__list__event__title{text-align:right;}
    }
    &[data-team="away"]{
      .tc_incidents__list__event__elapsed{text-align:right;}
    }
    &[data-type="time"]{
      .tc_incidents__list__event__elapsed,.tc_incidents__list__event__type{display:none;}
      .tc_incidents__list__event__title{font-weight:var(--font-regular);text-align:center;padding:var(--spacing-8) 0;background:inherit;
        small{display:none;}
      }
    }
  }

  // selector is true if user has overriden the OS setting on tv2.dk with darkmode (using the settings switch)
  :root:has(meta[name="color-scheme"][content="dark"]) .tc_incidents{
    [data-type="goal"] img,[data-type="whistle"] img{filter:invert(1) brightness(150%);}
    [data-type="substitution"] img{filter:brightness(130%);}
  }

  @media (prefers-color-scheme:dark) {
    // selector is true if user has set darkmode on OS level (but only if it's supported - ie. "dark" is present in the metatag)
    :root:has(meta[name="color-scheme"][content*="dark"]) .tc_incidents{
      [data-type="goal"] img,[data-type="whistle"] img{filter:invert(1) brightness(150%);}
      [data-type="substitution"] img{filter:brightness(130%);}
    }
  }
}

@include respond-to(print) {
  .tc_incidents{padding:var(--spacing-8);border:var(--spacing-2) solid var(--color-black);font-size:var(--font-16);}
  .tc_incidents__list{display:flex;flex-direction:column;gap:var(--spacing-16);list-style:none;margin:0;padding:0;}
  .tc_incidents__list__event{display:flex;justify-content:center;align-items:center;gap:var(--spacing-12);
    &[data-team="home"]{flex-direction:row-reverse;
      .tc_incidents__list__event__title{text-align:right;}
    }
    &[data-team="away"]{
      .tc_incidents__list__event__elapsed{text-align:right;}
    }
    &[data-type="time"]{
      .tc_incidents__list__event__title{text-align:center;}
      .tc_incidents__list__event__elapsed,.tc_incidents__list__event__type{display:none;}
    }
  }
  .tc_incidents__list__event__type{width:var(--spacing-24);
    img{width:var(--spacing-24);}
  }
  .tc_incidents__list__event__elapsed,.tc_incidents__list__event__title{width:calc(50% - var(--spacing-12));}
  .tc_incidents__list__event__title small{display:block;}
}
