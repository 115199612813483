// EMBED TOOLS LIST VOTING
@import "core";

@include respond-to(base) {
  .tc_tools__list-voting{counter-reset:item;list-style:none;
    &[reversed]{
      .tc_tools__list-voting__item{
        &::before{counter-increment:item -1;}
        &:nth-child(-n+9)::before{content:counter(item);}
        &:nth-last-child(-n+9)::before{content:'0'counter(item);}
      }
    }
  }
  .tc_tools__list-voting__item{position:relative;background-color:var(--color-light);padding:var(--spacing-16) var(--spacing-8-step) var(--spacing-16) calc(var(--spacing-64-jump) - var(--spacing-8-step));margin-bottom:var(--spacing-4);min-height:calc(var(--spacing-64-jump) + var(--spacing-64));
    &::before{content:counter(item);counter-increment:item;color:var(--color-800);font-size:var(--font-24-28);font-weight:var(--font-bold);position:absolute;top:var(--spacing-8);left:0;width:calc(var(--spacing-64-jump) - var(--spacing-8-step));text-align:center;}
    &:nth-child(-n+9)::before{content:'0'counter(item);}
    .tc_heading{padding-bottom:var(--spacing-8);}
    .tc_image,.tc_video{padding-bottom:var(--spacing-8-step);}
  }
  .tc_tools__list-voting__item__vote{position:absolute;top:calc(var(--spacing-32-step) + var(--spacing-16));left:0;width:calc(var(--spacing-64-jump) - var(--spacing-8-step));display:flex;flex-direction:column;align-items:center;}
  .tc_tools__list-voting__item__vote__button{
    svg{fill:var(--color-light);}
    &:first-of-type{order:-1;}
  }
  .tc_tools__list-voting__item__vote__score{font-size:var(--font-12);font-weight:var(--font-medium);padding:var(--spacing-8-step) 0;text-align:center;line-height:var(--line-110);}
  .tc_tools__list-voting__item__vote__score__label{font-weight:var(--font-regular);}

  [data-object-layout]{
    .tc_tools__list-voting__item::before,.tc_tools__list-voting__item__vote__score{color:var(--color-primary-tv2red);}
  }
  [data-object-layout="sport"]{
    .tc_tools__list-voting__item::before,.tc_tools__list-voting__item__vote__score{color:var(--color-primary-blue);}
  }
  [data-object-layout="vejr"]{
    .tc_tools__list-voting__item::before,.tc_tools__list-voting__item__vote__score{color:var(--color-primary-green);}
  }
  [data-object-layout="echo"]{
    .tc_tools__list-voting__item::before,.tc_tools__list-voting__item__vote__score{color:var(--color-primary-violet);}
  }
}

@include respond-to(print) {
  .tc_tools__list-voting__item{border:var(--spacing-2) solid var(--color-black);padding:var(--spacing-8);margin-bottom:var(--spacing-16);clear:both;
    .tc_image{float:right;width:var(--spacing-128);margin-left:var(--spacing-16);
      .tc_caption{font-size:var(--font-12);padding-left:0;padding-right:0;}
    }
    &::after{content:"";display:table;clear:both;}
    .tc_heading + .tc_image{margin-top:calc(var(--spacing-20) * -1);}
  }
  .tc_tools__list-voting__item__vote__score{font-weight:var(--font-medium);}
  .tc_tools__list-voting__item__vote__score__label{display:inline-block;padding-left:var(--spacing-4);}
}