// FORMS FILTER
@import "core";

@include respond-to(base-inline) {
  .tc_forms-filter{
    .tc_form-fieldset__body{padding-bottom:var(--spacing-20);gap:var(--spacing-12);}
  }
  .tc_forms-filter__loading{display:flex;font-size:var(--font-14);
    &::before{content:"";display:inline-flex;width:var(--spacing-16-step);margin-right:var(--spacing-4-step);}
    &.tc_forms-filter__loading--updating::before{mask:url("../../../../../icons/spinner.svg") no-repeat 50% 50%;mask-size:contain;background-color:var(--color-dark);}
  }
}

@include respond-to(print) {
  .tc_forms-filter{display:none;}
}
