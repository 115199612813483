// EPG
@import "core";

@include respond-to(base-inline) {
  :root{
    --epg-item-height: var(--spacing-56);
    --epg-item-header-width: calc(60 * var(--unit));
    --epg-main-width: calc(((360 * 24) + 180) * var(--unit));

    --epg-range-track-color: var(--color-dark-300);
    --epg-range-track-highlight-color: var(--color-dark-500);
  }
  .tc_epg{display:flex;flex-direction:column;gap:var(--spacing-16-step);background-color:var(--color-light);
    & + .tc_button__group{padding-top:var(--spacing-24);}
  }
  .tc_epg__header{display:flex;flex-wrap:wrap;gap:var(--spacing-24-step);}

  .tc_epg__header__settings{display:flex;flex-direction:row-reverse;align-items:center;gap:var(--spacing-16);order:1;}

  .tc_epg__channel-picker{display:flex;justify-content:center;order:1;min-width:100%;background-color:var(--color-100);padding:var(--spacing-32-step) 0;}
  .tc_epg__channel-picker__main{flex:1;display:flex;flex-direction:column;gap:var(--spacing-16);max-width:calc(556 * var(--unit));padding:0 var(--spacing-16);
    .tc_notification{margin:0 calc(13 * var(--unit));height:var(--spacing-32);}
    .tc_form__group{margin-bottom:var(--spacing-32);}
  }
  .tc_epg__channel-picker__main__list{list-style:none;margin:0;padding:0;background-color:var(--color-light);overflow-y:auto;display:flex;flex-direction:column;
    &[data-channel-picker-list="my-list"]{height:50vh;
      .tc_epg__channel-picker__main__list__item{padding-left:var(--spacing-40);}
    }
    &[data-channel-picker-list="all-list"]{height:calc(50vh - (80 * var(--unit)));}
  }
  .tc_epg__channel-picker__main__list__item{position:relative;display:flex;align-items:center;gap:var(--spacing-8);min-height:var(--spacing-48);margin-bottom:calc(var(--spacing-1) * -1);padding:0 0 0 var(--spacing-8-step);background-color:var(--color-light);border-top:var(--spacing-2) solid var(--color-100);border-bottom:var(--spacing-2) solid var(--color-100);
    &:first-child{border-top-width:0;}
    &:last-child{border-bottom-width:var(--spacing-4);}
  }
  .tc_epg__channel-picker__main__list__item__logo{width:var(--spacing-56);max-height:var(--spacing-32);}
  .tc_epg__channel-picker__main__list__item__title{font-size:var(--font-14);line-height:var(--line-120);}
  .tc_epg__channel-picker__main__list__item__button{margin-left:auto;}
  .tc_epg__channel-picker__main__no-results{position:absolute;padding:var(--spacing-8) var(--spacing-16);font-size:var(--font-16);}

  .tc_epg__category-picker{order:1;min-width:100%;background-color:var(--color-100);padding:var(--spacing-32-step) 0;}
  .tc_epg__category-picker__main{padding:0 var(--spacing-16);
    .tc_forms-filter{
      .tc_form-fieldset__body{display:grid;column-gap:var(--spacing-20);row-gap:var(--spacing-12);grid-template-columns:1fr 1fr;padding-bottom:var(--spacing-32);}
    }
  }

  .tc_epg__header__date{display:flex;gap:var(--spacing-4);min-width:100%;list-style:none;padding:var(--spacing-8) 0;overflow:auto;}
  .tc_epg__header__date__item{
    input{position:relative;z-index:-1;opacity:0;
      &:checked + label{color:var(--color-text);background-color:var(--color-dark-200);}
      &:focus-visible ~ label{outline:var(--spacing-2) solid var(--color-focus);outline-offset:var(--spacing-2);}
    }
    label{display:flex;flex-direction:column;justify-content:center;align-items:center;font-size:var(--font-10-12);line-height:var(--line-130);font-weight:var(--font-medium);color:var(--color-dark-800);width:calc(var(--spacing-40-step) + var(--spacing-0-step));height:calc(var(--spacing-40-step) + var(--spacing-0-step));cursor:pointer;transition:background-color var(--transition-faster);-webkit-user-select:none;user-select:none;}
  }

  .tc_epg__header__time{flex:1;display:flex;align-items:center;gap:var(--spacing-24);
    .tc_button{display:none;}
    &[aria-hidden=true] .tc_epg__header__time__range__hours{color:var(--color-400);}
  }

  .tc_epg__header__time__range{flex:1;}

  .tc_epg__header__time__range__hours{display:flex;justify-content:space-around;list-style:none;font-size:var(--font-12);font-weight:var(--font-medium);margin:0 var(--spacing-2) calc(var(--spacing-4) * -1);}
  .tc_epg__header__time__range__hours-item{width:var(--spacing-32-step);text-align:center;}

  .tc_epg__header__time__range__input{margin-bottom:calc(var(--spacing-8) * -1);
    .tc_form__range{
      &::-moz-range-track{background:linear-gradient(to right, var(--epg-range-track-highlight-color) var(--epg-time-in-pct, 0), var(--epg-range-track-color) var(--epg-time-in-pct, 0));}
      &::-moz-range-progress{background:transparent;}
      &::-webkit-slider-runnable-track{background:linear-gradient(to right, var(--epg-range-track-highlight-color) var(--epg-time-in-pct, 0), var(--epg-range-track-color) var(--epg-time-in-pct, 0));}
      &::before{background:transparent;}
    }
  }

  .tc_epg__main__content{
    &:empty{min-height:calc((var(--epg-item-height)) * 8);}
  }
  .tc_epg__main__content__channel{background-color:var(--color-100);}
  .tc_epg__main__content__channel__header{background:var(--color-100);height:var(--epg-item-height);box-sizing:border-box;}
  .tc_epg__main__content__channel__header__link{display:block;height:calc(var(--epg-item-height) - var(--spacing-2));background-repeat:no-repeat;background-size:100% 70%;background-position:50%;
    &:focus{outline-offset:calc(var(--spacing-2) * -1);}
    &:focus-visible{outline-offset:calc(var(--spacing-2) * -1);}
  }
  .tc_epg__main__content__channel__programs{list-style:none;font-size:var(--font-14);line-height:var(--line-130);}
  .tc_epg__main__content__channel__programs__item{padding:var(--spacing-8) var(--spacing-12);overflow:hidden;
    &[data-program-meta|="premiere"]{
      .tc_epg__main__content__channel__programs__item__info__title::after{content:"Premiere";font-size:var(--font-12);font-weight:var(--font-regular);color:var(--color-primary-tv2red);}
    }
    .tc_overlay__body__sub{background-color:var(--color-light);}
  }
  .tc_epg__main__content__channel__programs__item__info{display:flex;}
  .tc_epg__main__content__channel__programs__item__info__title{display:flex;gap:var(--spacing-4);
    strong{display:block;}
  }
  .tc_epg__main__content__channel__programs__item__progress{appearance:none;border:0;}
  .tc_epg__main__content__channel__programs__item__button{position:absolute;left:0;right:0;top:0;bottom:0;width:100%;background-color:var(--color-light);padding:0;border:0;opacity:0;cursor:pointer;
    &:focus{outline:none;}
    &:focus-visible{outline:none;}
  }

  .tc_epg__viewmode{position:relative;width:calc(var(--spacing-16-jump) + (268 * var(--unit)));
    .tc_form-fieldset__legend span{font-size:var(--font-14);padding:var(--spacing-8) 0;}
    .tc_form-fieldset__body{position:absolute;top:calc(6 * var(--unit));right:var(--spacing-32);}
    .tc_form__radio ~ .tc_form__label{font-size:var(--font-14);padding-top:var(--spacing-2);padding-bottom:var(--spacing-2);}
  }

  /* dialog */
  .tc_epg__dialog{margin:0 auto;width:var(--main-medium);max-width:100%;height:100vh;max-height:100vh;background-color:var(--color-light);font-size:var(--font-14);}
  .tc_epg__dialog__main{padding:0 var(--gutter) var(--spacing-32);}
  .tc_epg__dialog__main__header{position:sticky;top:0;z-index:2;background-color:var(--color-light);display:flex;flex-direction:column;padding-top:var(--spacing-8);
    & > span{font-size:var(--font-14);color:var(--color-primary-tv2red);height:var(--spacing-20);margin-top:calc(var(--spacing-20) * -1);}
    .tc_heading{padding-bottom:var(--spacing-16);
      &:first-child{padding-top:var(--spacing-8-step);}
    }
    & > .tc_button{margin-left:auto;z-index:1;}
  }
  .tc_epg__dialog__main__content{
    .tc_image{margin-bottom:var(--spacing-16);background:var(--color-900);}
    .tc_richcontent{font-size:var(--font-14);}
  }
  .tc_epg__dialog__main__content__meta{display:flex;align-items:center;gap:var(--spacing-8);padding-bottom:var(--spacing-12);}
  .tc_epg__dialog__main__content__meta__channel{display:flex;align-items:center;gap:var(--spacing-8);line-height:var(--line-120);
    &::after{content:"";display:block;border-right:var(--spacing-2) solid var(--color-900);height:var(--spacing-12);}
  }
  .tc_epg__dialog__main__content__meta__time{white-space:nowrap;}
  .tc_epg__dialog__main__content__meta__av{font-size:var(--font-12);line-height:var(--line-120);padding:0 var(--spacing-4);border:var(--spacing-1) solid var(--color-600);border-radius:var(--spacing-2);}
  .tc_epg__dialog__main__content__schedule{list-style:none;padding:0 0 var(--spacing-12);margin:0;
    li{display:flex;align-items:center;}
    img{height:var(--spacing-32);}
  }
  .tc_epg__dialog__main__footer{
    p{padding-bottom:var(--spacing-12);}
    .tc_button__group{padding-top:var(--spacing-24);}
  }
  @supports (height:1dvh){
    .tc_epg__dialog{height:100dvh;max-height:100dvh;}
  }

  /* viewmodes */
  [data-viewmode="horizontal"]{
    .tc_epg__main{position:relative;z-index:1;overflow-x:scroll;overflow-y:hidden;}
    .tc_epg__main__time{list-style:none;display:flex;align-items:center;position:relative;z-index:2;width:var(--epg-main-width);height:var(--spacing-32);padding-left:calc(var(--epg-item-header-width) - var(--spacing-1));background:var(--color-dark-bordeaux);
      li{min-width:calc(180 * var(--unit));font-size:var(--font-14);color:var(--color-white);
        &::before{content:"";display:inline-block;border-left:var(--spacing-1) solid var(--color-white);padding-right:var(--spacing-8);height:var(--spacing-12);}
      }
    }

    .tc_epg__main__content{width:calc(var(--epg-main-width) + var(--epg-item-header-width));}
    .tc_epg__main__content__channel{
      .tc_epg__main__content__channel__programs::before{content:"";position:absolute;left:0;right:0;bottom:0;top:0;border-top:var(--spacing-2) solid var(--color-light);border-bottom:var(--spacing-2) solid var(--color-light);}
    }
    .tc_epg__main__content__channel__header{position:sticky;left:0;width:var(--epg-item-header-width);z-index:1;border-top:var(--spacing-2) solid var(--color-light);border-bottom:var(--spacing-2) solid var(--color-light);box-shadow:0 var(--spacing-8) var(--spacing-8) 0 var(--color-dark-400);}
    .tc_epg__main__content__channel__programs{position:relative;display:flex;width:var(--epg-main-width);height:var(--epg-item-height);margin:calc(var(--epg-item-height) * -1) 0 0 var(--epg-item-header-width);overflow:hidden;}
    .tc_epg__main__content__channel__programs__item{position:absolute;top:0;height:var(--epg-item-height);border:var(--spacing-2) solid var(--color-light);box-sizing:border-box;background-color:var(--color-100);
      &:has(:focus-visible){outline:var(--spacing-2) solid var(--color-focus);outline-offset:calc(var(--spacing-3) * -1);}
      &[data-program-status^="ended"],&[data-program-status="running"]{
        .tc_epg__main__content__channel__programs__item__progress{position:absolute;top:0;left:0;right:0;bottom:0;width:100%;height:100%;background:transparent;
          &::-webkit-progress-bar{background-color:transparent;}
          &::-webkit-progress-value{background-color:var(--color-100);opacity:.5;}
          &::-moz-progress-bar{background-color:var(--color-100);opacity:.5;}
        }
        &:first-child{border-left-width:var(--spacing-4);}
        &:last-child{border-right-width:var(--spacing-4);}
      }
      &[data-program-status="not-started"]{
        .tc_epg__main__content__channel__programs__item__progress{position:absolute;top:0;left:0;right:0;bottom:0;width:100%;height:100%;opacity:0;}
      }
    }
    .tc_epg__main__content__channel__programs__item__info{flex-direction:column-reverse;}
    .tc_epg__main__content__channel__programs__item__info__title{
      strong{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
    }
    .tc_epg__main__marker{position:absolute;top:0;bottom:0;width:var(--spacing-2);margin-left:calc(var(--epg-item-header-width) - var(--spacing-2));background-color:var(--color-primary-tv2red);pointer-events:none;}
  }

  [data-viewmode="vertical"]{
    .tc_epg__main__time{display:none;}
    .tc_epg__main__content{display:grid;gap:var(--spacing-4);}
    .tc_epg__main__content__channel{overflow:hidden;padding-bottom:var(--spacing-64);}
    .tc_epg__main__content__channel--collapsed{position:relative;height:calc(270 * var(--unit));padding-bottom:0;
      &::after{content:"";position:absolute;bottom:0;left:0;right:0;height:var(--spacing-64);background:linear-gradient(to bottom,transparent, var(--color-100));pointer-events:none;}
    }
    .tc_epg__main__content__channel__programs__item{position:relative;left:0!important;width:auto!important; // override inline styles
      &[data-program-status="ended"]{opacity:.5;}
      &[data-program-status="ended-today"]{display:none;}
      &[data-program-status="running"]{
        .tc_epg__main__content__channel__programs__item__progress{width:calc(100% - calc(var(--spacing-48) - var(--spacing-2)));margin-left:calc(var(--spacing-48) - var(--spacing-2));height:var(--spacing-4);background-color:var(--color-300);vertical-align:0;
          &::-webkit-progress-bar{background-color:var(--color-300);}
          &::-webkit-progress-value{background-color:var(--color-primary-tv2red);}
          &::-moz-progress-bar{background-color:var(--color-primary-tv2red);}
        }
      }
      &[data-program-status="ended"],&[data-program-status="not-started"]{
        .tc_epg__main__content__channel__programs__item__progress{position:absolute;width:var(--spacing-1);height:var(--spacing-1);overflow:hidden;white-space:nowrap;clip:rect(0 0 0 0);clip-path:inset(50%);}
      }
      &:focus-within{outline:var(--spacing-2) solid var(--color-focus);outline-offset:calc(var(--spacing-2) * -1);}
    }
    .tc_epg__main__content__channel__programs__item__info{flex-direction:row;gap:var(--spacing-8);}
    .tc_epg__main__content__channel__programs__item__info__title{flex-direction:column-reverse;}
    .tc_epg__main__content__button{grid-column:1/-1;background:var(--color-light);padding:var(--spacing-16) 0 var(--spacing-32);}
    .tc_epg__main__marker{display:none;}
  }

  // Channel page
  .tc_epg--channel{
    .tc_epg__main__content__channel__header{
      .tc_heading{display:flex;justify-content:center;align-items:center;height:calc(var(--epg-item-height) - var(--spacing-1));}
    }
  }

  @media (scripting:none) {
    .tc_epg::before{content:"TV-guiden bruger JavaScript for at fungere";position:absolute;left:0;right:0;top:0;bottom:0;z-index:3;display:flex;justify-content:center;align-items:center;background:var(--color-dark-700);color:var(--color-light);}
  }
}

@include respond-to(base) {
  .tc_epg--loading{position:relative;
    &::before,&::after{content:"";position:absolute;left:0;right:0;top:0;bottom:0;z-index:5;}
    &::before{background:var(--color-light-800);}
    &::after{mask:url(../../../../icons/spinner.svg) no-repeat 50% min(50%,calc(200 * var(--unit)));background-color:var(--color-dark);}
  }
  .tc_epg__channel-picker__main__list{
    &[data-channel-picker-list="my-list"]{
      .tc_epg__channel-picker__main__list__item{
        &::before{content:"";position:absolute;left:0;top:0;bottom:0;width:var(--spacing-40);mask:url('../../../../icons/drag.svg') no-repeat 50% 50%;mask-size:var(--spacing-20) auto;rotate:90deg;background-color:var(--color-dark-600);}
      }
    }
  }
  .tc_epg__main__content__channel__programs__item--loading{
    .tc_epg__main__content__channel__programs__item__info{opacity:.2;}
  }
}

@include respond-to(small-inline) {
  .tc_epg__header__time__range__hours{margin:0 calc(var(--spacing-1) * -1) calc(var(--spacing-8) * -1);}
}

@include respond-to(medium-inline) {
  :root{
    --epg-item-header-width: calc(100 * var(--unit));
  }
  .tc_epg__header{position:relative;min-height:var(--spacing-72);border-bottom:0;}
  .tc_epg__category-picker__main{
    .tc_forms-filter{
      .tc_form-fieldset__body{grid-template-columns:1fr 1fr 1fr;}
    }
  }
  .tc_epg__header__date__item label{line-height:var(--line-140);}
  .tc_epg__header__time{
    .tc_button{display:inline-flex;}
  }
  .tc_epg__header__time__range__hours{margin:0 calc(var(--spacing-3) * -1) calc(var(--spacing-8) * -1);}

  [data-viewmode="vertical"]{
    .tc_epg__main__content{grid-template-columns:repeat(2, 1fr);}
  }

  /* dialog */
  .tc_epg__dialog{margin:auto;max-height:75vh;}
  .tc_epg__dialog__main__header{
    & > .tc_button{right:calc(var(--spacing-16) * -1);}
  }
  @supports (height:1dvh){
    .tc_epg__dialog{max-height:75vh;}
  }
}

@include respond-to(large-inline) {
  .tc_epg__category-picker__main{
    .tc_forms-filter{
      .tc_form-fieldset__body{grid-template-columns:1fr 1fr 1fr 1fr;}
    }
  }
  .tc_epg__header__date{justify-content:center;gap:var(--spacing-12);}
  .tc_epg__header__nav{flex-direction:row;gap:var(--spacing-48);}
  [data-viewmode="vertical"]{
    .tc_epg__main__content{grid-template-columns:repeat(4, 1fr);}
  }
}

@include respond-to(hover) {
  .tc_epg__header__date__item input:not(:checked) ~ label:hover{background-color:var(--color-dark-100);}
  .tc_epg__main__content__channel__programs__item{transition:background-color var(--transition-faster);
    &:not(:focus):hover{background-color:var(--color-light);}
  }
  [data-viewmode="horizontal"]{
    .tc_epg__main__content__channel__programs__item{
      &:not(:focus):hover{
        &[data-program-status^="ended"],&[data-program-status="running"]{
          .tc_epg__main__content__channel__programs__item__progress{
            &::-webkit-progress-value{background-color:var(--color-light);}
            &::-moz-progress-bar{background-color:var(--color-light);}
          }
        }
      }
    }
  }
}

@include respond-to(print) {
  body:has(.tc_epg__dialog[open]){
    .tc_deck__header,.tc_epg__main{display:none;}
  }
  .tc_epg__header,.tc_epg__main__time,.tc_epg__main__content__channel__programs__item__button,.tc_epg__main__marker{display:none;}
  .tc_deck__header img{display:none;}
  .tc_epg__main{width:var(--main-medium);}
  .tc_epg__main__content{display:grid;grid-template-columns:repeat(2, 1fr);gap:var(--spacing-16);}
  .tc_epg__main__content__channel{margin-bottom:var(--spacing-32);}
  .tc_epg__main__content__channel__header{border-bottom:var(--spacing-1) solid var(--color-900);margin-bottom:var(--spacing-16);
    .sr-only{display:block!important;}
  }
  .tc_epg__main__content__channel__header__link{text-decoration:none;}
  .tc_epg__main__content__channel__programs{list-style:none;margin:0;padding:0;}
  .tc_epg__main__content__channel__programs__item{width:auto;}
  .tc_epg__main__content__channel__programs__item__info__title{font-size:var(--font-16);}
  .tc_epg__dialog__main__content{display:flex;flex-direction:column;gap:var(--spacing-16);
    .tc_richcontent{font-size:var(--font-16);}
    .tc_heading{margin-bottom:calc(var(--spacing-8) * -1);}
  }
  .tc_epg__dialog__main__content__meta{display:flex;gap:var(--spacing-16);}
  .tc_epg__dialog__main__content__schedule{list-style:none;margin:0;padding:0;
    li{display:flex;font-size:var(--font-16);
      img{height:var(--spacing-32);}
    }
  }
  .tc_epg__dialog{max-width:var(--main-small);margin:0 auto;
    .tc_button{display:none;}
  }
}
