// EMBED TOOLS LIVE POLL
@import "core";

@include respond-to(base-nomediaquery) {
  @keyframes vote {
    0% {background-position:100% 0;}
    100% {background-position:auto 0;}
  }
}

@include respond-to(base) {
  .tc_tools__live-poll__vote{position:relative;}
  .tc_tools__live-poll{
    .tc_heading{padding-bottom:var(--spacing-16);}
  }
  .tc_tools__live-poll__vote__item{background-color:var(--color-light);margin-bottom:var(--spacing-2);
    &:focus-within{color:var(--color-light);background-color:var(--color-800);
      .tc_form__label::before{border-color:transparent;}
    }
    .tc_form__group{cursor:pointer;}
    .tc_form__radio:checked + .tc_form__label::after{opacity:1;background-color:var(--color-800);}
    .tc_form__label{border-width:var(--spacing-16) var(--spacing-8-step);border-style:solid;border-color:transparent;cursor:pointer;
      &::after{transition:none;}
    }
  }
  .tc_tools__live-poll__note{padding:var(--spacing-32) var(--spacing-8-step);margin-bottom:var(--spacing-20);background-color:var(--color-light);}

  .tc_tools__live-poll__results{position:relative;color:var(--color-text);}
  .tc_tools__live-poll__results__info{padding:var(--spacing-16) var(--spacing-8-step);text-align:center;font-size:var(--font-14);}
  .tc_tools__live-poll__results__list{list-style:none;padding-bottom:var(--spacing-8);}
  .tc_tools__live-poll__results__item{background-color:var(--color-light);padding:var(--spacing-16) var(--spacing-8-step);margin-bottom:var(--spacing-2);}
  .tc_tools__live-poll__results__item__caption{padding-bottom:var(--spacing-8);font-size:var(--font-14);line-height:var(--line-120);}
  .tc_tools__live-poll__results__item__result{display:flex;}
  .tc_tools__live-poll__results__item__result__percent{padding-right:var(--spacing-8);font-size:var(--font-24-28);line-height:var(--line-110);font-weight:var(--font-bold);min-width:calc(var(--spacing-64-step) + var(--spacing-4-step));}
  .tc_tools__live-poll__results__item__result__meter{appearance:none;background:linear-gradient(to right, transparent, transparent 50%, var(--color-200) 50%, var(--color-200) 100% ) no-repeat 100% 0, var(--color-light);background-color:var(--color-dark);background-size:199.9%;width:100%;height:var(--spacing-20-step);animation: 1s vote;
    &::-webkit-meter-bar{background:transparent;}
    &::-webkit-meter-optimum-value{background:transparent;}
    &::-moz-meter-bar{background:transparent;}
  }
  .tc_tools__live-poll__hidden{padding:var(--spacing-32) var(--spacing-8-step);background-color:var(--color-light);}

  [data-object-layout]{
    .tc_tools__live-poll__results__item__result__meter{background-color:var(--color-primary-tv2red);}
  }
  [data-object-layout="sport"]{
    .tc_tools__live-poll__vote__item{
      &:focus-within,.tc_form__radio:checked + .tc_form__label::after{background-color:var(--color-primary-blue);}
    }
    .tc_tools__live-poll__results__item__result__meter{background-color:var(--color-primary-blue);}
  }
  [data-object-layout="vejr"]{
    .tc_tools__live-poll__vote__item{
      &:focus-within,.tc_form__radio:checked + .tc_form__label::after{background-color:var(--color-primary-green);}
    }
    .tc_tools__live-poll__results__item__result__meter{background-color:var(--color-primary-green);}
  }
  [data-object-layout="echo"]{
    .tc_tools__live-poll__vote__item{
      &:focus-within,.tc_form__radio:checked + .tc_form__label::after{background-color:var(--color-primary-violet);}
    }
    .tc_tools__live-poll__results__item__result__meter{background-color:var(--color-primary-violet);}
  }
}

@include respond-to(hover) {
  .tc_tools__live-poll__vote__item:hover{background-color:var(--color-dark-100);
    .tc_form__label::after{opacity:1;}
  }
  [data-object-layout="nyheder"] .tc_tools__live-poll__vote__item:hover .tc_form__label::after{background-color:var(--color-primary-tv2red);}
  [data-object-layout="sport"] .tc_tools__live-poll__vote__item:hover .tc_form__label::after{background-color:var(--color-primary-blue);}
  [data-object-layout="vejr"] .tc_tools__live-poll__vote__item:hover .tc_form__label::after{background-color:var(--color-primary-green);}
  [data-object-layout="echo"] .tc_tools__live-poll__vote__item:hover .tc_form__label::after{background-color:var(--color-primary-violet);}
}

@include respond-to(print) {
  .tc_tools__live-poll__results__item{margin-bottom:var(--spacing-16);}
  .tc_tools__live-poll__results__item__result{display:flex;}
  .tc_tools__live-poll__results__item__result__percent{width:var(--spacing-64);}
}
