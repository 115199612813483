// PARTNER
@import "core";

@include respond-to(base-inline) {
  .tc_partner{display:flex;flex-direction:column;gap:var(--spacing-8-step);color:var(--color-500);font-size:var(--font-12-14);}
  .tc_partner__item{display:flex;align-items:center;gap:var(--spacing-8);min-height:var(--spacing-32);}
  .tc_partner__item__logo{max-height:var(--spacing-32);max-width:calc(110 * var(--unit));}
  .tc_partner__item__text a{color:var(--color-link);text-decoration:underline;}

  // selector is true if user has overriden the OS setting on tv2.dk with darkmode (using the settings switch)
  :root:has(meta[name="color-scheme"][content="dark"]) .tc_partner__item__logo{filter:invert(1) brightness(2);}

  @media (prefers-color-scheme:dark) {
    // selector is true if user has set darkmode on OS level (but only if it's supported - ie. "dark" is present in the metatag)
    :root:has(meta[name="color-scheme"][content*="dark"]) .tc_partner__item__logo{filter:invert(1) brightness(2);}
  }
}

@include respond-to(hover) {
  .tc_partner__item__text{
    a{transition:color var(--transition-faster) var(--link-easing-hover);
      &:hover{color:var(--color-link-hover);}
      &:visited:hover{color:var(--color-link-visited-hover);}
    }
  }
}

@include respond-to(print) {
  .tc_partner{display:flex;flex-direction:column;gap:var(--spacing-8-step);font-size:var(--font-14);margin-bottom:var(--spacing-24);}
  .tc_partner__item{display:flex;align-items:center;gap:var(--spacing-8);}
}
