// ELECTION MAP
@import "core";

@include respond-to(base-inline) {
  :root{
    --color-election-light-unknown: rgb(237, 237, 237);
    --color-election-light-a: rgb(230, 162, 172);
    --color-election-light-b: rgb(188, 149, 213);
    --color-election-light-c: rgb(174, 212, 151);
    --color-election-light-d: rgb(116, 191, 193);
    --color-election-light-f: rgb(249, 222, 240);
    --color-election-light-i: rgb(223, 240, 244);
    --color-election-light-k: rgb(254, 213, 154);
    --color-election-light-m: rgb(252, 250, 253);
    --color-election-light-o: rgb(253, 246, 231);
    --color-election-light-q: rgb(182, 224, 149);
    --color-election-light-v: rgb(137, 175, 227);
    --color-election-light-æ: rgb(142, 166, 199);
    --color-election-light-ø: rgb(250, 211, 205);
    --color-election-light-å: rgb(212, 231, 188);

    --color-election-dark-unknown: rgb(102, 102, 102);
    --color-election-dark-a: rgb(108, 29, 40);
    --color-election-dark-b: rgb(58, 30, 77);
    --color-election-dark-c: rgb(48, 76, 32);
    --color-election-dark-d: rgb(17, 37, 37);
    --color-election-dark-f: rgb(181, 35, 133);
    --color-election-dark-i: rgb(52, 140, 160);
    --color-election-dark-k: rgb(152, 91, 1);
    --color-election-dark-m: rgb(132, 70, 178);
    --color-election-dark-o: rgb(215, 152, 14);
    --color-election-dark-q: rgb(55, 92, 26);
    --color-election-dark-v: rgb(21, 50, 88);
    --color-election-dark-æ: rgb(28, 41, 58);
    --color-election-dark-ø: rgb(183, 38, 17);
    --color-election-dark-å: rgb(86, 120, 44);
  }

  .tc_election-map{position:relative;width:100%;aspect-ratio:4/3;overflow:hidden;
    & > svg{width:75%;height:100%;position:absolute;left:12.5%;top:0;bottom:0;right:12.5%;overflow:visible!important;visibility:hidden;touch-action:pan-y!important;
      &.tc_election-map__svg--visible{visibility:visible!important;}
    }

    &[data-selected-legend="a"] .tc_election-map__shape:not([data-party-letter="a"]),
    &[data-selected-legend="b"] .tc_election-map__shape:not([data-party-letter="b"]),
    &[data-selected-legend="c"] .tc_election-map__shape:not([data-party-letter="c"]),
    &[data-selected-legend="d"] .tc_election-map__shape:not([data-party-letter="d"]),
    &[data-selected-legend="f"] .tc_election-map__shape:not([data-party-letter="f"]),
    &[data-selected-legend="i"] .tc_election-map__shape:not([data-party-letter="i"]),
    &[data-selected-legend="k"] .tc_election-map__shape:not([data-party-letter="k"]),
    &[data-selected-legend="m"] .tc_election-map__shape:not([data-party-letter="m"]),
    &[data-selected-legend="o"] .tc_election-map__shape:not([data-party-letter="o"]),
    &[data-selected-legend="q"] .tc_election-map__shape:not([data-party-letter="q"]),
    &[data-selected-legend="v"] .tc_election-map__shape:not([data-party-letter="v"]),
    &[data-selected-legend="æ"] .tc_election-map__shape:not([data-party-letter="æ"]),
    &[data-selected-legend="ø"] .tc_election-map__shape:not([data-party-letter="ø"]),
    &[data-selected-legend="å"] .tc_election-map__shape:not([data-party-letter="å"]),
    &[data-selected-legend="j"] .tc_election-map__shape:not([data-party-letter="j"]),
    &[data-selected-legend="n"] .tc_election-map__shape:not([data-party-letter="n"]){
      &:not(.tc_election-map__shape__active){
        fill: var(--color-200)!important;
        filter:none;
      }}
  }
  .tc_election-map__buttons{position:relative;left:var(--spacing-4-step);top:var(--spacing-4-step);z-index:1;display:inline-flex;flex-direction:column;
    button{position:relative;width:var(--spacing-40);height:var(--spacing-40);background:var(--color-light);border:0;border-radius:0;border-top:var(--spacing-1) solid var(--color-500);border-left:var(--spacing-1) solid var(--color-500);border-right:var(--spacing-1) solid var(--color-500);cursor:pointer;box-sizing:border-box;overflow:hidden;
      &:first-child{border-top-left-radius:var(--spacing-4);border-top-right-radius:var(--spacing-4);}
      &:last-child{border-bottom-left-radius:var(--spacing-4);border-bottom-right-radius:var(--spacing-4);border-bottom:var(--spacing-1) solid var(--color-500);padding-bottom:0;}
      > svg{fill:var(--color-700);height:var(--spacing-20);width:var(--spacing-20);box-sizing:border-box;margin:auto;}
    }
  }
  .tc_election-map__hover-name{position:absolute;z-index:2;bottom:var(--spacing-8-step);left:var(--spacing-8-step);background:var(--color-900);color:var(--color-light);font-weight:var(--font-medium);line-height:var(--line-110);height:var(--font-14);font-size:var(--font-14);}
  .tc_election-map__hover-name--active{padding:var(--spacing-4) var(--spacing-8);}

  .tc_election-map__shape{stroke:white;stroke-width:inherit;fill:var(--color-300);stroke-linejoin:round;stroke-linecap:round;paint-order:stroke;
    &[data-party-letter="a"].tc_election-map__shape__active{stroke:var(--color-election-dark-a);}
    &[data-party-letter="b"].tc_election-map__shape__active{stroke:var(--color-election-dark-b);}
    &[data-party-letter="c"].tc_election-map__shape__active{stroke:var(--color-election-dark-c);}
    &[data-party-letter="d"].tc_election-map__shape__active{stroke:var(--color-election-dark-d);}
    &[data-party-letter="f"].tc_election-map__shape__active{stroke:var(--color-election-dark-f);}
    &[data-party-letter="i"].tc_election-map__shape__active{stroke:var(--color-election-dark-i);}
    &[data-party-letter="k"].tc_election-map__shape__active{stroke:var(--color-election-dark-k);}
    &[data-party-letter="m"].tc_election-map__shape__active{stroke:var(--color-election-dark-m);}
    &[data-party-letter="o"].tc_election-map__shape__active{stroke:var(--color-election-dark-o);}
    &[data-party-letter="q"].tc_election-map__shape__active{stroke:var(--color-election-dark-q);}
    &[data-party-letter="v"].tc_election-map__shape__active{stroke:var(--color-election-dark-v);}
    &[data-party-letter="æ"].tc_election-map__shape__active{stroke:var(--color-election-dark-æ);}
    &[data-party-letter="ø"].tc_election-map__shape__active{stroke:var(--color-election-dark-ø);}
    &[data-party-letter="å"].tc_election-map__shape__active{stroke:var(--color-election-dark-å);}
    &[data-party-letter="j"].tc_election-map__shape__active{stroke:var(--color-election-dark-j);}
    &[data-party-letter="n"].tc_election-map__shape__active{stroke:var(--color-election-dark-n);}
  }
  .tc_election-map__shape__not-active{opacity:0.58;}
  .tc_election-map__shape__disabled{pointer-events:none;stroke:none;fill:var(--color-200);}

  .tc_election-map__shape__active{opacity:1;stroke:var(--color-700);filter:drop-shadow(0 var(--spacing-3) var(--spacing-3) var(--color-dark-700));}
  .tc_election-map__shape__active-parent{opacity:1;}
  .tc_election_map__shape__hover-label{position:absolute;}
}

.tc_election-map__shape{
  &:not(.tc_election-map__shape__active):hover{stroke:var(--color-700);filter:drop-shadow(0 var(--spacing-2) var(--spacing-2) var(--color-dark-700));}
  &[data-party-letter="a"]:hover{stroke:var(--color-election-dark-a);}
  &[data-party-letter="b"]:hover{stroke:var(--color-election-dark-b);}
  &[data-party-letter="c"]:hover{stroke:var(--color-election-dark-c);}
  &[data-party-letter="d"]:hover{stroke:var(--color-election-dark-d);}
  &[data-party-letter="f"]:hover{stroke:var(--color-election-dark-f);}
  &[data-party-letter="i"]:hover{stroke:var(--color-election-dark-i);}
  &[data-party-letter="k"]:hover{stroke:var(--color-election-dark-k);}
  &[data-party-letter="m"]:hover{stroke:var(--color-election-dark-m);}
  &[data-party-letter="o"]:hover{stroke:var(--color-election-dark-o);}
  &[data-party-letter="q"]:hover{stroke:var(--color-election-dark-q);}
  &[data-party-letter="v"]:hover{stroke:var(--color-election-dark-v);}
  &[data-party-letter="æ"]:hover{stroke:var(--color-election-dark-æ);}
  &[data-party-letter="ø"]:hover{stroke:var(--color-election-dark-ø);}
  &[data-party-letter="å"]:hover{stroke:var(--color-election-dark-å);}
  &[data-party-letter="j"]:hover{stroke:var(--color-election-dark-j);}
  &[data-party-letter="n"]:hover{stroke:var(--color-election-dark-n);}
}
