// EVENT INFO
@import "core";

@include respond-to(base-inline) {
  .tc_eventinfo{padding:var(--spacing-16-step) var(--spacing-16-step) var(--spacing-20-step);background-color:var(--color-100);max-width:var(--main-medium);
    [role="rowgroup"]{display:grid;gap:var(--spacing-24);padding-top:var(--spacing-24);}
    [role="row"]{display:flex;gap:var(--spacing-16);}
    [role="columnheader"]{font-size:var(--font-14);min-width:var(--spacing-72);max-width:var(--spacing-72);padding-top:calc(var(--spacing-4) / 2);overflow-wrap:break-word;}
    [role="cell"]{font-size:var(--font-16);flex:1 1 100%;
      img{max-height:var(--spacing-20);padding-top:var(--spacing-4);}
    }
  }
}

@include respond-to(medium-inline) {
  .tc_eventinfo{
    [role="rowgroup"]{grid-template-columns:1fr 1fr;}
  }
}

@include respond-to(print) {
  .tc_eventinfo{border:var(--spacing-2) solid var(--color-black);padding:var(--spacing-8);
    [role="row"]{display:block;padding-bottom:var(--spacing-8);}
  }
}
