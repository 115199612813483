// RESULTBOX
@import "core";

@include respond-to(base-inline) {
  .tc_resultbox{font-size:var(--font-14);line-height:var(--line-140);width:100%;background-color:var(--color-light);
    &[data-type="match"] .tc_resultbox__event__participant__name{font-weight:var(--font-bold);}
    &[data-state="NOTSTARTED"] .tc_resultbox__event__participant__score{display:none;}
    &[data-state="INPROGRESS"] .tc_resultbox__eventinfo__label{color:var(--color-live);}
  }
  .tc_resultbox__link{display:flex;height:var(--spacing-80);border:var(--spacing-1) solid var(--color-300);text-decoration:none;color:var(--color-text);max-width:calc(100% - var(--spacing-2));box-sizing:border-box;}
  .tc_resultbox__event{flex:1 1 auto;min-width:68%;max-width:68%;padding:var(--spacing-4) var(--spacing-8);
    .tc_heading{font-size:var(--font-12);padding-bottom:var(--spacing-8);color:var(--color-700);white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
  }
  .tc_resultbox__event__participant{display:flex;align-items:center;padding-bottom:var(--spacing-4);
    &[data-national-team="true"] .tc_resultbox__event__participant__name{padding-left:var(--spacing-32);background-size:var(--spacing-24) auto;}
    &[data-winner="true"] .tc_resultbox__event__participant__score{font-weight:var(--font-medium);}
  }
  .tc_resultbox__event__participant__name{flex:1 1 auto;padding-left:var(--spacing-24);white-space:nowrap;overflow:hidden;text-overflow:ellipsis;background-repeat:no-repeat;background-position:0 50%;background-size:var(--spacing-16) auto;}
  .tc_resultbox__event__participant__score{padding-left:var(--spacing-8);}
  .tc_resultbox__event__race{font-weight:var(--font-medium);}
  .tc_resultbox__eventinfo{position:relative;flex:1 1 auto;display:flex;flex-direction:column;align-items:center;padding:var(--spacing-4) var(--spacing-8);border-left:var(--spacing-1) solid var(--color-300);overflow:hidden;color:var(--color-300); // color fix for Safari
    .tc_timestamp{font-size:var(--font-12);}
  }
  .tc_resultbox__eventinfo__label{display:flex;align-items:center;position:absolute;top:var(--spacing-8);bottom:0;line-height:var(--line-110);text-align:center;color:var(--color-text);} // color fix for Safari
}

@include respond-to(hover) {
  a.tc_resultbox__link:hover{background-color:var(--color-100);}
}

@include respond-to(print) {
  .tc_resultbox{margin-bottom:var(--spacing-16);border:var(--spacing-2) solid var(--color-black);}
  .tc_resultbox__link{display:flex;gap:var(--spacing-32);color:var(--color-black);text-decoration:none;}
  .tc_resultbox__event{flex:1;padding:var(--spacing-8);
    .tc_heading{font-size:var(--font-14);}
  }
  .tc_resultbox__eventinfo{padding:var(--spacing-8);}
  .tc_resultbox__event__participant{display:flex;justify-content:space-between;}
  .tc_resultbox__event__participant__name{background:none!important;} // override inline styles
}
